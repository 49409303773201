import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../common/SearchBar";
import { handleSearchBarFilter } from "./SitesPageUtils/handleSearchBarFilter";
import { useNavigate } from "react-router-dom";

export const GroupSearchBar = ({
  setSearchBarValue,
  setEditedParentGroupList,
  setBackdropLoadingScreenOn,
  searchBarValue,
}: {
  setSearchBarValue: any;
  setEditedParentGroupList: any;
  setBackdropLoadingScreenOn: any;
  searchBarValue: any;
}) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(searchBarValue);

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.setItem("siteSearchBarValue", inputValue);
      handleSearchBarFilter(
        inputValue, // Pass the input value directly
        setSearchBarValue,
        setEditedParentGroupList,
        navigate,
        setBackdropLoadingScreenOn
      );
    }, 500);

    return () => clearTimeout(timer);
  }, [
    inputValue,
    setSearchBarValue,
    setEditedParentGroupList,
    navigate,
    setBackdropLoadingScreenOn,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        defaultValue={searchBarValue}
        placeholder="Search Groups..."
        onChange={handleChange}
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );
};
