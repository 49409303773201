import { AxiosError } from "axios";
import { createApi } from "../../../../utils/createApi";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleConfigureMotion = (
  setBackdropLoadingScreenOn: any,
  setAlertState: any,
  navigate: any
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleConfigureMotion(setBackdropLoadingScreenOn, setAlertState, navigate);
  };

  AxiosUI.post(
    `customer/${getNumbersAfterAboutSite(
      location.href
    )}/action/configure_motion/`
  ).then(
    () => {
      setAlertState((previousState: any) => {
        return { ...previousState, configureMotionSuccess: true };
      });
      setBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setAlertState((previousState: any) => {
          return { ...previousState, configureMotionFail: true };
        });
        setBackdropLoadingScreenOn(false);
      });
    }
  );
};
