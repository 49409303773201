import React from "react";
import { NavBar } from "../../common/NavBar";
import { FiltersHealthMonitoring } from "./FiltersHealthMonitoring";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApplyFiltersButtonHM } from "./ApplyFiltersButtonHM";
import { SVGLine } from "../Analytics/SVGLine";
import { ErrorsOverViewGraph } from "./ErrorsOverViewGraph";
import { BandwidthGraph } from "./BandwidthGraph";
import { CameraStatsGraph } from "./CameraStatsGraph";
import { DownloadReportButton } from "./DownloadReportButton";
import { AlertSection } from "./AlertSection";
import { AllDataDummy } from "./HealthMonitoringUtils/AllDataDummy";
import AlertStatusDialog from "./AlertStatusDialog";
import { getAllHMData } from "./HealthMonitoringUtils/getAllHMData";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { handleUpdateFilteredDataCounts } from "./HealthMonitoringUtils/handleUpdateFilteredDataCounts";
import { handleDownloadReport } from "./HealthMonitoringUtils/handleDownloadReport";
import { SceneChangeOutcomesDialog } from "./SceneChangeOutcomesDialog";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getSitesByParentGroupAlerts } from "../Alerts/AlertsUtils/getSitesByParentGroupAlerts";
import { getCamerasBySiteAlerts } from "../Alerts/AlertsUtils/getCamerasBySiteAlerts";
import { updateSearchParamValues } from "../../utils/updateSearchParamValues";
import { capitalizeMetrics } from "./HealthMonitoringUtils/capitalizeMetrics";
import { NoAlertsDialog } from "../Alerts/NoAlertsDialog";
import { BulkSceneChangeOutcomesDialog } from "./BulkSceneChangeOutcomesDialog";

export const HealthMonitoring = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const group = searchParams.get("group");
  const site = searchParams.get("site");
  const camera = searchParams.get("camera");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const metricsParam: any = searchParams.get("metrics");

  const [userFilters, setUserFilters] = React.useState<any>({
    parent_group: group,
    site: site,
    camera: camera,
    metrics: metricsParam ? capitalizeMetrics(metricsParam) : [],
    start_date: start_date ? new Date(start_date) : null,
    end_date: end_date ? new Date(end_date) : null,
  });

  React.useEffect(() => {
    updateSearchParamValues(userFilters, searchParams, setSearchParams, "");
  }, []);

  const [siteOptions, setSiteOptions] = React.useState<any[]>([]);

  const [cameraOptions, setCameraOptions] = React.useState<any[]>([]);

  const [siteDropDownValue, setSiteDropDownValue] = React.useState("");

  const [cameraDropDownValue, setCameraDropDownValue] = React.useState("");

  const [loadingSites, setLoadingSites] = React.useState(group ? true : false);

  const [loadingCameras, setLoadingCameras] = React.useState(
    site ? true : false
  );

  const [errorWarningData, setErrorWarningData] = React.useState("error");

  const [clickedTab, setClickedTab] = React.useState("all");

  const [activeTable, setActiveTable] = React.useState(<></>);

  const [alertStatusDialogOpen, setAlertStatusDialogOpen] =
    React.useState(false);

  const [selectedRow, setSelectedRow] = React.useState(AllDataDummy[0]);

  const [selectedRowIndex, setSelectedRowIndex] = React.useState(0);

  const [fetchingAllData, setFectchingAllData] = React.useState(false);

  const [bandwidthData, setBandwidthData] = React.useState([]);

  const [errorsOverviewData, setErrorsOverviewData] = React.useState({
    connectivity: 0,
    image_quality: 0,
    license_info: 0,
    motion_status: 0,
    recording_status: 0,
    scene_change: 0,
    stream_quality: 0,
  });

  const [alertsData, setAlertsData] = React.useState({
    all: [],
    errors: [],
    warnings: [],
    pending: [],
    resolved: [],
  });

  const [cameraStatsData, setCameraStatsData] = React.useState([]);

  const [sortedData, setSortedData] = React.useState([]);

  const [loadingCameraStatsData, setLoadingCameraStatsData] =
    React.useState(true);

  const [unhealthyCamsData, setUnhealthyCamsData] = React.useState({
    unhealthy_cameras: 0,
    total_cameras: 0,
    errors: 0,
    warnings: 0,
    resolved: 0,
  });

  const [updatingStatus, setUpdatingStatus] = React.useState(false);

  const [loadingBandwidthData, setLoadingBandwidthData] = React.useState(true);

  const [notesValue, setNotesValue] = React.useState("");

  const [selectedRowsToSend, setSelectedRowsToSend] = React.useState([]);

  const [activeName, setActiveName] = React.useState("");

  const [singleCellActivated, setSingleCellActivated] = React.useState(false);

  const [showSiteStats, setShowSiteStats] = React.useState(true);

  const [filteredDataLengths, setFilteredDataLengths] = React.useState({
    all: 0,
    errors: 0,
    warnings: 0,
    pending: 0,
    resolved: 0,
  });

  const [alertFilter, setAlertFilter] = React.useState("all");

  const [downloadURL, setDownloadURL] = React.useState("");

  const [sceneChangeOutcomesDialogOpen, setSceneChangeOutcomesDialogOpen] =
    React.useState(false);

  const [backgroundFrame, setBackgroundFrame] = React.useState("");

  const [detectedFrame, setDetectedFrame] = React.useState("");

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [metricsSelected, setMetricsSelected] = React.useState<string[]>(
    metricsParam ? capitalizeMetrics(metricsParam) : []
  );

  const [bandwidthLoadingError, setBandwidthLoadingError] =
    React.useState(false);

  const [noAlertsDialogOpen, setNoAlertsDialogOpen] = React.useState(false);

  const openAlert = searchParams.get("open_alert");

  const [openAlertCameraId, openAlertErrorType, openAlertcreatedTimestamp] =
    openAlert ? openAlert.split("-") : ["", "", ""];

  const [filtersApplied, setFiltersApplied] = React.useState(false);

  const [sceneChangeValueString, setSceneChangeValueString] =
    React.useState("reset");

  const [
    bulkSceneChangeOutcomesDialogOpen,
    setBulkSceneChangeOutcomesDialogOpen,
  ] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (group) {
      getSitesByParentGroupAlerts(
        group,
        navigate,
        setSiteOptions,
        setLoadingSites
      );
    }
    if (site) {
      getCamerasBySiteAlerts(
        [site],
        navigate,
        setCameraOptions,
        setLoadingCameras
      );
    }
  }, []);

  React.useEffect(() => {
    if (searchParams.size > 0) {
      setFiltersApplied(true);
      setFectchingAllData(true);
      localStorage.removeItem("backupCHMAlertsData");
      getAllHMData(
        setErrorsOverviewData,
        setCameraStatsData,
        setLoadingCameraStatsData,
        setUnhealthyCamsData,
        setAlertsData,
        setFectchingAllData,
        userFilters,
        setActiveTable,
        setAlertStatusDialogOpen,
        setSelectedRow,
        setSelectedRowIndex,
        setUpdatingStatus,
        setBandwidthData,
        setLoadingBandwidthData,
        setShowSiteStats,
        cameraDropDownValue,
        setBandwidthLoadingError,
        setSearchParams,
        setNotesValue,
        setBackgroundFrame,
        setDetectedFrame,
        setNoAlertsDialogOpen,
        setClickedTab,
        navigate
      );
    }
  }, []);

  React.useEffect(() => {
    if (filtersApplied) {
      handleUpdateFilteredDataCounts(
        singleCellActivated,
        alertsData,
        activeName,
        setFilteredDataLengths,
        alertFilter
      );
    }
  }, [updatingStatus, activeTable]);

  React.useEffect(() => {
    setDownloadURL("");
    handleDownloadReport(userFilters, setDownloadURL, navigate);
  }, []);

  return (
    <div>
      <BackdropLoadingScreen openState={fetchingAllData} />
      <BackdropLoadingScreen openState={updatingStatus} />
      <div className="bg-actuate-white absolute w-full h-full overflow-scroll">
        {alertStatusDialogOpen && (
          <AlertStatusDialog
            alertsData={alertsData}
            alertStatusDialogOpen={alertStatusDialogOpen}
            setAlertStatusDialogOpen={setAlertStatusDialogOpen}
            selectedRow={selectedRow}
            selectedRowIndex={selectedRowIndex}
            setSelectedRowIndex={setSelectedRowIndex}
            setSelectedRow={setSelectedRow}
            clickedTab={clickedTab}
            setFectchingAllData={setUpdatingStatus}
            setUnhealthyCamsData={setUnhealthyCamsData}
            unhealthyCamsData={unhealthyCamsData}
            setAlertsData={setAlertsData}
            notesValue={notesValue}
            setNotesValue={setNotesValue}
            selectedRowsToSend={selectedRowsToSend}
            setSelectedRowsToSend={setSelectedRowsToSend}
            setSortedData={setSortedData}
            setActiveTable={setActiveTable}
            sortedData={sortedData}
            singleCellActivated={singleCellActivated}
            activeName={activeName}
            filters={userFilters}
            alertFilter={alertFilter}
            setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
            setUpdatingStatus={setUpdatingStatus}
            backgroundFrame={backgroundFrame}
            detectedFrame={detectedFrame}
            setBackgroundFrame={setBackgroundFrame}
            setDetectedFrame={setDetectedFrame}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            setErrorsOverviewData={setErrorsOverviewData}
            setCameraStatsData={setCameraStatsData}
            setLoadingCameraStatsData={setLoadingCameraStatsData}
            setBandwidthData={setBandwidthData}
            setLoadingBandwidthData={setLoadingBandwidthData}
            setShowSiteStats={setShowSiteStats}
            cameraDropDownValue={cameraDropDownValue}
            setFilteredDataLengths={setFilteredDataLengths}
            sceneChangeValueString={sceneChangeValueString}
            setBulkSceneChangeOutcomesDialogOpen={
              setBulkSceneChangeOutcomesDialogOpen
            }
          />
        )}
        {sceneChangeOutcomesDialogOpen && (
          <SceneChangeOutcomesDialog
            sceneChangeOutcomesDialogOpen={sceneChangeOutcomesDialogOpen}
            setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
            setUpdatingStatus={setUpdatingStatus}
            selectedObject={selectedRow}
            setAlertsData={setAlertsData}
            alertsData={alertsData}
            setFectchingAllData={setFectchingAllData}
            setUnhealthyCamsData={setUnhealthyCamsData}
            unhealthyCamsData={unhealthyCamsData}
            clickedTab={clickedTab}
            setActiveTable={setActiveTable}
            setAlertStatusDialogOpen={setAlertStatusDialogOpen}
            setSelectedRow={setSelectedRow}
            setSelectedRowIndex={setSelectedRowIndex}
            setSortedData={setSortedData}
            singleCellActivated={singleCellActivated}
            activeName={activeName}
            filters={userFilters}
            alertFilter={alertFilter}
            setBackgroundFrame={setBackgroundFrame}
            setDetectedFrame={setDetectedFrame}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            setErrorsOverviewData={setErrorsOverviewData}
            setCameraStatsData={setCameraStatsData}
            setLoadingCameraStatsData={setLoadingCameraStatsData}
            setBandwidthData={setBandwidthData}
            setLoadingBandwidthData={setLoadingBandwidthData}
            setShowSiteStats={setShowSiteStats}
            cameraDropDownValue={cameraDropDownValue}
            setFilteredDataLengths={setFilteredDataLengths}
            sceneChangeValueString={sceneChangeValueString}
            setSceneChangeValueString={setSceneChangeValueString}
            setBulkSceneChangeOutcomesDialogOpen={
              setBulkSceneChangeOutcomesDialogOpen
            }
            navigate={navigate}
          />
        )}
        {bulkSceneChangeOutcomesDialogOpen && (
          <BulkSceneChangeOutcomesDialog
            bulkSceneChangeOutcomesDialogOpen={
              bulkSceneChangeOutcomesDialogOpen
            }
            setBulkSceneChangeOutcomesDialogOpen={
              setBulkSceneChangeOutcomesDialogOpen
            }
            alertsArray={JSON.parse(localStorage.selectedCHMAlertsArray)}
            setUpdatingStatus={setUpdatingStatus}
            setAlertsData={setAlertsData}
            alertsData={alertsData}
            setFectchingAllData={setFectchingAllData}
            setUnhealthyCamsData={setUnhealthyCamsData}
            unhealthyCamsData={unhealthyCamsData}
            clickedTab={clickedTab}
            setActiveTable={setActiveTable}
            setAlertStatusDialogOpen={setAlertStatusDialogOpen}
            setSelectedRow={setSelectedRow}
            setSelectedRowIndex={setSelectedRowIndex}
            setSortedData={setSortedData}
            singleCellActivated={singleCellActivated}
            activeName={activeName}
            filters={userFilters}
            alertFilter={alertFilter}
            setBackgroundFrame={setBackgroundFrame}
            setDetectedFrame={setDetectedFrame}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            setErrorsOverviewData={setErrorsOverviewData}
            setCameraStatsData={setCameraStatsData}
            setLoadingCameraStatsData={setLoadingCameraStatsData}
            setBandwidthData={setBandwidthData}
            setLoadingBandwidthData={setLoadingBandwidthData}
            setShowSiteStats={setShowSiteStats}
            cameraDropDownValue={cameraDropDownValue}
            setFilteredDataLengths={setFilteredDataLengths}
            setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
            sceneChangeValueString={sceneChangeValueString}
            setSceneChangeValueString={setSceneChangeValueString}
            navigate={navigate}
          />
        )}
        {noAlertsDialogOpen && (
          <NoAlertsDialog
            noAlertsDialogOpen={noAlertsDialogOpen}
            setNoAlertsDialogOpen={setNoAlertsDialogOpen}
            cameraID={openAlertCameraId}
            errorType={openAlertErrorType}
            createdTimestamp={openAlertcreatedTimestamp}
            siteID={searchParams.get("site") ? searchParams.get("site") : ""}
          />
        )}
        <div
          className={
            !navBarCollapsed ? "pl-[170px] pt-[10px]" : "pl-[95px] pt-[10px]"
          }
        >
          <FiltersHealthMonitoring
            userFilters={userFilters}
            setUserFilters={setUserFilters}
            setSiteOptions={setSiteOptions}
            setSiteDropDownValue={setSiteDropDownValue}
            setCameraOptions={setCameraOptions}
            setCameraDropDownValue={setCameraDropDownValue}
            siteOptions={siteOptions}
            siteDropDownValue={siteDropDownValue}
            loadingSites={loadingSites}
            setLoadingCameras={setLoadingCameras}
            setLoadingSites={setLoadingSites}
            cameraOptions={cameraOptions}
            cameraDropDownValue={cameraDropDownValue}
            loadingCameras={loadingCameras}
            navBarCollapsed={navBarCollapsed}
            metricsSelected={metricsSelected}
            setMetricsSelected={setMetricsSelected}
            navigate={navigate}
          />
        </div>
        {/* <div className="absolute top-[17px] left-[670px]">
          <button
          onClick={() => 
          }>
            alertsData
          </button>

        </div> */}

        <div className="absolute top-[17px] left-[1270px]">
          <ApplyFiltersButtonHM
            userFilters={userFilters}
            setUserFilters={setUserFilters}
            metricsSelected={metricsSelected}
            setErrorsOverviewData={setErrorsOverviewData}
            setCameraStatsData={setCameraStatsData}
            setLoadingCameraStatsData={setLoadingCameraStatsData}
            setUnhealthyCamsData={setUnhealthyCamsData}
            setAlertsData={setAlertsData}
            setFectchingAllData={setFectchingAllData}
            setActiveTable={setActiveTable}
            setAlertStatusDialogOpen={setAlertStatusDialogOpen}
            setSelectedRow={setSelectedRow}
            setSelectedRowIndex={setSelectedRowIndex}
            setUpdatingStatus={setUpdatingStatus}
            setBandwidthData={setBandwidthData}
            setLoadingBandwidthData={setLoadingBandwidthData}
            setShowSiteStats={setShowSiteStats}
            setSingleCellActivated={setSingleCellActivated}
            setActiveName={setActiveName}
            cameraDropDownValue={cameraDropDownValue}
            setDownloadURL={setDownloadURL}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setBandwidthLoadingError={setBandwidthLoadingError}
            setNotesValue={setNotesValue}
            setBackgroundFrame={setBackgroundFrame}
            setDetectedFrame={setDetectedFrame}
            setNoAlertsDialogOpen={setNoAlertsDialogOpen}
            setFiltersApplied={setFiltersApplied}
            setClickedTab={setClickedTab}
            navigate={navigate}
          />
        </div>
        {!fetchingAllData && filtersApplied && (
          <div
            className={
              !navBarCollapsed
                ? "absolute left-[150px]"
                : "absolute left-[75px]"
            }
          >
            <div className="absolute">
              <SVGLine navBarCollapsed={navBarCollapsed} />
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[38px] left-[1090px] w-[200px]"
                  : "absolute top-[38px] left-[1165px] w-[200px]"
              }
            >
              <DownloadReportButton downloadURL={downloadURL} />
            </div>
            <div className="absolute top-[30px]">
              <ErrorsOverViewGraph
                errorsOverviewData={errorsOverviewData}
                errorWarningData={errorWarningData}
                setErrorWarningData={setErrorWarningData}
                navBarCollapsed={navBarCollapsed}
                userFilters={userFilters}
              />
            </div>
            <div className="absolute top-[30px] left-[640px]">
              <BandwidthGraph
                bandwidthData={bandwidthData}
                loadingBandwidthData={loadingBandwidthData}
                navBarCollapsed={navBarCollapsed}
                bandwidthLoadingError={bandwidthLoadingError}
                setLoadingBandwidthData={setLoadingBandwidthData}
                setBandwidthLoadingError={setBandwidthLoadingError}
                filters={userFilters}
                setBandwidthData={setBandwidthData}
                navigate={navigate}
              />
            </div>
            <div className="absolute top-[480px]">
              <CameraStatsGraph
                cameraStatsData={cameraStatsData}
                loadingCameraStatsData={loadingCameraStatsData}
                alertsData={alertsData}
                setActiveTable={setActiveTable}
                setAlertStatusDialogOpen={setAlertStatusDialogOpen}
                setSelectedRow={setSelectedRow}
                setSelectedRowIndex={setSelectedRowIndex}
                setFectchingAllData={setFectchingAllData}
                setAlertsData={setAlertsData}
                setUnhealthyCamsData={setUnhealthyCamsData}
                unhealthyCamsData={unhealthyCamsData}
                clickedTab={clickedTab}
                setSortedData={setSortedData}
                activeName={activeName}
                setActiveName={setActiveName}
                singleCellActivated={singleCellActivated}
                setSingleCellActivated={setSingleCellActivated}
                setFilteredDataLengths={setFilteredDataLengths}
                setUpdatingStatus={setUpdatingStatus}
                showSiteStats={showSiteStats}
                filters={userFilters}
                alertFilter={alertFilter}
                setSceneChangeOutcomesDialogOpen={
                  setSceneChangeOutcomesDialogOpen
                }
                setBackgroundFrame={setBackgroundFrame}
                setDetectedFrame={setDetectedFrame}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                setErrorsOverviewData={setErrorsOverviewData}
                setCameraStatsData={setCameraStatsData}
                setLoadingCameraStatsData={setLoadingCameraStatsData}
                setBandwidthData={setBandwidthData}
                setLoadingBandwidthData={setLoadingBandwidthData}
                setShowSiteStats={setShowSiteStats}
                cameraDropDownValue={cameraDropDownValue}
                navBarCollapsed={navBarCollapsed}
                userFilters={userFilters}
                sceneChangeValueString={sceneChangeValueString}
                setBulkSceneChangeOutcomesDialogOpen={
                  setBulkSceneChangeOutcomesDialogOpen
                }
                navigate={navigate}
              />
            </div>
            <div className="absolute top-[975px]">
              <AlertSection
                unhealthyCamsData={unhealthyCamsData}
                clickedTab={clickedTab}
                setClickedTab={setClickedTab}
                alertsData={alertsData}
                activeTable={activeTable}
                setActiveTable={setActiveTable}
                setAlertStatusDialogOpen={setAlertStatusDialogOpen}
                setSelectedRow={setSelectedRow}
                setSelectedRowIndex={setSelectedRowIndex}
                setFectchingAllData={setUpdatingStatus}
                setAlertsData={setAlertsData}
                updatingStatus={updatingStatus}
                setUnhealthyCamsData={setUnhealthyCamsData}
                setSortedData={setSortedData}
                singleCellActivated={singleCellActivated}
                activeName={activeName}
                filteredDataLengths={filteredDataLengths}
                setUpdatingStatus={setUpdatingStatus}
                cameraDropDownValue={cameraDropDownValue}
                filters={userFilters}
                alertFilter={alertFilter}
                setAlertFilter={setAlertFilter}
                setFilteredDataLengths={setFilteredDataLengths}
                setSceneChangeOutcomesDialogOpen={
                  setSceneChangeOutcomesDialogOpen
                }
                setBackgroundFrame={setBackgroundFrame}
                setDetectedFrame={setDetectedFrame}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                setErrorsOverviewData={setErrorsOverviewData}
                setCameraStatsData={setCameraStatsData}
                setLoadingCameraStatsData={setLoadingCameraStatsData}
                setBandwidthData={setBandwidthData}
                setLoadingBandwidthData={setLoadingBandwidthData}
                setShowSiteStats={setShowSiteStats}
                navBarCollapsed={navBarCollapsed}
                sceneChangeValueString={sceneChangeValueString}
                setBulkSceneChangeOutcomesDialogOpen={
                  setBulkSceneChangeOutcomesDialogOpen
                }
                navigate={navigate}
              />
            </div>
          </div>
        )}
        {!filtersApplied && !fetchingAllData && (
          <div className="relative top-[200px] text-center">
            <p className="font-mulish text-[22px]">
              Please Apply Filters to view data.
            </p>
            <p className="font-mulish text-[18px]">
              To view data for all sites, leave the Site and Camera filters
              empty.
            </p>
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
