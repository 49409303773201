import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { ImmixAlertsTab } from "../ImmixAlertsTab";
import { IgnoreZonesBreadCrumbs } from "../../EditIgnoreZones/IgnoreZonesBreadCrumbs";
import { createIgnoreZonesLinksArray } from "../../EditIgnoreZones/EditIgnoreZonesUtils/createIgnoreZonesLinksArray";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { getNumbersAfterEditCamera, getNumbersAfterSites } from "../EditCamera";

export const handleSubmitNewImmixAlert = async (
  cameraGeneralInfo: any,
  immixAlerts: any,
  postObject: any,
  navigate: any,
  setBackdropLoadingScreenOn: any,
  setCameraGeneralInfo: any,
  setCameraGeneralInfoBackup: any,
  setGeneralInfoChangesMade: any,
  setPatchObject: any,
  setImmixAlerts: any,
  setActiveTab: any,
  setAddImmixAlertDialogOpen: any,
  cameraGeneralInfoBackup: any,
  setIgnoreZonesBreadCrumbs: any,
  handleSitesBreadcrumbClick: any,
  handleSingularSiteBreadcrumbClick: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleSubmitNewImmixAlert(
      cameraGeneralInfo,
      immixAlerts,
      postObject,
      navigate,
      setBackdropLoadingScreenOn,
      setCameraGeneralInfo,
      setCameraGeneralInfoBackup,
      setGeneralInfoChangesMade,
      setPatchObject,
      setImmixAlerts,
      setActiveTab,
      setAddImmixAlertDialogOpen,
      cameraGeneralInfoBackup,
      setIgnoreZonesBreadCrumbs,
      handleSitesBreadcrumbClick,
      handleSingularSiteBreadcrumbClick,
      setSendingImmixTest,
      setImmixTestSuccessDialogOpen,
      setImmixTestFailDialogOpen
    );
  };

  const createNewStreamsObject = (streams: any) => {
    const updatedStreams = streams;
    updatedStreams.forEach((object: any, index: any) => {
      const updatedImmixAlerts = object.immix_alerts;
      updatedImmixAlerts.push(postObject);
      updatedStreams[index].immix_alerts = updatedImmixAlerts;
    });
    return updatedStreams;
  };

  let postPatchObjectFail = false;
  let getSiteCamerasFail = false;
  await AxiosUI.patch(
    `/camera/${getNumbersAfterEditCamera(location.href)}/general_info/`,
    {
      streams: createNewStreamsObject(cameraGeneralInfo.streams),
    }
  ).then(
    (response: AxiosResponse) => {
      postPatchObjectFail = false;
      setCameraGeneralInfo(response.data);
      setCameraGeneralInfoBackup(response.data);
      const pushedArray: any[] = [];
      response.data.streams[0].immix_alerts.forEach((alertObject: any) => {
        pushedArray.push(alertObject);
      });
      setImmixAlerts(pushedArray);
      setGeneralInfoChangesMade(false);
      setPatchObject({});
      setActiveTab(
        <ImmixAlertsTab
          immixAlerts={pushedArray}
          setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
          setSendingImmixTest={setSendingImmixTest}
          setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
          setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
          navigate={navigate}
        />
      );
      setBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      postPatchObjectFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setCameraGeneralInfo(cameraGeneralInfoBackup);
        setPatchObject({
          camera_name: cameraGeneralInfoBackup.camera_name,
          streams: cameraGeneralInfoBackup.streams,
        });
        setBackdropLoadingScreenOn(false);
      });
    }
  );
  !postPatchObjectFail &&
    (await AxiosUI.get(
      `camera/site/?customer__id=${getNumbersAfterSites(location.href)}`
    ).then(
      (response: AxiosResponse) => {
        getSiteCamerasFail = false;
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          getSiteCamerasFail = true;
        });
      }
    ));
  !postPatchObjectFail &&
    !getSiteCamerasFail &&
    (await AxiosUI.get(
      `customer/${getNumbersAfterSites(location.href)}/about/`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteInfo", JSON.stringify(response.data));
        localStorage.setItem("integrationType", response.data.integration_type);
        setIgnoreZonesBreadCrumbs(
          <IgnoreZonesBreadCrumbs
            linksArray={createIgnoreZonesLinksArray(response.data.breadcrumbs)}
            handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
            handleSingularSiteBreadcrumbClick={
              handleSingularSiteBreadcrumbClick
            }
            styling="absolute top-[10px] left-[30px]"
          />
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setBackdropLoadingScreenOn(false);
        });
      }
    ));
};
