import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { FormHelperText, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { handleSubmitNewSentinelAlert } from "./EditCameraUtils/handleSubmitNewSentinelAlert";

export const AddSentinelAlertDialog = ({
  setAddSentinelAlertDialogOpen,
  addSentinelAlertDialogOpen,
  sentinelAlerts,
  setBackdropLoadingScreenOn,
  setCameraGeneralInfo,
  setCameraGeneralInfoBackup,
  setGeneralInfoChangesMade,
  setPatchObject,
  setSentinelAlerts,
  setActiveTab,
  cameraGeneralInfoBackup,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  cameraGeneralInfo,
}: {
  setAddSentinelAlertDialogOpen: Dispatcher<boolean>;
  addSentinelAlertDialogOpen: boolean;
  sentinelAlerts: any;
  setBackdropLoadingScreenOn: any;
  setCameraGeneralInfo: any;
  setCameraGeneralInfoBackup: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setSentinelAlerts: any;
  setActiveTab: any;
  cameraGeneralInfoBackup: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  cameraGeneralInfo: any;
}) => {
  const navigate = useNavigate();

  const [postObject, setPostObject] = React.useState({
    id: -1,
    sentinel_server: "",
    sentinel_site_id: "",
    sentinel_device_address: "",
    draw_ignore_zones: false,
  });

  React.useEffect(() => {
    if (sentinelAlerts[0]) {
      setPostObject({
        id: -1,
        sentinel_server: sentinelAlerts[0].sentinel_server,
        sentinel_site_id: sentinelAlerts[0].sentinel_site_id,
        sentinel_device_address: sentinelAlerts[0].sentinel_device_address,
        draw_ignore_zones: sentinelAlerts[0].draw_ignore_zones,
      });
    }
  }, []);

  return (
    <Dialog open={addSentinelAlertDialogOpen}>
      <DialogTitle id="alert-dialog-title">Add Sentinel Alert</DialogTitle>
      <DialogContent>
        <TextField
          id="sentinel_server"
          variant="standard"
          label="Sentinel Server"
          defaultValue={
            sentinelAlerts[0] ? sentinelAlerts[0].sentinel_server : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, sentinel_server: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          helperText="What is the url for your Sentinel server."
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="sentinel_site_id"
          variant="standard"
          label="Sentinel site id"
          defaultValue={
            sentinelAlerts[0] ? sentinelAlerts[0].sentinel_site_id : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, sentinel_site_id: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="sentinel_device_address"
          variant="standard"
          label="Sentinel device address"
          defaultValue={
            sentinelAlerts[0] ? sentinelAlerts[0].sentinel_device_address : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                sentinel_device_address: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <div>
          <FormControlLabel
            sx={{
              fontFamily: "Mulish",
              color: "#001943",
            }}
            label="Draw Ignore Zones"
            control={
              <Checkbox
                color="primary"
                defaultChecked={
                  sentinelAlerts[0]
                    ? sentinelAlerts[0].draw_ignore_zones
                    : false
                }
                onChange={(event, checked) => {
                  setPostObject((previousState: any) => {
                    return { ...previousState, draw_ignore_zones: checked };
                  });
                }}
              />
            }
          />
          <FormHelperText>
            If checked, draws ignore zones in the alert images.
          </FormHelperText>
        </div>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddSentinelAlertDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setAddSentinelAlertDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleSubmitNewSentinelAlert(
              cameraGeneralInfo,
              sentinelAlerts,
              postObject,
              navigate,
              setBackdropLoadingScreenOn,
              setCameraGeneralInfo,
              setCameraGeneralInfoBackup,
              setGeneralInfoChangesMade,
              setPatchObject,
              setSentinelAlerts,
              setActiveTab,
              setAddSentinelAlertDialogOpen,
              cameraGeneralInfoBackup,
              setIgnoreZonesBreadCrumbs,
              handleSitesBreadcrumbClick,
              handleSingularSiteBreadcrumbClick
            );
          }}
          text="Submit"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
