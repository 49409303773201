/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AlertData, Order } from "./AlertsUtils/alertsInterfaces";
import { getComparator, stableSort } from "./AlertsUtils/alertsTableFunctions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { EnhancedAlertsTableHead } from "./EnhancedAlertsTableHead";
import { handleRowSelected } from "./AlertsUtils/handleRowSelected";
import { createDetctionTypeIcon } from "./AlertsUtils/createDetctionTypeIcon";
import { Tooltip } from "@mui/material";
import { FilterMenu } from "./FilterMenu";
import { handleReorderIndex } from "./AlertsUtils/handleReorderIndex";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DetectionTypeFilter } from "./DetectionTypeFilter";
import { filterByDetectionType } from "./AlertsUtils/filterByDetectionType";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { saveAs } from "file-saver";
import { OutcomeTypeFilter } from "./OutcomeTypeFilter";

export const handleCreateAlertTypeNoUnderscore = (name: string) => {
  //first make the first letter uppercase
  let newName = name.slice(0, 1).toUpperCase() + name.slice(1);
  //then check if there is an underscore and if so replace it with a space
  if (newName.includes("_")) {
    newName = newName.replace("_", " ");
  }
  return newName;
};

export const setDetectionTypeLower = (detectionType: string) => {
  let detectionTypeLower =
    detectionType.slice(0, 1).toLowerCase() + detectionType.slice(1);

  //check if there is a space in the detection type and if so replace it with an underscore
  if (detectionTypeLower.includes(" ")) {
    detectionTypeLower = detectionTypeLower.replace(" ", "_");
  }
  return detectionTypeLower;
};

export const AlertsTable = ({
  selected,
  alertData,
  setAlertData,
  setSelected,
  setSelectedRow,
  setSelectedButtonsFilled,
  setSeeMoreURL,
  navigate,
  setMP4URL,
  page,
  setPage,
  userFilters,
  setFetchingFilteredData,
  setUserFilters,
  setThumbnailVideos,
  setDateOutsideRange,
  setVideoClipLoading,
  setAlertsToDisplay,
  setPageLoading,
  setSelectedAlertCameraInfo,
  setIgnoreZonesSelectedAlert,
  setAlertsIndexOrder,
  setRefreshInterval,
  currentName,
  setCurrentName,
  pageLoadedNumber,
  setPageLoadedNumber,
  setRefreshingData,
  cameraOptions,
  siteOptions,
  scrollToTop,
  refreshingToken,
  setRefreshingToken,
  setAlertLabels,
  alertLabels,
  detectionType,
  setDetectionType,
  setPeriodHours,
  selectedRow,
  setQueryLimitHit,
  searchParams,
  setSearchParams,
  alertsDownloadURL,
  refreshInterval,
  outcomeFilterType,
  setOutcomeFilterType,
  setBackArrowDisabled,
  setForwardArrowDisabled,
}: {
  selected: any;
  alertData: AlertData[];
  setAlertData: any;
  setSelected: any;
  setSelectedRow: any;
  setSelectedButtonsFilled: any;
  setSeeMoreURL: any;
  navigate: any;
  setMP4URL: any;
  page: number;
  setPage: any;
  userFilters: any;
  setFetchingFilteredData: any;
  setUserFilters: any;
  setThumbnailVideos: any;
  setDateOutsideRange: any;
  setVideoClipLoading: any;
  setAlertsToDisplay: any;
  setPageLoading: any;
  setSelectedAlertCameraInfo: any;
  setIgnoreZonesSelectedAlert: any;
  setAlertsIndexOrder: any;
  setRefreshInterval: any;
  currentName: any;
  setCurrentName: any;
  pageLoadedNumber: number;
  setPageLoadedNumber: any;
  setRefreshingData: any;
  cameraOptions: any;
  siteOptions: any;
  scrollToTop: any;
  refreshingToken: boolean;
  setRefreshingToken: any;
  setAlertLabels: any;
  alertLabels: string[];
  detectionType: string;
  setDetectionType: any;
  setPeriodHours: any;
  selectedRow: any;
  setQueryLimitHit: any;
  searchParams: any;
  setSearchParams: any;
  alertsDownloadURL: string;
  refreshInterval: any;
  outcomeFilterType: string;
  setOutcomeFilterType: any;
  setBackArrowDisabled: any;
  setForwardArrowDisabled: any;
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [count, setCount] = React.useState(alertData.length);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AlertData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF8400",
      },
    },
  });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - alertData.length) : 0;

  const checkRowsForFilter = (
    alertData: any,
    detectionType: string,
    outcomeFilterType: string
  ) => {
    if (detectionType === "All" && outcomeFilterType === "All") {
      return alertData;
    } else if (detectionType !== "All" && outcomeFilterType === "All") {
      setCount(
        alertData.filter(
          (row: any) =>
            row.alert_labels === setDetectionTypeLower(detectionType)
        ).length
      );
      return alertData.filter(
        (row: any) => row.alert_labels === setDetectionTypeLower(detectionType)
      );
    } else if (detectionType === "All" && outcomeFilterType !== "All") {
      setCount(
        alertData.filter((row: any) => row.detection_type === outcomeFilterType)
          .length
      );
      return alertData.filter(
        (row: any) => row.detection_type === outcomeFilterType
      );
    } else if (detectionType !== "All" && outcomeFilterType !== "All") {
      setCount(
        alertData.filter(
          (row: any) =>
            row.alert_labels === setDetectionTypeLower(detectionType) &&
            row.detection_type === outcomeFilterType
        ).length
      );
      return alertData.filter(
        (row: any) =>
          row.alert_labels === setDetectionTypeLower(detectionType) &&
          row.detection_type === outcomeFilterType
      );
    }
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        checkRowsForFilter(alertData, detectionType, outcomeFilterType),
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage, detectionType, outcomeFilterType]
  );

  React.useEffect(() => {
    handleReorderIndex(
      stableSort(alertData, getComparator(order, orderBy)),
      setAlertsIndexOrder,
      alertData,
      setAlertData,
      "alerts",
      detectionType
    );
  }, [order, visibleRows]);

  return (
    <Paper>
      <div className="relative top-[8px] h-[35px]">
        {/* <div className="absolute left-[5px]">
            <MarkAsSeenButton
              alertData={alertData}
              setAlertData={setAlertData}
            />
          </div> */}
        <div className="absolute left-[0px]">
          <FilterMenu
            userFilters={userFilters}
            setFetchingFilteredData={setFetchingFilteredData}
            setAlertData={setAlertData}
            navigate={navigate}
            setSelectedRow={setSelectedRow}
            setSeeMoreURL={setSeeMoreURL}
            setMP4URL={setMP4URL}
            setUserFilters={setUserFilters}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            setThumbnailVideos={setThumbnailVideos}
            setDateOutsideRange={setDateOutsideRange}
            setPage={setPage}
            setVideoClipLoading={setVideoClipLoading}
            setAlertsToDisplay={setAlertsToDisplay}
            setPageLoading={setPageLoading}
            setSelectedAlertCameraInfo={setSelectedAlertCameraInfo}
            setIgnoreZonesSelectedAlert={setIgnoreZonesSelectedAlert}
            setAlertsIndexOrder={setAlertsIndexOrder}
            setRefreshInterval={setRefreshInterval}
            currentName={currentName}
            setCurrentName={setCurrentName}
            pageLoadedNumber={pageLoadedNumber}
            setPageLoadedNumber={setPageLoadedNumber}
            setRefreshingData={setRefreshingData}
            cameraOptions={cameraOptions}
            siteOptions={siteOptions}
            setRefreshingToken={setRefreshingToken}
            refreshingToken={refreshingToken}
            setAlertLabels={setAlertLabels}
            setPeriodHours={setPeriodHours}
            setQueryLimitHit={setQueryLimitHit}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            detectionType={detectionType}
            refreshInterval={refreshInterval}
            setOutcomeFilterType={setOutcomeFilterType}
            setForwardArrowDisabled={setForwardArrowDisabled}
            setBackArrowDisabled={setBackArrowDisabled}
          />
        </div>
        {currentName === "Resolved Alerts" && (
          <div className="absolute">
            <OutcomeTypeFilter
              outcomeFilterType={outcomeFilterType}
              setOutcomeFilterType={setOutcomeFilterType}
              setPage={setPage}
            />
          </div>
        )}
        <div className="absolute ml-auto right-[5px]">
          <DetectionTypeFilter
            alertLabels={alertLabels}
            detectionType={detectionType}
            setDetectionType={setDetectionType}
            userFilters={userFilters}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setPage={setPage}
          />
        </div>
      </div>
      <TableContainer sx={{ height: 650 }}>
        <Table
          sx={{ fontFamily: "Mulish" }}
          aria-labelledby="tableTitle"
          stickyHeader={true}
        >
          <EnhancedAlertsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            setSelected={setSelected}
            alertData={alertData}
            rowCount={alertData.length}
            numSelected={selected.length}
            setSelectedRow={setSelectedRow}
            setAlertData={setAlertData}
          />

          {alertData.length === 0 && (
            <TableBody>
              <TableRow></TableRow>
            </TableBody>
          )}
          {alertData.length > 0 && (
            <TableBody>
              {visibleRows.map((row: any, index: number) => {
                let sx: any = { cursor: "pointer" };
                if (!row.seen && !row.selected) {
                  sx = {
                    cursor: "pointer",
                    backgroundColor: "rgba(255, 132, 0, 0.08)",
                  };
                }
                if (row.seen && row.selected) {
                  sx = {
                    cursor: "pointer",
                    backgroundColor: "rgba(2, 136, 209, 0.2)",
                  };
                }
                if (!row.seen && row.selected) {
                  sx = {
                    cursor: "pointer",
                    backgroundColor: "rgba(2, 136, 209, 0.2)",
                  };
                }
                return (
                  <React.Fragment key={`${row.formatted_date}_${index}`}>
                    <ThemeProvider theme={theme}>
                      <TableRow
                        hover
                        onClick={() => {
                          handleRowSelected(
                            row,
                            alertData,
                            setAlertData,
                            setSelectedButtonsFilled,
                            setSeeMoreURL,
                            navigate,
                            setMP4URL,
                            setVideoClipLoading,
                            setSelectedRow,
                            setSelectedAlertCameraInfo,
                            setIgnoreZonesSelectedAlert,
                            refreshingToken,
                            visibleRows,
                            setBackArrowDisabled,
                            setForwardArrowDisabled
                          );
                          scrollToTop();
                        }}
                        tabIndex={-1}
                        key={row.id}
                        sx={sx}
                      >
                        <TableCell>
                          {row.seen ? (
                            <></>
                          ) : (
                            <FiberManualRecordIcon
                              fontSize="small"
                              color="warning"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "10px", fontFamily: "mulish" }}
                        >
                          {row.display_name
                            ? row.display_name
                            : row.customer_name}
                        </TableCell>
                        {/* </Tooltip> */}
                        <TableCell
                          align="left"
                          sx={{ fontSize: "10px", fontFamily: "mulish" }}
                        >
                          {row.camera_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "10px", fontFamily: "mulish" }}
                        >
                          {row.formatted_date}
                          &nbsp;
                          <Tooltip
                            title={row.timezone}
                            placement="top"
                            sx={{ fontSize: "10px", fontFamily: "mulish" }}
                          >
                            <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "10px", fontFamily: "mulish" }}
                        >{`${handleCreateAlertTypeNoUnderscore(
                          row.alert_labels
                        )}`}</TableCell>
                        <TableCell align="left">
                          {createDetctionTypeIcon(row.detection_type)}
                        </TableCell>
                        {/* <TableCell>
                        {row.detection_type && (
                          <OrangeTextButton
                            text="See Notes"
                            onClick={() => {
                              setNoteText(row.notes);
                              setNotesDialogOpen(true);
                            }}
                            disabled={false}
                          />
                        )}
                      </TableCell> */}
                      </TableRow>
                      {/* </Tooltip> */}
                    </ThemeProvider>
                  </React.Fragment>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <div className="absolute pt-[8px] pl-[5px] z-20">
        <OrangeTextButton
          text="Download Report"
          onClick={() => saveAs(alertsDownloadURL, "report.csv")}
          disabled={!alertsDownloadURL}
        />
      </div>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
