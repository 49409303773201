import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";

export const StartDateDropDownAILink = ({
  userFilters,
  setUserFilters,
  setTimeFiltersDisabledAILink,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  setTimeFiltersDisabledAILink: Dispatcher<boolean>;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Start Date"
        value={userFilters.start_date}
        onChange={(newValue) => {
          if (newValue !== null) {
            setUserFilters((previousState: any) => {
              return { ...previousState, start_date: newValue };
            });
          }
          if (newValue === null) {
            setUserFilters((previousState: any) => {
              return { ...previousState, start_date: null };
            });
          }
          if (
            yyyymmddGenerator(newValue) !==
            yyyymmddGenerator(userFilters.end_date)
          ) {
            setTimeFiltersDisabledAILink(true);
            setUserFilters((previousState: any) => {
              return { ...previousState, start_time: null, end_time: null };
            });
          }
          if (
            yyyymmddGenerator(newValue) ===
            yyyymmddGenerator(userFilters.end_date)
          ) {
            setTimeFiltersDisabledAILink(false);
          }
        }}
        renderInput={(params) => <TextField sx={{ width: 150 }} {...params} />}
      />
    </LocalizationProvider>
  );
};
