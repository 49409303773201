import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { TableHeadComponent } from "../../common/TableHead";
import { getNumbersAfterEditCamera } from "./EditCamera";

export const NotificationsTab = ({
  cameraGeneralInfo,
}: {
  cameraGeneralInfo: any;
}) => {
  const [titlesArray, setTitlesArray] = React.useState(["", ""]);

  React.useEffect(() => {
    if (cameraGeneralInfo.streams[0].show_nvr_alarm) {
      setTitlesArray(["Ses recipients", "NVR Alarm"]);
    } else {
      setTitlesArray(["Ses recipients", ""]);
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          <TableRow key={getNumbersAfterEditCamera(location.href)}>
            <TableCell align="left" sx={{ fontFamily: "Mulish", width: 600 }}>
              {cameraGeneralInfo.streams[0].ses_recipients
                ? cameraGeneralInfo.streams[0].ses_recipients
                : "--"}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {cameraGeneralInfo.streams[0].show_nvr_alarm && (
                <Switch
                  checked={cameraGeneralInfo.streams[0].nvr_alarm}
                  color="warning"
                  disabled={true}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
