import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const CameraDropDownSentinel = ({
  setUserFiltersSentinel,
  cameraOptionsSentinel,
  cameraDropDownValueSentinel,
  setCameraDropDownValueSentinel,
  siteOptionsSentinel,
  loadingCamerasSentinel,
  showUserDropDown,
  navBarCollapsed,
}: {
  setUserFiltersSentinel: Dispatcher<any>;
  cameraOptionsSentinel: any;
  cameraDropDownValueSentinel: any;
  setCameraDropDownValueSentinel: Dispatcher<string>;
  siteOptionsSentinel: any;
  loadingCamerasSentinel: boolean;
  showUserDropDown: boolean;
  navBarCollapsed: boolean;
}) => {
  const cameraIds = new Map();
  cameraOptionsSentinel.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });

  const siteIds = new Map();
  siteOptionsSentinel.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  let sx: any;

  if (showUserDropDown && navBarCollapsed) {
    sx = { width: 175 };
  }

  if (showUserDropDown && !navBarCollapsed) {
    sx = { width: 150 };
  }

  if (!showUserDropDown && navBarCollapsed) {
    sx = { width: 235 };
  }

  if (!showUserDropDown && !navBarCollapsed) {
    sx = { width: 200 };
  }

  return (
    <div key="camera_dropdown">
      <Autocomplete
        value={cameraDropDownValueSentinel}
        disablePortal
        options={
          cameraOptionsSentinel[0]
            ? cameraOptionsSentinel.map((object: any) => {
                return object.camera_name;
              })
            : []
        }
        loading={loadingCamerasSentinel}
        onChange={(event, value: any) => {
          if (value !== "") {
            setCameraDropDownValueSentinel(value);
            setUserFiltersSentinel((previousState: any) => {
              return { ...previousState, camera_ids: [cameraIds.get(value)] };
            });
          } else {
            setCameraDropDownValueSentinel(value);
            setUserFiltersSentinel((previousState: any) => {
              return { ...previousState, camera_ids: [] };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Camera"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCamerasSentinel ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={sx}
          />
        )}
      />
    </div>
  );
};
