import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from "@mui/material";
import { handleSubmitNewSureviewAlert } from "./EditCameraUtils/handleSubmitNewSureviewAlert";

export const AddSureviewAlertDialog = ({
  setAddSureviewAlertDialogOpen,
  addSureviewAlertDialogOpen,
  sureViewAlerts,
  setBackdropLoadingScreenOn,
  setCameraGeneralInfo,
  setCameraGeneralInfoBackup,
  setGeneralInfoChangesMade,
  setPatchObject,
  setSureviewAlerts,
  setActiveTab,
  cameraGeneralInfoBackup,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  cameraGeneralInfo,
}: {
  setAddSureviewAlertDialogOpen: any;
  addSureviewAlertDialogOpen: any;
  sureViewAlerts: any;
  setBackdropLoadingScreenOn: any;
  setCameraGeneralInfo: any;
  setCameraGeneralInfoBackup: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setSureviewAlerts: any;
  setActiveTab: any;
  cameraGeneralInfoBackup: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  cameraGeneralInfo: any;
}) => {
  const navigate = useNavigate();

  const serverOptions = [
    ["US SMTP Server", "us-smtp.sureviewops.com"],
    ["EU SMTP Server", "eu-smtp.sureviewops.com"],
  ];

  const optionsIds = new Map();
  const optionNames = new Map();
  serverOptions.forEach((option: any) => {
    optionsIds.set(option[0], option[1]);
  });
  serverOptions.forEach((option: any) => {
    optionNames.set(option[1], option[0]);
  });

  const [postObject, setPostObject] = React.useState({
    id: null,
    sureview_server: "",
    sureview_subject: "",
    sureview_cc: "",
    sureview_email: "",
  });

  React.useEffect(() => {
    if (sureViewAlerts[0]) {
      setPostObject({
        id: null,
        sureview_server: sureViewAlerts[0].sureview_server,
        sureview_subject: sureViewAlerts[0].sureview_subject,
        sureview_cc: sureViewAlerts[0].sureview_cc,
        sureview_email: sureViewAlerts[0].sureview_email,
      });
    }
  }, []);

  return (
    <Dialog
      open={addSureviewAlertDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Sureview Alert</DialogTitle>
      <DialogContent>
        <div key="sureview_server">
          <Autocomplete
            disablePortal
            id="sureview_server"
            defaultValue={
              sureViewAlerts[0]
                ? optionNames.get(sureViewAlerts[0].sureview_server)
                : ""
            }
            options={serverOptions.map((option: string[]) => {
              return option[0];
            })}
            onChange={(event: any, newInputValue: any) => {
              setPostObject((previousState: any) => {
                return {
                  ...previousState,
                  sureview_server: optionsIds.get(newInputValue),
                };
              });
            }}
            sx={{ width: 350 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Sureview Server"
                autoComplete="off"
                id="sureview_server"
                name="sureview_server"
              />
            )}
          />
        </div>
        <br></br>
        <TextField
          id="sureview_subject"
          variant="standard"
          label="Sureview Subject"
          defaultValue={
            sureViewAlerts[0] ? sureViewAlerts[0].sureview_subject : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, sureview_subject: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="sureview_cc"
          variant="standard"
          label="Sureview cc"
          defaultValue={sureViewAlerts[0] ? sureViewAlerts[0].sureview_cc : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, sureview_cc: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="sureview_email"
          variant="standard"
          label="Sureview Email"
          defaultValue={
            sureViewAlerts[0] ? sureViewAlerts[0].sureview_email : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, sureview_email: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          helperText=""
          type="string"
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddSureviewAlertDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleSubmitNewSureviewAlert(
              cameraGeneralInfo,
              sureViewAlerts,
              postObject,
              navigate,
              setBackdropLoadingScreenOn,
              setCameraGeneralInfo,
              setCameraGeneralInfoBackup,
              setGeneralInfoChangesMade,
              setPatchObject,
              setSureviewAlerts,
              setActiveTab,
              setAddSureviewAlertDialogOpen,
              cameraGeneralInfoBackup,
              setIgnoreZonesBreadCrumbs,
              handleSitesBreadcrumbClick,
              handleSingularSiteBreadcrumbClick
            );
          }}
          text="Submit"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
