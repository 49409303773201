import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "./siteAboutInterfaces";
import { CameraHealthTabEditMode } from "../CameraHealthTabEditMode";
import { CameraHealthTab } from "../CameraHealthTab";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleSubmitCameraHealthData = (
  healthCheckData: any,
  setHealthCheckData: any,
  cameraHealthPatchObject: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: Dispatcher<boolean>,
  setActiveTab: any,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  siteInfo: any,
  setAddCameraHealthDialogOpen: Dispatcher<boolean>,
  addCameraHealthDialogOpen: boolean,
  setSiteInfo: any,
  setEditMode: Dispatcher<boolean>,
  setTurnOffHealthDialogOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleSubmitCameraHealthData(
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      setActiveTab,
      setBackdropLoadingScreenOn,
      siteInfo,
      setAddCameraHealthDialogOpen,
      addCameraHealthDialogOpen,
      setSiteInfo,
      setEditMode,
      setTurnOffHealthDialogOpen,
      navigate
    );
  };

  const updatedPatchObject: any = {};

  for (const key in cameraHealthPatchObject) {
    if (key !== "deployment") {
      updatedPatchObject[key] = cameraHealthPatchObject[key];
    }
  }

  AxiosUI.patch(`/healthcheck/${healthCheckData.id}/`, updatedPatchObject).then(
    (response: AxiosResponse) => {
      setHealthCheckData(response.data);
      const newSiteInfo = siteInfo;
      newSiteInfo.chm = true;
      setActiveTab(<></>);
      setSiteInfo((previousState: any) => {
        return { ...previousState, chm: true };
      });
      setEditMode(false);
      AxiosUI.patch(
        `/healthcheck/by_customer/${getNumbersAfterAboutSite(location.href)}/`,
        {
          chm: true,
        }
      ).then(
        () => {
          setTimeout(() => {
            setActiveTab(
              <CameraHealthTab
                healthCheckData={cameraHealthPatchObject}
                siteInfo={newSiteInfo}
              />
            );
          }, 1);
          setBackdropLoadingScreenOn(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setActiveTab(<></>);
            setTimeout(() => {
              setActiveTab(
                <CameraHealthTabEditMode
                  healthCheckData={healthCheckData}
                  setCameraHealthPatchObject={setCameraHealthPatchObject}
                  setCameraHealthChangesMade={setCameraHealthChangesMade}
                  siteInfo={siteInfo}
                  setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
                  setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
                />
              );
            }, 1);
            alert("Error adding Camera Health, please try again.");
            setBackdropLoadingScreenOn(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setActiveTab(<></>);
        setTimeout(() => {
          setActiveTab(
            <CameraHealthTabEditMode
              healthCheckData={healthCheckData}
              setCameraHealthPatchObject={setCameraHealthPatchObject}
              setCameraHealthChangesMade={setCameraHealthChangesMade}
              siteInfo={siteInfo}
              setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
              setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
            />
          );
        }, 1);
        alert("Error adding Camera Health, please try again.");
        setBackdropLoadingScreenOn(false);
      });
    }
  );
};
