import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleSubmitNewSoftguardAlert } from "./EditCameraUtils/handleSubmitNewSoftguardAlert";

export const AddSoftguardAlertDialog = ({
  setAddSoftguardAlertDialogOpen,
  addSoftguardAlertDialogOpen,
  softguardAlerts,
  setBackdropLoadingScreenOn,
  setCameraGeneralInfo,
  setCameraGeneralInfoBackup,
  setGeneralInfoChangesMade,
  setPatchObject,
  setSoftguardAlerts,
  setActiveTab,
  cameraGeneralInfoBackup,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  cameraGeneralInfo,
}: {
  setAddSoftguardAlertDialogOpen: Dispatcher<boolean>;
  addSoftguardAlertDialogOpen: boolean;
  softguardAlerts: any;
  setBackdropLoadingScreenOn: any;
  setCameraGeneralInfo: any;
  setCameraGeneralInfoBackup: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setSoftguardAlerts: any;
  setActiveTab: any;
  cameraGeneralInfoBackup: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  cameraGeneralInfo: any;
}) => {
  const navigate = useNavigate();

  const [errorMode, setErrorMode] = React.useState(false);

  const [postObject, setPostObject] = React.useState({
    id: -1,
    server: "",
    port: "",
    zone: "",
    account: "",
    user: "",
    user_ext: "",
  });

  React.useEffect(() => {
    if (softguardAlerts[0]) {
      setPostObject({
        id: -1,
        server: softguardAlerts[0].server,
        port: softguardAlerts[0].port,
        zone: softguardAlerts[0].zone,
        account: softguardAlerts[0].account,
        user: softguardAlerts[0].user,
        user_ext: softguardAlerts[0].user_ext,
      });
    }
  }, []);

  React.useEffect(() => {
    if (
      postObject.server.length === 0 ||
      postObject.port.length === 0 ||
      postObject.zone.length === 0 ||
      postObject.account.length === 0 ||
      postObject.user.length === 0 ||
      postObject.user_ext.length === 0 ||
      postObject.server.length > 100 ||
      postObject.port.length > 100 ||
      postObject.zone.length > 3 ||
      postObject.account.length > 8 ||
      postObject.user.length > 3 ||
      postObject.user_ext.length > 100
    ) {
      setErrorMode(true);
    } else {
      setErrorMode(false);
    }
  }, [postObject]);

  return (
    <Dialog open={addSoftguardAlertDialogOpen}>
      <DialogTitle id="alert-dialog-title">Add Softguard Alert</DialogTitle>
      <DialogContent>
        <TextField
          id="server"
          variant="standard"
          label="Server"
          defaultValue={softguardAlerts[0] ? softguardAlerts[0].server : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, server: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          error={
            postObject.server.length === 0 || postObject.server.length > 100
          }
          helperText={
            postObject.server.length === 0 || postObject.server.length > 100
              ? postObject.server.length === 0
                ? "Server cannot be empty"
                : "Server cannot be longer than 100 characters"
              : ""
          }
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="port"
          variant="standard"
          label="Port"
          defaultValue={softguardAlerts[0] ? softguardAlerts[0].port : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                port: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          error={postObject.port.length === 0 || postObject.port.length > 100}
          helperText={
            postObject.port.length === 0 || postObject.port.length > 100
              ? postObject.port.length === 0
                ? "Port cannot be empty"
                : "Port cannot be longer than 100 characters"
              : ""
          }
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="zone"
          variant="standard"
          label="Zone"
          defaultValue={softguardAlerts[0] ? softguardAlerts[0].zone : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, zone: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          error={postObject.zone.length === 0 || postObject.zone.length > 3}
          helperText={
            postObject.zone.length === 0 || postObject.zone.length > 3
              ? postObject.zone.length === 0
                ? "Zone cannot be empty"
                : "Zone cannot be longer than 3 characters"
              : ""
          }
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="account"
          variant="standard"
          label="Account"
          defaultValue={softguardAlerts[0] ? softguardAlerts[0].account : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, account: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          error={
            postObject.account.length === 0 || postObject.account.length > 8
          }
          helperText={
            postObject.account.length === 0 || postObject.account.length > 8
              ? postObject.account.length === 0
                ? "Account cannot be empty"
                : "Account cannot be longer than 8 characters"
              : ""
          }
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="user"
          variant="standard"
          label="User"
          defaultValue={softguardAlerts[0] ? softguardAlerts[0].user : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                user: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          error={postObject.user.length === 0 || postObject.user.length > 3}
          helperText={
            postObject.user.length === 0 || postObject.user.length > 3
              ? postObject.user.length === 0
                ? "User cannot be empty"
                : "User cannot  be longer than 3 characters"
              : ""
          }
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="user_ext"
          variant="standard"
          label="User Ext"
          defaultValue={softguardAlerts[0] ? softguardAlerts[0].user_ext : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                user_ext: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          error={
            postObject.user_ext.length === 0 || postObject.user_ext.length > 100
          }
          helperText={
            postObject.user_ext.length === 0 || postObject.user_ext.length > 100
              ? postObject.user_ext.length === 0
                ? "User Ext cannot be empty"
                : "User Ext cannot be longer than 100 characters"
              : ""
          }
          type="string"
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddSoftguardAlertDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setAddSoftguardAlertDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleSubmitNewSoftguardAlert(
              cameraGeneralInfo,
              softguardAlerts,
              postObject,
              navigate,
              setBackdropLoadingScreenOn,
              setCameraGeneralInfo,
              setCameraGeneralInfoBackup,
              setGeneralInfoChangesMade,
              setPatchObject,
              setSoftguardAlerts,
              setActiveTab,
              setAddSoftguardAlertDialogOpen,
              cameraGeneralInfoBackup,
              setIgnoreZonesBreadCrumbs,
              handleSitesBreadcrumbClick,
              handleSingularSiteBreadcrumbClick
            );
          }}
          text="Submit"
          disabled={errorMode}
        />
      </DialogActions>
    </Dialog>
  );
};
