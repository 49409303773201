import React from "react";
import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { timeSlicer } from "../timeSlicer";

export const getAlertsDownloadURL = (
  setAlertsDownloadURL: Dispatcher<string>,
  groupId: string,
  siteId: string,
  cameraId: string,
  startDate: string,
  endDate: string,
  startTime: Date,
  endTime: Date
) => {
  const AxiosUI = createMonitoringAPICall(``);

  let searchParamsString = ``;

  if (groupId) {
    if (groupId !== "undefined") {
      searchParamsString += `?group_id=${groupId}`;
    }
  }

  if (siteId) {
    if (siteId !== "undefined") {
      if (groupId !== "undefined") {
        searchParamsString += `&customer_id=${siteId}`;
      } else {
        searchParamsString += `?customer_id=${siteId}`;
      }
    }
  }

  if (cameraId) {
    if (cameraId !== "undefined") {
      if (groupId !== "undefined" && siteId !== "undefined") {
        searchParamsString += `&camera_id=${cameraId}`;
      } else {
        searchParamsString += `?camera_id=${cameraId}`;
      }
    }
  }

  if (startDate) {
    if (
      groupId !== "undefined" &&
      siteId !== "undefined" &&
      cameraId !== "undefined"
    ) {
      searchParamsString += `&start=${startDate}`;
    } else {
      searchParamsString += `?start=${startDate}`;
    }
  }

  if (endDate) {
    if (
      groupId !== "undefined" &&
      siteId !== "undefined" &&
      cameraId !== "undefined" &&
      startDate
    ) {
      searchParamsString += `&end=${endDate}`;
    } else {
      searchParamsString += `?end=${endDate}`;
    }
  }

  if (startDate.toString() === endDate.toString() && startTime !== null) {
    searchParamsString += `&start_time=${timeSlicer(startTime)}`;
  }

  if (startDate.toString() === endDate.toString() && endTime !== null) {
    searchParamsString += `&end_time=${timeSlicer(endTime)}`;
  }

  if (searchParamsString.length > 0) {
    searchParamsString += `&source=v2`;
  }

  AxiosUI.get(`alert/download_v2/${searchParamsString}`).then(
    (response: AxiosResponse) => {
      setAlertsDownloadURL(response.data.url);
    },
    (error: AxiosError) => {
      // eslint-disable-next-line no-console
      console.log("error", error);
    }
  );
};
