import React from "react";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleSaveChangesButtonClick } from "./SiteAboutPageUtils/handleSaveChangesButtonClick";
import { useNavigate } from "react-router-dom";
import {
  AlertState,
  SiteSchedule,
  SiteInfo,
  PatchObject,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const SaveChangesButton = ({
  patchObject,
  setEditMode,
  setSiteInfo,
  setPatchObject,
  siteInfo,
  clickedTab,
  setActiveTab,
  vmsVariables,
  allSiteSchedules,
  siteSchedules,
  setAllSiteSchedules,
  setBackdropLoadingScreenOn,
  setAlertState,
  setSyncNeeded,
  editMode,
  setSchedulesTabActive,
  setClickedTab,
  siteInfoChangesMade,
  setSiteInfoChangesMade,
  scheduleChangesMade,
  setScheduleChangesMade,
  setDeleteScheduleDialogOpen,
  setSiteSchedules,
  setArmButtonDisabled,
  recordingServerChangesMade,
  recordingServerData,
  recordingServerPatchObject,
  setRecordingServerData,
  setRecordingServerPatchObject,
  setRecordingServerChangesMade,
  cameraHealthChangesMade,
  healthCheckData,
  setHealthCheckData,
  cameraHealthPatchObject,
  setCameraHealthPatchObject,
  setCameraHealthChangesMade,
  setArmedStatus,
  webhooksChangesMade,
  webhooksInfo,
  setWebhooksInfo,
  webhooksPatchObject,
  setWebhooksPatchObject,
  setWebhooksChangesMade,
  setDeactivateSiteInsteadDialogOpen,
  setActivateSiteWithScheduleDialogOpen,
}: {
  patchObject: PatchObject;
  setEditMode: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<SiteInfo>;
  setPatchObject: Dispatcher<PatchObject>;
  siteInfo: SiteInfo;
  clickedTab: string;
  setActiveTab: Dispatcher<JSX.Element>;
  vmsVariables: (string | string[] | undefined)[][];
  allSiteSchedules: SiteSchedule[];
  siteSchedules: SiteSchedule[];
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setSyncNeeded: Dispatcher<boolean>;
  editMode: boolean;
  setSchedulesTabActive: Dispatcher<boolean>;
  setClickedTab: Dispatcher<string>;
  siteInfoChangesMade: boolean;
  setSiteInfoChangesMade: Dispatcher<boolean>;
  scheduleChangesMade: boolean;
  setScheduleChangesMade: Dispatcher<boolean>;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<SiteSchedule[]>;
  setArmButtonDisabled: any;
  recordingServerChangesMade: any;
  recordingServerData: any;
  recordingServerPatchObject: any;
  setRecordingServerData: any;
  setRecordingServerPatchObject: any;
  setRecordingServerChangesMade: any;
  cameraHealthChangesMade: any;
  healthCheckData: any;
  setHealthCheckData: any;
  cameraHealthPatchObject: any;
  setCameraHealthPatchObject: any;
  setCameraHealthChangesMade: any;
  setArmedStatus: any;
  webhooksChangesMade: boolean;
  webhooksInfo: any;
  setWebhooksInfo: any;
  webhooksPatchObject: any;
  setWebhooksPatchObject: any;
  setWebhooksChangesMade: any;
  setDeactivateSiteInsteadDialogOpen: any;
  setActivateSiteWithScheduleDialogOpen: any;
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <OrangeTextButton
        onClick={() =>
          handleSaveChangesButtonClick(
            patchObject,
            setEditMode,
            setSiteInfo,
            setPatchObject,
            siteInfo,
            clickedTab,
            setActiveTab,
            vmsVariables,
            allSiteSchedules,
            siteSchedules,
            setAllSiteSchedules,
            setBackdropLoadingScreenOn,
            setAlertState,
            setSyncNeeded,
            editMode,
            setSchedulesTabActive,
            setClickedTab,
            siteInfoChangesMade,
            setSiteInfoChangesMade,
            scheduleChangesMade,
            setScheduleChangesMade,
            setDeleteScheduleDialogOpen,
            navigate,
            setSiteSchedules,
            setArmButtonDisabled,
            recordingServerChangesMade,
            recordingServerData,
            recordingServerPatchObject,
            setRecordingServerData,
            setRecordingServerPatchObject,
            setRecordingServerChangesMade,
            cameraHealthChangesMade,
            healthCheckData,
            setHealthCheckData,
            cameraHealthPatchObject,
            setCameraHealthPatchObject,
            setCameraHealthChangesMade,
            setArmedStatus,
            webhooksChangesMade,
            webhooksInfo,
            setWebhooksInfo,
            webhooksPatchObject,
            setWebhooksPatchObject,
            setWebhooksChangesMade,
            setDeactivateSiteInsteadDialogOpen,
            setActivateSiteWithScheduleDialogOpen
          )
        }
        text="SAVE CHANGES"
        disabled={false}
      />
    </div>
  );
};
