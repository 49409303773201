import { RebootDialog } from "./RebootDialog";
import { ActivateSiteDialog } from "./ActivateSiteDialog";
import { DeactivateSiteDialog } from "./DeactivateSiteDialog";
import { DeactivateInquiryDialog } from "./DeactivateInquiryDialog";
import { DisarmSiteDialog } from "./DisarmSiteDialog";
import { ArmSiteDialog } from "./ArmSiteDialog";
import { DeleteSiteCheckDialog } from "./DeleteSiteCheckDialog";
import { NoAutoAddCamerasDialog } from "./NoAutoAddCamerasDialog";
import {
  AlertState,
  SiteSchedule,
  SiteInfo,
  PatchObject,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import AddCameraHealthDialog from "./AddCameraHealthDialog";
import { TurnOffCameraHealthDialog } from "./TurnOffCameraHealthDialog";
import { DeactivateSiteInsteadDialog } from "./DeactivateSiteInsteadDialog";
import { ActivateSiteWithScheduleDialog } from "./ActivateSiteWithScheduleDialog";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const SiteAboutDialogs = ({
  deleteScheduleDialogOpen,
  setDeleteScheduleDialogOpen,
  setBackdropLoadingScreenOn,
  setAllSiteSchedules,
  setSiteInfo,
  editMode,
  setActiveTab,
  allSiteSchedules,
  siteInfo,
  setScheduleChangesMade,
  rebootDialogOpen,
  setRebootDialogOpen,
  setAlertState,
  activateSiteDialogOpen,
  setActivateSiteDialogOpen,
  setPatchObject,
  patchObject,
  deactivateSiteDialogOpen,
  setDeactivateSiteDialogOpen,
  deativateInquiryDialogOpen,
  setDeativateInquiryDialogOpen,
  disarmDialogOpen,
  setDisarmDialogOpen,
  armDialogOpen,
  setArmDialogOpen,
  deleteSiteDialogOpen,
  setDeleteSiteDialogOpen,
  navigate,
  setSyncNeeded,
  noAutoAddCamerasDialogOpen,
  setNoAutoAddCamerasDialogOpen,
  setArmedStatus,
  setArmButtonDisabled,
  setEditMode,
  clickedTab,
  vmsVariables,
  handleSaveChangesButtonClick,
  siteSchedules,
  setSchedulesTabActive,
  setClickedTab,
  siteInfoChangesMade,
  setSiteInfoChangesMade,
  scheduleChangesMade,
  setSiteSchedules,
  addCameraHealthDialogOpen,
  setAddCameraHealthDialogOpen,
  healthCheckData,
  setCameraHealthPatchObject,
  setCameraHealthChangesMade,
  setHealthCheckData,
  turnOffHealthDialogOpen,
  setTurnOffHealthDialogOpen,
  permissions,
  deactivateSiteInsteadDialogOpen,
  setDeactivateSiteInsteadDialogOpen,
  activateSiteWithScheduleDialogOpen,
  setActivateSiteWithScheduleDialogOpen,
}: {
  deleteScheduleDialogOpen: boolean;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>;
  setSiteInfo: Dispatcher<SiteInfo>;
  editMode: boolean;
  setActiveTab: Dispatcher<JSX.Element>;
  allSiteSchedules: SiteSchedule[];
  siteInfo: SiteInfo;
  setScheduleChangesMade: Dispatcher<boolean>;
  rebootDialogOpen: boolean;
  setRebootDialogOpen: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  activateSiteDialogOpen: boolean;
  setActivateSiteDialogOpen: Dispatcher<boolean>;
  setPatchObject: Dispatcher<PatchObject>;
  patchObject: PatchObject;
  deactivateSiteDialogOpen: boolean;
  setDeactivateSiteDialogOpen: Dispatcher<boolean>;
  deativateInquiryDialogOpen: boolean;
  setDeativateInquiryDialogOpen: Dispatcher<boolean>;
  disarmDialogOpen: boolean;
  setDisarmDialogOpen: Dispatcher<boolean>;
  armDialogOpen: boolean;
  setArmDialogOpen: Dispatcher<boolean>;
  deleteSiteDialogOpen: boolean;
  setDeleteSiteDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
  setSyncNeeded: Dispatcher<boolean>;
  noAutoAddCamerasDialogOpen: boolean;
  setNoAutoAddCamerasDialogOpen: Dispatcher<boolean>;
  setArmedStatus: Dispatcher<boolean>;
  setArmButtonDisabled: any;
  setEditMode: any;
  clickedTab: any;
  vmsVariables: any;
  handleSaveChangesButtonClick: any;
  siteSchedules: any;
  setSchedulesTabActive: any;
  setClickedTab: any;
  siteInfoChangesMade: any;
  setSiteInfoChangesMade: any;
  scheduleChangesMade: any;
  setSiteSchedules: any;
  addCameraHealthDialogOpen: boolean;
  setAddCameraHealthDialogOpen: Dispatcher<boolean>;
  healthCheckData: any;
  setCameraHealthPatchObject: any;
  setCameraHealthChangesMade: any;
  setHealthCheckData: any;
  turnOffHealthDialogOpen: boolean;
  setTurnOffHealthDialogOpen: Dispatcher<boolean>;
  permissions: any;
  deactivateSiteInsteadDialogOpen: boolean;
  setDeactivateSiteInsteadDialogOpen: Dispatcher<boolean>;
  activateSiteWithScheduleDialogOpen: boolean;
  setActivateSiteWithScheduleDialogOpen: Dispatcher<boolean>;
}) => {
  return (
    <div>
      {rebootDialogOpen && (
        <RebootDialog
          rebootDialogOpen={rebootDialogOpen}
          setRebootDialogOpen={setRebootDialogOpen}
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setSyncNeeded={setSyncNeeded}
          setArmedStatus={setArmedStatus}
          setArmButtonDisabled={setArmButtonDisabled}
        />
      )}
      {activateSiteDialogOpen && (
        <ActivateSiteDialog
          activateSiteDialogOpen={activateSiteDialogOpen}
          setActivateSiteDialogOpen={setActivateSiteDialogOpen}
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setPatchObject={setPatchObject}
          patchObject={patchObject}
          siteInfo={siteInfo}
        />
      )}
      {deactivateSiteDialogOpen && (
        <DeactivateSiteDialog
          deactivateSiteDialogOpen={deactivateSiteDialogOpen}
          setDeactivateSiteDialogOpen={setDeactivateSiteDialogOpen}
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setPatchObject={setPatchObject}
          patchObject={patchObject}
          siteInfo={siteInfo}
          setArmButtonDisabled={setArmButtonDisabled}
          setArmedStatus={setArmedStatus}
        />
      )}
      {deativateInquiryDialogOpen && (
        <DeactivateInquiryDialog
          deativateInquiryDialogOpen={deativateInquiryDialogOpen}
          setDeativateInquiryDialogOpen={setDeativateInquiryDialogOpen}
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setPatchObject={setPatchObject}
          patchObject={patchObject}
          setArmButtonDisabled={setArmButtonDisabled}
          setArmedStatus={setArmedStatus}
          siteInfo={siteInfo}
        />
      )}
      {disarmDialogOpen && (
        <DisarmSiteDialog
          disarmDialogOpen={disarmDialogOpen}
          setDisarmDialogOpen={setDisarmDialogOpen}
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setArmedStatus={setArmedStatus}
          setArmButtonDisabled={setArmButtonDisabled}
        />
      )}
      {armDialogOpen && (
        <ArmSiteDialog
          armDialogOpen={armDialogOpen}
          setArmDialogOpen={setArmDialogOpen}
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setSyncNeeded={setSyncNeeded}
          setArmedStatus={setArmedStatus}
          setArmButtonDisabled={setArmButtonDisabled}
        />
      )}
      {deleteSiteDialogOpen && (
        <DeleteSiteCheckDialog
          deleteSiteDialogOpen={deleteSiteDialogOpen}
          setDeleteSiteDialogOpen={setDeleteSiteDialogOpen}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setSyncNeeded={setSyncNeeded}
        />
      )}
      {noAutoAddCamerasDialogOpen && (
        <NoAutoAddCamerasDialog
          noAutoAddCamerasDialogOpen={noAutoAddCamerasDialogOpen}
          setNoAutoAddCamerasDialogOpen={setNoAutoAddCamerasDialogOpen}
        />
      )}
      {addCameraHealthDialogOpen && (
        <AddCameraHealthDialog
          addCameraHealthDialogOpen={addCameraHealthDialogOpen}
          setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
          setSiteInfo={setSiteInfo}
          setActiveTab={setActiveTab}
          healthCheckData={healthCheckData}
          setCameraHealthPatchObject={setCameraHealthPatchObject}
          setCameraHealthChangesMade={setCameraHealthChangesMade}
          siteInfo={siteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setHealthCheckData={setHealthCheckData}
          setEditMode={setEditMode}
          setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
          permissions={permissions}
          navigate={navigate}
        />
      )}
      {turnOffHealthDialogOpen && (
        <TurnOffCameraHealthDialog
          turnOffHealthDialogOpen={turnOffHealthDialogOpen}
          healthCheckData={healthCheckData}
          cameraHealthPatchObject={healthCheckData}
          setCameraHealthPatchObject={setCameraHealthPatchObject}
          setCameraHealthChangesMade={setCameraHealthChangesMade}
          setActiveTab={setActiveTab}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          siteInfo={siteInfo}
          setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
          setSiteInfo={setSiteInfo}
          setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
          setEditMode={setEditMode}
          navigate={navigate}
        />
      )}
      {deactivateSiteInsteadDialogOpen && (
        <DeactivateSiteInsteadDialog
          deactivateSiteInsteadDialogOpen={deactivateSiteInsteadDialogOpen}
          setDeactivateSiteInsteadDialogOpen={
            setDeactivateSiteInsteadDialogOpen
          }
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setPatchObject={setPatchObject}
          patchObject={patchObject}
          setArmButtonDisabled={setArmButtonDisabled}
          setArmedStatus={setArmedStatus}
          siteInfo={siteInfo}
          setScheduleChangesMade={setScheduleChangesMade}
          setSiteSchedules={setSiteSchedules}
          setAllSiteSchedules={setAllSiteSchedules}
          setEditMode={setEditMode}
          clickedTab={clickedTab}
          setActiveTab={setActiveTab}
          vmsVariables={vmsVariables}
          setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          allSiteSchedules={allSiteSchedules}
        />
      )}
      {activateSiteWithScheduleDialogOpen && (
        <ActivateSiteWithScheduleDialog
          activateSiteWithScheduleDialogOpen={
            activateSiteWithScheduleDialogOpen
          }
          setActivateSiteWithScheduleDialogOpen={
            setActivateSiteWithScheduleDialogOpen
          }
          setSiteInfo={setSiteInfo}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAlertState={setAlertState}
          setPatchObject={setPatchObject}
          patchObject={patchObject}
          siteInfo={siteInfo}
        />
      )}
    </div>
  );
};
