import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { FormHelperText, TextField } from "@mui/material";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../Onboarding/OnboardingProgressDiv";
import ErrorBoundary from "../../../ErrorBoundary";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const SentinelSetupScreen = ({
  setVisibleScreen,
}: {
  setVisibleScreen: any;
}) => {
  const [postObject, setPostObject] = React.useState({
    sentinel_server: "",
    sentinel_site_id: "",
    sentinel_device_address: "",
    draw_ignore_zones: true,
  });

  return (
    <ErrorBoundary>
      <div>
        <p
          className={
            "font-mulish absolute top-[90px] w-[300px] font-bold text-[#283E6D] left-[25px] text-[16px]"
          }
        >
          {`Sentinel Alert Information`}
        </p>
        <div className="pt-[140px] pl-[30px]">
          <TextField
            value={postObject.sentinel_server}
            id="sentinel_server"
            variant="standard"
            label="Sentinel Server"
            onChange={(event) => {
              setPostObject((previousState: any) => {
                return {
                  ...previousState,
                  sentinel_server: event.target.value,
                };
              });
            }}
            sx={{ width: "400px" }}
            helperText="What is the url for your Sentinel server."
            type="string"
          />
          <br></br>
          <br></br>
          <TextField
            value={postObject.sentinel_site_id}
            id="sentinel_site_id"
            variant="standard"
            label="Sentinel site id"
            onChange={(event) => {
              setPostObject((previousState: any) => {
                return {
                  ...previousState,
                  sentinel_site_id: event.target.value,
                };
              });
            }}
            sx={{ width: "400px" }}
            type="string"
          />
          <br></br>
          <br></br>
          <TextField
            value={postObject.sentinel_device_address}
            id="sentinel_device_address"
            variant="standard"
            label="Sentinel device address"
            onChange={(event) => {
              setPostObject((previousState: any) => {
                return {
                  ...previousState,
                  sentinel_device_address: event.target.value,
                };
              });
            }}
            sx={{ width: "400px" }}
            type="string"
          />
          <br></br>
          <br></br>
          <div>
            <FormControlLabel
              sx={{
                fontFamily: "Mulish",
                color: "#001943",
              }}
              label="Draw Ignore Zones"
              control={
                <Checkbox
                  color="primary"
                  checked={postObject.draw_ignore_zones}
                  onChange={(event, checked) => {
                    setPostObject((previousState: any) => {
                      return { ...previousState, draw_ignore_zones: checked };
                    });
                  }}
                />
              }
            />
            <FormHelperText>
              If checked, draws ignore zones in the alert images.
            </FormHelperText>
          </div>
        </div>
        <div className="pt-10 pl-[420px]">
          <div style={{ display: "flex" }}>
            &nbsp;&nbsp;&nbsp;
            <OrangeTextButton
              onClick={() => {
                localStorage.setItem(
                  "sentinelAlertPostObject",
                  JSON.stringify(postObject)
                );
                setVisibleScreen(
                  <OnboardingProgressDiv
                    percentage={
                      validIntegrationTypes.includes(
                        localStorage.selectedIntegrationType
                      )
                        ? 25
                        : 33
                    }
                    step={1}
                    setVisibleScreen={setVisibleScreen}
                  />
                );
              }}
              text="Submit"
              disabled={
                false
                // !postObject.sentinel_device_address ||
                // !postObject.sentinel_server ||
                // !postObject.sentinel_site_id
              }
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
