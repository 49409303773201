import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

export const TableHeadComponent = ({ titlesArray }: { titlesArray: any }) => {
  const siteInfo = JSON.parse(localStorage.siteInfo);
  return (
    <TableHead>
      <TableRow>
        {titlesArray.map((title: any) => {
          return (
            <>
              <TableCell
                key={title}
                align="left"
                sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}
              >
                {title}
                &nbsp;
                {title === "Arm Time" || title === "Disarm Time" ? (
                  <Tooltip
                    title={siteInfo.timezone}
                    placement="top"
                    sx={{ fontSize: "15px", fontFamily: "mulish" }}
                  >
                    <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                  </Tooltip>
                ) : (
                  ""
                )}
                {title === "Bold Alarm Type" ? (
                  <Tooltip
                    title={
                      "Alarm type for alert, default is BA (Burglary alarm)."
                    }
                    placement="top"
                    sx={{ fontSize: "15px", fontFamily: "mulish" }}
                  >
                    <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableCell>
            </>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export const TooltipTableHeadComponent = ({
  titlesArray,
  toolTipText,
}: {
  titlesArray: any;
  toolTipText: any;
}) => {
  return (
    <TableHead>
      <TableRow>
        {titlesArray.map((array: any) => {
          return (
            <>
              <TableCell
                key={array[1]}
                align="left"
                sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}
              >
                {array[0]}
                &nbsp;
                {toolTipText[array[1]] && (
                  <Tooltip
                    title={toolTipText[array[1]]}
                    placement="top"
                    sx={{ fontSize: "15px", fontFamily: "mulish" }}
                  >
                    <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                  </Tooltip>
                )}
              </TableCell>
            </>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
