import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AlertsTableHealthMonitoring } from "../AlertsTableHealthMonitoring";
import { AxiosError, AxiosResponse } from "axios";
import { yyyymmddGenerator } from "../../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { handleUpdateFilteredDataCounts } from "./handleUpdateFilteredDataCounts";

export const handleBulkResolve = (
  alertsArray: any,
  setAlertsData: any,
  statusString: string,
  alertsData: any,
  setFectchingAllData: Dispatcher<boolean>,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: string,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setSortedData: any,
  singleCellActivated: boolean,
  activeName: any,
  setUpdatingStatus: Dispatcher<boolean>,
  filters: any,
  alertFilter: string,
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  setFilteredDataLengths: any,
  sceneChangeValueString: string,
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  setUpdatingStatus(true);

  const retryFunction = () => {
    handleBulkResolve(
      alertsArray,
      setAlertsData,
      statusString,
      alertsData,
      setFectchingAllData,
      setUnhealthyCamsData,
      unhealthyCamsData,
      clickedTab,
      setActiveTable,
      setAlertStatusDialogOpen,
      setSelectedRow,
      setSelectedRowIndex,
      setSortedData,
      singleCellActivated,
      activeName,
      setUpdatingStatus,
      filters,
      alertFilter,
      setSceneChangeOutcomesDialogOpen,
      setBackgroundFrame,
      setDetectedFrame,
      setSelectedRows,
      selectedRows,
      setErrorsOverviewData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      setBandwidthData,
      setLoadingBandwidthData,
      setShowSiteStats,
      cameraDropDownValue,
      setFilteredDataLengths,
      sceneChangeValueString,
      setBulkSceneChangeOutcomesDialogOpen,
      navigate
    );
  };

  const AxiosUI = createApi(``);

  let searchQueryString = `?start_date=${yyyymmddGenerator(
    filters.start_date
  )}&end_date=${yyyymmddGenerator(filters.end_date)}`;

  if (filters.parent_group) {
    searchQueryString += `&group=${filters.parent_group}`;
  }

  if (filters.site) {
    searchQueryString += `&site=${filters.site}`;
  }

  if (filters.camera) {
    searchQueryString += `&camera=${filters.camera}`;
  }

  const successfulAlertsArray: any[] = [];

  const failedAlertsArray: any[] = [];

  let anyAlertsUpdateFail = false;

  let timesRun = 0;

  const updateData = (submittedAlertData: any) => {
    if (anyAlertsUpdateFail) {
      alert("Some alerts were not updated, please try updating them again");
    }
    setActiveTable(<></>);
    setAlertsData(submittedAlertData);
    setFectchingAllData(false);
    setTimeout(() => {
      setActiveTable(
        <AlertsTableHealthMonitoring
          alertsData={submittedAlertData}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={clickedTab}
          setActiveTable={setActiveTable}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          sceneChangeValueString={sceneChangeValueString}
          setBulkSceneChangeOutcomesDialogOpen={
            setBulkSceneChangeOutcomesDialogOpen
          }
          navigate={navigate}
        />
      );
      handleUpdateFilteredDataCounts(
        singleCellActivated,
        submittedAlertData,
        activeName,
        setFilteredDataLengths,
        alertFilter
      );
    }, 1);
  };

  const finalAlertFunctionality = (submittedAlertData: any) => {
    if (timesRun === 0) {
      AxiosUI.get(
        `healthcheck_result/unhealthy_cameras/${searchQueryString}`
      ).then((response: AxiosResponse) => {
        setUnhealthyCamsData(response.data);

        successfulAlertsArray.forEach((chmAlert: any, indexAlerts: number) => {
          const foundAlertAllIndex = submittedAlertData.all.findIndex(
            (submittedAlert: any) =>
              submittedAlert.start_timestamp === chmAlert.start_timestamp &&
              submittedAlert.camera_name === chmAlert.camera_name
          );
          const foundAlertErrorsIndex = submittedAlertData.errors.findIndex(
            (submittedAlert: any) =>
              submittedAlert.start_timestamp === chmAlert.start_timestamp &&
              submittedAlert.camera_name === chmAlert.camera_name
          );
          const foundAlertWarningsIndex = submittedAlertData.warnings.findIndex(
            (submittedAlert: any) =>
              submittedAlert.start_timestamp === chmAlert.start_timestamp &&
              submittedAlert.camera_name === chmAlert.camera_name
          );
          const foundAlertPendingIndex = submittedAlertData.pending.findIndex(
            (submittedAlert: any) =>
              submittedAlert.start_timestamp === chmAlert.start_timestamp &&
              submittedAlert.camera_name === chmAlert.camera_name
          );
          if (chmAlert.status === "resolved") {
            submittedAlertData.all.splice(foundAlertAllIndex, 1);
            submittedAlertData.resolved.push(chmAlert);
            if (foundAlertErrorsIndex >= 0) {
              submittedAlertData.errors.splice(foundAlertErrorsIndex, 1);
            }
            if (foundAlertWarningsIndex >= 0) {
              submittedAlertData.warnings.splice(foundAlertWarningsIndex, 1);
            }
            if (foundAlertPendingIndex >= 0) {
              submittedAlertData.pending.splice(foundAlertPendingIndex, 1);
            }
          }
          if (chmAlert.status === "pending") {
            submittedAlertData[foundAlertAllIndex] = chmAlert;
            submittedAlertData.pending.push(chmAlert);
            if (foundAlertErrorsIndex >= 0) {
              submittedAlertData.errors[foundAlertErrorsIndex] = chmAlert;
            }
            if (foundAlertWarningsIndex >= 0) {
              submittedAlertData.warnings[foundAlertWarningsIndex] = alert;
            }
          }
          if (indexAlerts === successfulAlertsArray.length - 1) {
            if (!failedAlertsArray[0]) {
              updateData(submittedAlertData);
              setUpdatingStatus(false);
            } else {
              failedAlertsArray.forEach(
                (chmFailAlert: any, indexFailedAlerts: number) => {
                  const foundAlertAllFailIndex =
                    submittedAlertData.all.findIndex(
                      (submittedAlert: any) =>
                        submittedAlert.start_timestamp ===
                          chmFailAlert.start_timestamp &&
                        submittedAlert.camera_name === chmFailAlert.camera_name
                    );
                  const foundAlertErrorsFailIndex =
                    submittedAlertData.errors.findIndex(
                      (submittedAlert: any) =>
                        submittedAlert.start_timestamp ===
                          chmFailAlert.start_timestamp &&
                        submittedAlert.camera_name === chmFailAlert.camera_name
                    );
                  const foundAlertWarningsFailIndex =
                    submittedAlertData.warnings.findIndex(
                      (submittedAlert: any) =>
                        submittedAlert.start_timestamp ===
                          chmFailAlert.start_timestamp &&
                        submittedAlert.camera_name === chmFailAlert.camera_name
                    );
                  const foundAlertPendingFailIndex =
                    submittedAlertData.pending.findIndex(
                      (submittedAlert: any) =>
                        submittedAlert.start_timestamp ===
                          chmFailAlert.start_timestamp &&
                        submittedAlert.camera_name === chmFailAlert.camera_name
                    );
                  if (chmFailAlert.status === "resolved") {
                    if (
                      foundAlertPendingFailIndex >= 0 &&
                      !(foundAlertErrorsFailIndex >= 0) &&
                      !(foundAlertWarningsFailIndex >= 0)
                    ) {
                      submittedAlertData.all[foundAlertAllFailIndex].status =
                        "pending";
                      submittedAlertData.pending[foundAlertPendingFailIndex] =
                        "pending";
                    }
                    if (
                      foundAlertPendingFailIndex >= 0 &&
                      foundAlertErrorsFailIndex >= 0
                    ) {
                      submittedAlertData.all[foundAlertAllFailIndex].status =
                        "pending";
                      submittedAlertData.pending[foundAlertPendingFailIndex] =
                        "pending";
                      submittedAlertData.errors[foundAlertErrorsFailIndex] =
                        "pending";
                    }
                    if (
                      foundAlertPendingFailIndex >= 0 &&
                      foundAlertWarningsFailIndex >= 0
                    ) {
                      submittedAlertData.all[foundAlertAllFailIndex].status =
                        "pending";
                      submittedAlertData.pending[foundAlertPendingFailIndex] =
                        "pending";
                      submittedAlertData.warnings[foundAlertWarningsFailIndex] =
                        "pending";
                    }
                    if (
                      !(foundAlertPendingFailIndex >= 0) &&
                      !(foundAlertErrorsFailIndex >= 0) &&
                      !(foundAlertWarningsFailIndex >= 0)
                    ) {
                      submittedAlertData.all[foundAlertAllFailIndex].status =
                        "ongoing";
                    }
                    if (
                      !(foundAlertPendingFailIndex >= 0) &&
                      foundAlertErrorsFailIndex >= 0
                    ) {
                      submittedAlertData.all[foundAlertAllFailIndex].status =
                        "ongoing";
                      submittedAlertData.errors[foundAlertErrorsFailIndex] =
                        "ongoing";
                    }
                    if (
                      !(foundAlertPendingFailIndex >= 0) &&
                      foundAlertWarningsFailIndex >= 0
                    ) {
                      submittedAlertData.all[foundAlertAllFailIndex].status =
                        "ongoing";
                      submittedAlertData.warnings[foundAlertWarningsFailIndex] =
                        "ongoing";
                    }
                  }
                  if (chmFailAlert.status === "pending") {
                    submittedAlertData.all[foundAlertAllFailIndex].status =
                      "ongoing";

                    if (foundAlertErrorsFailIndex >= 0) {
                      submittedAlertData.errors[foundAlertErrorsFailIndex] =
                        "ongoing";
                    }
                    if (foundAlertWarningsFailIndex >= 0) {
                      submittedAlertData.warnings[foundAlertWarningsFailIndex] =
                        "ongoing";
                    }
                  }
                  if (indexFailedAlerts === failedAlertsArray.length - 1) {
                    updateData(submittedAlertData);
                    setUpdatingStatus(false);
                  }
                }
              );
            }
          }
        });
      });
      timesRun++;
    }
  };

  let index = 0;

  const resolveAPICall = (object: any) => {
    const postedAlert = object;
    postedAlert.status = statusString;
    const submittedAlertData: any = alertsData;
    const resolveFunction = () => {
      AxiosUI.post(
        `/healthcheck_result/update_healthcheck/?camera_id=${postedAlert.camera_id}&created_timestamp=${postedAlert.created_timestamp}&healthcheck_type=${postedAlert.error_type}&status=${statusString}`,
        postedAlert
      ).then(
        (response: AxiosResponse) => {
          if (
            (response.data.status === "resolved" &&
              postedAlert.status === "resolved") ||
            (response.data.status === "pending" &&
              postedAlert.status === "pending")
          ) {
            successfulAlertsArray.push(postedAlert);
          }
          if (index !== alertsArray.length - 1) {
            index++;
            resolveAPICall(alertsArray[index]);
          }
          if (index === alertsArray.length - 1) {
            finalAlertFunctionality(submittedAlertData);
          }
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            failedAlertsArray.push(postedAlert);
            if (index !== alertsArray.length - 1) {
              anyAlertsUpdateFail = true;
              index++;
              resolveAPICall(alertsArray[index]);
            }
            if (index === alertsArray.length - 1) {
              finalAlertFunctionality(submittedAlertData);
            }
          });
        }
      );
    };
    if (
      postedAlert.error_type === "scene_change" &&
      statusString === "resolved"
    ) {
      // Add functionality from handleResolveSuddenSceneChange here

      const submittedObject = {
        camera_id: postedAlert.camera_id,
        action: "clear",
        detected_frame_key: postedAlert.detected_frame_key,
        background_frame_key: postedAlert.background_frame_key,
        incident_timestamp: postedAlert.start_timestamp,
      };
      if (sceneChangeValueString === "reset") {
        AxiosUI.post(
          `/healthcheck_result/update_sudden_scene/?camera_id=${postedAlert.camera_id}&action=clear&detected_frame_key=${postedAlert.detected_frame_key}&background_frame_key=${postedAlert.background_frame_key}&incident_timestamp=${postedAlert.start_timestamp}`,
          submittedObject
        ).then(
          () => {
            resolveFunction();
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              resolveFunction();
            });
          }
        );
      }
      if (sceneChangeValueString === "add") {
        submittedObject.action = "update";
        AxiosUI.post(
          `/healthcheck_result/update_sudden_scene/?camera_id=${postedAlert.camera_id}&action=clear&detected_frame_key=${postedAlert.detected_frame_key}&background_frame_key=${postedAlert.background_frame_key}&incident_timestamp=${postedAlert.start_timestamp}`,
          submittedObject
        ).then(
          () => {
            resolveFunction();
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              resolveFunction();
            });
          }
        );
      }
    } else {
      resolveFunction();
    }
  };
  resolveAPICall(alertsArray[index]);
};
