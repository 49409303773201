import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleDuplicateCamera = async (
  submittedObject: any,
  navigate: any,
  setSiteCameras: any,
  setAlertState: any,
  setSelected: any,
  setSyncNeeded: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    handleDuplicateCamera(
      submittedObject,
      navigate,
      setSiteCameras,
      setAlertState,
      setSelected,
      setSyncNeeded
    );
  };
  let cameraDuplicateFail = false;

  await AxiosUI.post(`camera/action/duplicate_cameras/`, submittedObject).then(
    () => {
      cameraDuplicateFail = false;
    },
    (reason: AxiosError) => {
      cameraDuplicateFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error duplicating camera, please try again.");
      });
    }
  );
  !cameraDuplicateFail &&
    (await AxiosUI.get(
      `camera/site/?customer__id=${getNumbersAfterAboutSite(location.href)}`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
        setSiteCameras(response.data);
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            changeSuccess: true,
            activateSuccess: false,
            deactivateSuccess: false,
            disarmSuccess: false,
            armSuccess: false,
            syncSuccess: false,
            cameraDeleteSuccess: false,
          };
        });
        setSelected([]);
        setSyncNeeded(true);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
