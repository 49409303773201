import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { TextField } from "@mui/material";
import { Switch } from "@mui/material";

export const SentinelAlertsTabEditMode = ({
  sentinelAlerts,
  cameraGeneralInfo,
  setPatchObject,
  setGeneralInfoChangesMade,
}: {
  sentinelAlerts: any;
  cameraGeneralInfo: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
}) => {
  const createNewPatchStreams = (
    currentIndex: any,
    inputValue: any,
    titleString: string
  ) => {
    const updatedStreams = cameraGeneralInfo.streams;
    updatedStreams.forEach((object: any) => {
      if (titleString === "sentinel_server") {
        if (object.sentinel_alerts[currentIndex] === undefined) {
          object.sentinel_alerts[currentIndex] = {
            sentinel_server: "",
            sentinel_site_id: "",
            sentinel_device_address: "",
            draw_ignore_zones: false,
          };
        } else {
          object.sentinel_alerts[currentIndex].sentinel_server = inputValue;
        }
      }
      if (titleString === "sentinel_site_id") {
        if (object.sentinel_alerts[currentIndex] === undefined) {
          object.sentinel_alerts[currentIndex] = {
            sentinel_server: "",
            sentinel_site_id: "",
            sentinel_device_address: "",
            draw_ignore_zones: false,
          };
        } else {
          object.sentinel_alerts[currentIndex].sentinel_site_id = inputValue;
        }
      }
      if (titleString === "sentinel_device_address") {
        if (object.sentinel_alerts[currentIndex] === undefined) {
          object.sentinel_alerts[currentIndex] = {
            sentinel_server: "",
            sentinel_site_id: "",
            sentinel_device_address: "",
            draw_ignore_zones: false,
          };
        } else {
          object.sentinel_alerts[currentIndex].sentinel_device_address =
            inputValue;
        }
      }
      if (titleString === "draw_ignore_zones") {
        if (object.sentinel_alerts[currentIndex] === undefined) {
          object.sentinel_alerts[currentIndex] = {
            sentinel_server: "",
            sentinel_site_id: "",
            sentinel_device_address: "",
            draw_ignore_zones: false,
          };
        } else {
          object.sentinel_alerts[currentIndex].draw_ignore_zones = inputValue;
        }
      }
    });
    return updatedStreams;
  };

  const updatePatchObject = (patchStreams: any) => {
    setPatchObject((previousState: any) => {
      return { ...previousState, streams: patchStreams };
    });
  };

  React.useEffect(() => {
    setPatchObject((previousState: any) => {
      return { ...previousState, streams: cameraGeneralInfo.streams };
    });
  }, []);

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      {sentinelAlerts.map((object: any, index: any) => {
        const currentIndex = index;
        return (
          <div key={object.id} className="h-[218px]">
            <div className="min-w-[1150px] h-[5vh] bg-white"></div>
            <div className="min-w-[1150px] h-[40px] bg-white">
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: "Bold",
                  color: "black",
                  textIndent: "1vw",
                }}
              >
                Sentinel Alert {index + 1}
              </Typography>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 1000, fontFamily: "Mulish" }}
                  aria-label="simple table"
                >
                  <TableHeadComponent
                    titlesArray={[
                      "Sentinel Server",
                      "Sentinel Site Id",
                      "Sentinel Device Address",
                      "Draw Ignore Zones",
                    ]}
                  />
                  <TableBody>
                    <TableRow key={object.id}>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.sentinel_server}>
                          <TextField
                            id={object.sentinel_server}
                            defaultValue={object.sentinel_server}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "sentinel_server"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.sentinel_site_id}>
                          <TextField
                            id={object.sentinel_site_id}
                            defaultValue={object.sentinel_site_id}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "sentinel_site_id"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.sentinel_device_address}>
                          <TextField
                            id={object.sentinel_device_address}
                            defaultValue={object.sentinel_device_address}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "sentinel_device_address"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.draw_ignore_zones}>
                          <Switch
                            id={object.draw_ignore_zones}
                            defaultChecked={object.draw_ignore_zones}
                            color="warning"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  checked,
                                  "draw_ignore_zones"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        );
      })}
    </div>
  );
};
