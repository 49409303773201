import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { OrangeTextButton } from "../../common/OrangeTextButton";

export const USMonitoringAlertsTab = ({
  uSMonitoringAlerts,
  setAddUSMonitoringAlertDialogOpen,
}: {
  uSMonitoringAlerts: any;
  setAddUSMonitoringAlertDialogOpen: any;
}) => {
  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      <div className="min-w-[1150px] h-[50px] bg-white">
        <div className="absolute right-[70px] top-[10px]">
          {permissions.edit_cameras && (
            <OrangeTextButton
              onClick={() => {
                setAddUSMonitoringAlertDialogOpen(true);
              }}
              text="+ Add US Monitoring Alert"
              disabled={false}
            />
          )}
        </div>
      </div>
      {uSMonitoringAlerts[0] &&
        uSMonitoringAlerts.map((object: any, index: any) => {
          return (
            <div key={object.id} className="h-[160px]">
              <div className="min-w-[1150px] h-[50px] pt-[10px] bg-white">
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "black",
                    textIndent: "1vw",
                  }}
                >
                  US Monitoring Alert {index + 1}
                </Typography>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 1000, fontFamily: "Mulish" }}>
                    <TableHeadComponent
                      titlesArray={["US Monitoring CID", "US Monitoring Zone"]}
                    />
                    <TableBody>
                      <TableRow key={object.id}>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.us_monitoring_cid
                            ? object.us_monitoring_cid
                            : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.us_monitoring_zone
                            ? object.us_monitoring_zone
                            : "--"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          );
        })}
    </div>
  );
};
