import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleSubmitNewUSMonitoringAlert } from "./EditCameraUtils/handleSubmitNewUSMonitoringAlert";

export const AddUSMonitoringAlertDialog = ({
  setAddUSMonitoringAlertDialogOpen,
  addUSMonitoringAlertDialogOpen,
  uSMonitoringAlerts,
  setBackdropLoadingScreenOn,
  setCameraGeneralInfo,
  setCameraGeneralInfoBackup,
  setGeneralInfoChangesMade,
  setPatchObject,
  setUSMonitoringAlerts,
  setActiveTab,
  cameraGeneralInfoBackup,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  cameraGeneralInfo,
}: {
  setAddUSMonitoringAlertDialogOpen: Dispatcher<boolean>;
  addUSMonitoringAlertDialogOpen: boolean;
  uSMonitoringAlerts: any;
  setBackdropLoadingScreenOn: any;
  setCameraGeneralInfo: any;
  setCameraGeneralInfoBackup: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setUSMonitoringAlerts: any;
  setActiveTab: any;
  cameraGeneralInfoBackup: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  cameraGeneralInfo: any;
}) => {
  const navigate = useNavigate();

  const [postObject, setPostObject] = React.useState({
    id: -1,
    us_monitoring_cid: "",
    us_monitoring_zone: "",
  });

  React.useEffect(() => {
    if (uSMonitoringAlerts[0]) {
      setPostObject({
        id: -1,
        us_monitoring_cid: uSMonitoringAlerts[0].us_monitoring_cid,
        us_monitoring_zone: uSMonitoringAlerts[0].us_monitoring_zone,
      });
    }
  }, []);

  return (
    <Dialog open={addUSMonitoringAlertDialogOpen}>
      <DialogTitle id="alert-dialog-title">Add US Monitoring Alert</DialogTitle>
      <DialogContent>
        <TextField
          id="us_monitoring_cid"
          variant="standard"
          label="US Monitoring CID"
          defaultValue={
            uSMonitoringAlerts[0] ? uSMonitoringAlerts[0].us_monitoring_cid : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                us_monitoring_cid: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="us_monitoring_zone"
          variant="standard"
          label="US Monitoring Zone"
          defaultValue={
            uSMonitoringAlerts[0]
              ? uSMonitoringAlerts[0].us_monitoring_zone
              : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                us_monitoring_zone: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddUSMonitoringAlertDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setAddUSMonitoringAlertDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleSubmitNewUSMonitoringAlert(
              cameraGeneralInfo,
              uSMonitoringAlerts,
              postObject,
              navigate,
              setBackdropLoadingScreenOn,
              setCameraGeneralInfo,
              setCameraGeneralInfoBackup,
              setGeneralInfoChangesMade,
              setPatchObject,
              setUSMonitoringAlerts,
              setActiveTab,
              setAddUSMonitoringAlertDialogOpen,
              cameraGeneralInfoBackup,
              setIgnoreZonesBreadCrumbs,
              handleSitesBreadcrumbClick,
              handleSingularSiteBreadcrumbClick
            );
          }}
          text="Submit"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
