import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { addSeletedAndDetectionType } from "./addSeletedAndDetectionType";
import { getAlertMP4 } from "./getAlertMP4";
import { AlertData } from "./alertsInterfaces";
import { yyyymmddGenerator } from "../../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { getActiveIgnoreZones } from "./getActiveIgnoreZones";
import { getAlertsRefresh } from "./getAlertsRefresh";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { getParentAccounts } from "../../../utils/getParentAccounts";
import { timeSlicer } from "../timeSlicer";
import { createLabels } from "./createLabels";

export const getAlerts = (
  setAlertData: Dispatcher<AlertData[]>,
  navigate: NavigateFunction,
  setSelectedRow: Dispatcher<any>,
  setPageLoading: Dispatcher<boolean>,
  setSeeMoreURL: Dispatcher<string>,
  setMP4URL: Dispatcher<string>,
  userFilters: any,
  setThumbnailVideos: any,
  setDateOutsideRange: Dispatcher<boolean>,
  setVideoClipLoading: Dispatcher<boolean>,
  setAlertsToDisplay: Dispatcher<boolean>,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  setAlertsIndexOrder: any,
  setRefreshInterval: any,
  setFetchingFilteredData: any,
  setCurrentName: any,
  setPage: any,
  setSelectedButtonsFilled: any,
  setUserFilters: any,
  cameraOptions: any,
  siteOptions: any,
  setRefreshingData: any,
  pageLoadedNumber: any,
  refreshInterval: number,
  setParentAccountsLoading: any,
  setParentAccounts: any,
  setParentIds: any,
  setParentNames: any,
  setRefreshingToken: Dispatcher<boolean>,
  refreshingToken: boolean,
  setAlertLabels: Dispatcher<string[]>,
  setPeriodHours: Dispatcher<number>,
  setFiltersApplied: Dispatcher<boolean>,
  setForwardArrowDisabled: Dispatcher<boolean>,
  setBackArrowDisabled: Dispatcher<boolean>
) => {
  const cameraIds = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentIds = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  const AxiosUI = createMonitoringAPICall(``);
  const retryFunction = () => {
    getAlerts(
      setAlertData,
      navigate,
      setSelectedRow,
      setPageLoading,
      setSeeMoreURL,
      setMP4URL,
      userFilters,
      setThumbnailVideos,
      setDateOutsideRange,
      setVideoClipLoading,
      setAlertsToDisplay,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      setAlertsIndexOrder,
      setRefreshInterval,
      setFetchingFilteredData,
      setCurrentName,
      setPage,
      setSelectedButtonsFilled,
      setUserFilters,
      cameraOptions,
      siteOptions,
      setRefreshingData,
      pageLoadedNumber,
      refreshInterval,
      setParentAccountsLoading,
      setParentAccounts,
      setParentIds,
      setParentNames,
      setRefreshingToken,
      refreshingToken,
      setAlertLabels,
      setPeriodHours,
      setFiltersApplied,
      setForwardArrowDisabled,
      setBackArrowDisabled
    );
  };

  let searchParamsString = ``;

  const startDate = yyyymmddGenerator(userFilters.start_date);
  const endDate = yyyymmddGenerator(userFilters.end_date);

  if (startDate) {
    searchParamsString += `&start=${startDate}`;
  }

  if (endDate) {
    searchParamsString += `&end=${endDate}`;
  }

  if (userFilters.parent_group) {
    if (userFilters.parent_group !== "undefined") {
      searchParamsString += `&group_id=${userFilters.parent_group}`;
    }
  }

  if (userFilters.site) {
    if (userFilters.site !== "undefined") {
      searchParamsString += `&customer_id=${userFilters.site}`;
    }
  }

  if (userFilters.camera) {
    if (userFilters.camera !== "undefined") {
      searchParamsString += `&camera_id=${userFilters.camera}`;
    }
  }

  if (startDate && endDate) {
    if (
      startDate.toString() === endDate.toString() &&
      userFilters.start_time !== null
    ) {
      searchParamsString += `&start_time=${timeSlicer(userFilters.start_time)}`;
    }

    if (
      startDate.toString() === endDate.toString() &&
      userFilters.end_time !== null
    ) {
      searchParamsString += `&end_time=${timeSlicer(userFilters.end_time)}`;
    }
  }

  // let noAlertsOutsideRange = true;
  const getAlertsFunction = (refreshInt: number) => {
    AxiosUI.get(
      `alert/?window_filter=${userFilters.filter_type}${searchParamsString}`
    ).then(
      (response: AxiosResponse) => {
        getParentAccounts(
          setParentAccountsLoading,
          setParentAccounts,
          setParentIds,
          setParentNames,
          navigate
        );
        const data = response.data;
        if (data.parameters) {
          if (data.parameters.period_hours) {
            setPeriodHours(data.parameters.period_hours);
          }
        }
        setAlertData(
          addSeletedAndDetectionType(
            data.records,
            setAlertsIndexOrder
          ) as AlertData[]
        );
        if (data.records[0]) {
          getAlertMP4(
            data.records[0],
            navigate,
            setMP4URL,
            setVideoClipLoading
          );
          setFetchingFilteredData(false);
          setPageLoading(false);
          setFiltersApplied(true);
          setAlertsToDisplay(true);
          setSeeMoreURL(data.records[0].alert_url);
          setSelectedRow(data.records[0]);
          if (data.records.length === 1) {
            setForwardArrowDisabled(true);
          }
          createLabels(data.records, setAlertLabels);
          if (data.records[0].camera_pk) {
            getActiveIgnoreZones(
              data.records[0].camera_pk,
              setSelectedAlertCameraInfo,
              setIgnoreZonesSelectedAlert,
              navigate
            );
          } else {
            setSelectedAlertCameraInfo({});
            setIgnoreZonesSelectedAlert([]);
          }
          localStorage.setItem(
            "selectedRowAlerts",
            JSON.stringify(data.records[0])
          );
        } else {
          setPageLoading(false);
          setFiltersApplied(true);
        }
        if (localStorage.tokenRefreshing === "false") {
          let dataRecordsTimeStamp = "";
          if (data.records[0]) {
            dataRecordsTimeStamp = data.records[0].approx_capture_timestamp;
          }
          setTimeout(() => {
            getAlertsRefresh(
              setAlertData,
              navigate,
              userFilters.parent_group,
              userFilters.site,
              userFilters.camera,
              yyyymmddGenerator(userFilters.start_date),
              yyyymmddGenerator(userFilters.end_date),
              userFilters.start_time,
              userFilters.end_time,
              userFilters.filter_type,
              setSelectedRow,
              setRefreshingData,
              setSelectedAlertCameraInfo,
              setIgnoreZonesSelectedAlert,
              setAlertsIndexOrder,
              refreshInt,
              setRefreshInterval,
              pageLoadedNumber,
              setSeeMoreURL,
              setMP4URL,
              setVideoClipLoading,
              setSelectedButtonsFilled,
              setAlertsToDisplay,
              setCurrentName,
              setPage,
              setDateOutsideRange,
              setFetchingFilteredData,
              setPageLoading,
              setUserFilters,
              userFilters,
              cameraOptions,
              siteOptions,
              dataRecordsTimeStamp,
              setRefreshingToken,
              refreshingToken,
              data.records.length,
              setBackArrowDisabled,
              setForwardArrowDisabled
            );
          }, refreshInt);
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setPageLoading(false);
          setAlertsToDisplay(false);
        });
      }
    );
  };

  if (userFilters.parent_group) {
    AxiosUI.get(`group/${userFilters.parent_group}`).then(
      (response: AxiosResponse) => {
        if (response.data.refresh_interval_seconds) {
          getAlertsFunction(response.data.refresh_interval_seconds * 1000);
        } else {
          getAlertsFunction(refreshInterval);
        }
      },
      (reason: AxiosError) => {
        // eslint-disable-next-line no-console
        console.log("error reason:", reason);
        getAlertsFunction(refreshInterval);
      }
    );
  } else {
    getAlertsFunction(refreshInterval);
  }
};
