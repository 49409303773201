import React from "react";
import { NavBar } from "../common/NavBar";
import IgnoreZoneBuilder from "../common/IgnoreZoneBuilder";

export const TestPage = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: any;
  setNavBarCollapsed: any;
}) => {
  return (
    <div>
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <div className="absolute top-[0px] left-[150px] right-[1px] h-[19px]]">
          <div className="pl-[20px]">
            <IgnoreZoneBuilder
              shapeColor={"#50FF00"}
              height={576}
              width={720}
              imageURL={
                "https://actuate-analytics-ui.s3.amazonaws.com/actuate_nyc_alibi_vigilant/background/ip_camera_02.jpg?AWSAccessKeyId=ASIAVU6HT3QYG7BFI5PM&Signature=sNm7yZfqJiwcdXl6r2RiP%2BvLkJk%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEPj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJIMEYCIQCDAwCoiRYLDq6DwN1%2BHha9a3DMehH5%2BW77tug669gaZgIhAOSNZHjnO3Y2e%2B4NVJkQGzmiommPoZ%2BLWmoy7EqCPo1gKvcDCDEQBRoMMzg4NTc2MzA0MTc2IgxoxKldNoPNgmjpTvQq1AM%2Bs3HjWiJ6o0%2FziSLw%2B7cnik82xUUds21iYOSr7Z8wQGGVZkpfIGx6pM4FdlKOTtmnddT8o0c9liONYoQOdr4PIeVafEdmUCtwa18adMPf%2BIGynD1pEe3DMUla1wFFog1qPRNRfS6MCr%2BNo%2FREDVIvVECR4cJrYAHlGqW7nGjzazuHkaysjQahYcAvfDddqjGlnBVLNv6t%2BpLzSvaSNB9mMi0kZVSz233OKaeyvtywoEM0UEWVTVNZke6eP%2Fy%2Bha2giUTUqcEf1rNjoXcNUqZp%2FOUh1AyNu0CwsrabeJHGPcz5wvA9KBgcLE8sZhsLuWlsk3LAtk7jq2%2FWewSlHv3KDMWJAo95S4tgBUn2LyyltXXynmvn8s2Ew%2BDs5K9YVdQ2lgXhdlpVXoXeL8IdqPS1SD%2B2bRFPs2GoRMtnQvPM6m8B22gMHrLTErKPALGu0FNbxsnoGE3OOdU%2BQyPSJZ3Pif3oOs%2FWmWMAMxoYLQc0BlbISiLxaNZ7Na%2Bj5E3RTk%2FnqwWLqhvgLQNqIMaBKdapxGthpfGR6tXNCNv7BcE%2BRyh2OPx%2FjrwF%2F2aPYm%2FIdW2a2yjbGNzYLy2GpQ6O8N9gUv9aSWwiSSF%2B0ap4nfIJGS5ZiH4w9tWmtwY6pAHY%2FuG1UYuxQFrbpONkvwGlSvQDIIKFsgTA9Jy9CGn92yheoKQj7d72m12puKF0Bqb0vWkfL0xSL3Vo8jdSXJmzKiM2YppPZd4B8hKhEAZnO6yfR2rt%2FnzIiDYyXPQ0h2qqegWYq%2B1SxHmHMkQQFze8xoGrFCVheHk82kJaY07bwe4tUFA9qvgmpDCxIwFhW53Zy8Dg6%2B7SNnEPXMNc5pIeXcq0RA%3D%3D&Expires=1726611391"
              }
              shapesArray={
                [
                  //   [
                  //     [12, 70.5],
                  //     [50, 18.5],
                  //     [99, 61.5],
                  //   ],
                  //   [
                  //     [170, 21],
                  //     [305, 16],
                  //     [243, 63],
                  //   ],
                  //   [
                  //     [347, 49],
                  //     [425, 17],
                  //     [481, 72],
                  //   ],
                ]
              }
            />
          </div>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
