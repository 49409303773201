import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { NavigateFunction } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleResolveSuddenSceneChange } from "./HealthMonitoringUtils/handleResolveSuddenSceneChange";

export const SceneChangeOutcomesDialog = ({
  sceneChangeOutcomesDialogOpen,
  setSceneChangeOutcomesDialogOpen,
  selectedObject,
  setUpdatingStatus,
  setAlertsData,
  alertsData,
  setFectchingAllData,
  setUnhealthyCamsData,
  unhealthyCamsData,
  clickedTab,
  setActiveTable,
  setAlertStatusDialogOpen,
  setSelectedRow,
  setSelectedRowIndex,
  setSortedData,
  singleCellActivated,
  activeName,
  filters,
  alertFilter,
  setBackgroundFrame,
  setDetectedFrame,
  setSelectedRows,
  selectedRows,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  cameraDropDownValue,
  setFilteredDataLengths,
  sceneChangeValueString,
  setSceneChangeValueString,
  setBulkSceneChangeOutcomesDialogOpen,
  navigate,
}: {
  sceneChangeOutcomesDialogOpen: boolean;
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  selectedObject: any;
  setUpdatingStatus: Dispatcher<boolean>;
  setAlertsData: any;
  alertsData: any;
  setFectchingAllData: any;
  setUnhealthyCamsData: any;
  unhealthyCamsData: any;
  clickedTab: any;
  setActiveTable: any;
  setAlertStatusDialogOpen: any;
  setSelectedRow: any;
  setSelectedRowIndex: any;
  setSortedData: any;
  singleCellActivated: any;
  activeName: any;
  filters: any;
  alertFilter: any;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setSelectedRows: any;
  selectedRows: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  cameraDropDownValue: any;
  setFilteredDataLengths: any;
  sceneChangeValueString: string;
  setSceneChangeValueString: Dispatcher<string>;
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSceneChangeValueString((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog open={sceneChangeOutcomesDialogOpen}>
      {" "}
      <DialogContent sx={{ width: 575, padding: 4 }}>
        <DialogContentText
          sx={{
            fontSize: 18,
            color: "black",
            fontFamily: "mulish",
            fontWeight: "bold",
          }}
        >
          Choose the preferred action for your current reference image bank:
        </DialogContentText>
        <br></br>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={handleRadioChange}
        >
          <FormControlLabel
            sx={{ fontFamily: "mulish" }}
            value="reset"
            control={<Radio />}
            label="Reset all reference images to current view."
          />
          <FormControlLabel
            sx={{ fontFamily: "mulish" }}
            value="add"
            control={<Radio />}
            label="Add this detected image to your current reference image library."
          />
          <FormControlLabel
            sx={{ fontFamily: "mulish" }}
            value="proceed"
            control={<Radio />}
            label="Proceed with current reference image library (No changes). "
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <OrangeTextButton
          onClick={() => {
            setSceneChangeOutcomesDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <button
          className="shadow-button bg-actuate-orange border-1	border-actuate-orange rounded font-mulish text-white text-base p-1.5 w-[100px]"
          onClick={() => {
            setSceneChangeOutcomesDialogOpen(false);
            setUpdatingStatus(true);
            handleResolveSuddenSceneChange(
              sceneChangeValueString,
              selectedObject,
              setUpdatingStatus,
              setAlertsData,
              alertsData,
              setFectchingAllData,
              setUnhealthyCamsData,
              unhealthyCamsData,
              clickedTab,
              setActiveTable,
              setAlertStatusDialogOpen,
              setSelectedRow,
              setSelectedRowIndex,
              setSortedData,
              singleCellActivated,
              activeName,
              filters,
              alertFilter,
              setSceneChangeOutcomesDialogOpen,
              setBackgroundFrame,
              setDetectedFrame,
              setSelectedRows,
              selectedRows,
              setErrorsOverviewData,
              setCameraStatsData,
              setLoadingCameraStatsData,
              setBandwidthData,
              setLoadingBandwidthData,
              setShowSiteStats,
              cameraDropDownValue,
              setFilteredDataLengths,
              sceneChangeValueString,
              setBulkSceneChangeOutcomesDialogOpen,
              navigate
            );
          }}
        >
          Submit
        </button>
      </DialogActions>
    </Dialog>
  );
};
