import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleDeleteCamera = async (
  selected: any,
  setSiteCameras: any,
  setAlertState: any,
  setSelected: any,
  setSyncNeeded: any,
  navigate: any,
  setBackdropLoadingScreenOn: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    handleDeleteCamera(
      selected,
      setSiteCameras,
      setAlertState,
      setSelected,
      setSyncNeeded,
      navigate,
      setBackdropLoadingScreenOn
    );
  };
  await selected.forEach(async (id: any) => {
    let deleteCameraFail = false;
    await AxiosUI.delete(`camera/${id}`).then(
      () => {
        deleteCameraFail = false;
      },
      (reason: AxiosError) => {
        deleteCameraFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    );
    !deleteCameraFail &&
      (await AxiosUI.get(
        `camera/site/?customer__id=${getNumbersAfterAboutSite(location.href)}`
      ).then(
        (response: AxiosResponse) => {
          localStorage.setItem("siteCameras", JSON.stringify(response.data));
          setSiteCameras(response.data);
          setAlertState((previousState: any) => {
            return {
              ...previousState,
              changeSuccess: false,
              activateSuccess: false,
              deactivateSuccess: false,
              disarmSuccess: false,
              armSuccess: false,
              syncSuccess: false,
              cameraDeleteSuccess: true,
              armFail: false,
            };
          });
          setSelected([]);
          setSyncNeeded(true);
          setBackdropLoadingScreenOn(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
        }
      ));
  });
};
