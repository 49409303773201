import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const checkForAlarmType = (
  alarmId: number,
  setBoldAlertsOnCamera: Dispatcher<boolean>,
  setPatriotAlertsOnCamera: Dispatcher<boolean>,
  setSureViewAlertsOnCamera: Dispatcher<boolean>,
  setSentinelAlertsOnCamera: Dispatcher<boolean>,
  setImmixAlertsOnCamera: Dispatcher<boolean>,
  setWebhooksAlertsOnCamera: Dispatcher<boolean>,
  setSoftguardAlertsOnCamera: Dispatcher<boolean>
) => {
  if (!localStorage.alarmOptions) {
    return;
  }

  const alarmOptions = JSON.parse(localStorage.alarmOptions);

  const alarmNames = new Map();
  alarmOptions.forEach((object: any) => {
    alarmNames.set(object.value, object.name);
  });

  const alarmType = alarmNames.get(alarmId);

  if (alarmType === "Bold") {
    setBoldAlertsOnCamera(true);
  }
  if (alarmType === "Patriot") {
    setPatriotAlertsOnCamera(true);
  }
  if (alarmType === "SureView") {
    setSureViewAlertsOnCamera(true);
  }
  if (alarmType === "Sentinel") {
    setSentinelAlertsOnCamera(true);
  }
  if (alarmType === "Immix") {
    setImmixAlertsOnCamera(true);
  }
  if (alarmType === "Webhooks") {
    setWebhooksAlertsOnCamera(true);
  }
  if (alarmType === "Softguard") {
    setSoftguardAlertsOnCamera(true);
  }
};
