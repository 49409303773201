import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { AlertData, Order } from "../Alerts/AlertsUtils/alertsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import {
  getComparator,
  stableSort,
} from "../Alerts/AlertsUtils/alertsTableFunctions";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { NavigateFunction } from "react-router-dom";
import useWindowDimensions from "../../common/useWindowDimensions";
import { cancelIconRed } from "../../common/cancelIconRed";
import { checkedCircleIcon } from "../../common/checkedCircleIcon";
import { EnhancedGroupUsersTableHead } from "./EnhancedGroupUsersTableHead";
import { dateConvertor } from "../../utils/dateConvertor";
import { handleResendPassword } from "./GroupUsersUtils/handleResendPassword";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { getGroupUsersData } from "./GroupUsersUtils/getGroupUsersData";

export const GroupUsersTable = ({
  groupUserData,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSelectedRow,
  setAboutUserDialogOpen,
  setSelectedRowIndex,
  setGroupUserId,
  setDeleteGroupUserDialogOpen,
  setUpdatingGroupUserData,
  setAlertState,
  setUserNameString,
  filteredDataExists,
  filteredData,
  activeData,
  moreUsersToLoad,
  setGroupUserData,
  setFetchingGroupUserData,
  moreUsersToLoadPageNumber,
  searchString,
  setBackgroundLoadingData,
  setFilteredData,
  setActiveData,
  setMoreUsersToLoad,
  setMoreUsersToLoadPageNumber,
  navigate,
}: {
  groupUserData: any;
  page: number;
  setPage: Dispatcher<number>;
  rowsPerPage: number;
  setRowsPerPage: Dispatcher<number>;
  setSelectedRow: any;
  setAboutUserDialogOpen: Dispatcher<boolean>;
  setSelectedRowIndex: Dispatcher<number>;
  setGroupUserId: any;
  setDeleteGroupUserDialogOpen: Dispatcher<boolean>;
  setUpdatingGroupUserData: Dispatcher<boolean>;
  setAlertState: any;
  setUserNameString: any;
  filteredDataExists: boolean;
  filteredData: any;
  activeData: any;
  moreUsersToLoad: boolean;
  setGroupUserData: any;
  setFetchingGroupUserData: Dispatcher<boolean>;
  moreUsersToLoadPageNumber: number;
  searchString: string;
  setBackgroundLoadingData: Dispatcher<boolean>;
  setFilteredData: any;
  setActiveData: any;
  setMoreUsersToLoad: any;
  setMoreUsersToLoadPageNumber: any;
  navigate: NavigateFunction;
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AlertData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(activeData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage]
  );

  const { height, width } = useWindowDimensions();

  React.useEffect(() => {
    setCalculatedHeight(height - 205);
    setCalculatedWidth(width - 150);
  }, [height, width]);

  const [calcuatedHeight, setCalculatedHeight] = React.useState(0);

  const [calcuatedWidth, setCalculatedWidth] = React.useState(0);

  const [displayShowMoreButton, setDisplayShowMoreButton] =
    React.useState(false);

  React.useEffect(() => {
    if (rowsPerPage === 25) {
      setDisplayShowMoreButton(activeData.length / 25 === page + 1);
    }
    if (rowsPerPage === 10) {
      setDisplayShowMoreButton(activeData.length / 10 === page + 1);
    }
    if (rowsPerPage === 5) {
      setDisplayShowMoreButton(activeData.length / 5 === page + 1);
    }
  }, [rowsPerPage, page, activeData]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{ maxHeight: calcuatedHeight, minWidth: calcuatedWidth }}
      >
        <Table sx={{ fontFamily: "Mulish" }} stickyHeader>
          <EnhancedGroupUsersTableHead
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
          />
          <TableBody>
            {activeData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((obj: any, index: any) => {
                return (
                  <React.Fragment key={obj.id}>
                    <TableRow
                      tabIndex={-1}
                      hover
                      onClick={() => {
                        setSelectedRow(obj);
                        setSelectedRowIndex(
                          activeData.findIndex((item: any) => item === obj)
                        );
                      }}
                      sx={{
                        cursor: "pointer",
                        fontFamily: "Mulish",
                      }}
                    >
                      <TableCell
                        sx={{ fontFamily: "Mulish" }}
                        align="left"
                        onClick={() => {
                          setSelectedRow(obj);
                          setSelectedRowIndex(
                            activeData.findIndex((item: any) => item === obj)
                          );
                          setAboutUserDialogOpen(true);
                          setAlertState((previousState: any) => {
                            return {
                              ...previousState,
                              updateUserSuccess: false,
                              deleteUserSuccess: false,
                              addUserSuccess: false,
                              emailSent: false,
                              noData: false,
                            };
                          });
                        }}
                      >
                        {obj.user.username}
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Mulish" }}
                        align="left"
                        onClick={() => {
                          setSelectedRow(obj);
                          setSelectedRowIndex(
                            activeData.findIndex((item: any) => item === obj)
                          );
                          setAboutUserDialogOpen(true);
                          setAlertState((previousState: any) => {
                            return {
                              ...previousState,
                              updateUserSuccess: false,
                              deleteUserSuccess: false,
                              addUserSuccess: false,
                              emailSent: false,
                              noData: false,
                            };
                          });
                        }}
                      >
                        {obj.group.name}
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Mulish" }}
                        align="left"
                        onClick={() => {
                          setSelectedRow(obj);
                          setSelectedRowIndex(
                            activeData.findIndex((item: any) => item === obj)
                          );
                          setAboutUserDialogOpen(true);
                          setAlertState((previousState: any) => {
                            return {
                              ...previousState,
                              updateUserSuccess: false,
                              deleteUserSuccess: false,
                              addUserSuccess: false,
                              emailSent: false,
                              noData: false,
                            };
                          });
                        }}
                      >
                        {obj.access_level > 2 && obj.access_level !== 5
                          ? checkedCircleIcon
                          : cancelIconRed}
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Mulish" }}
                        align="left"
                        onClick={() => {
                          setSelectedRow(obj);
                          setSelectedRowIndex(
                            activeData.findIndex((item: any) => item === obj)
                          );
                          setAboutUserDialogOpen(true);
                          setAlertState((previousState: any) => {
                            return {
                              ...previousState,
                              updateUserSuccess: false,
                              deleteUserSuccess: false,
                              addUserSuccess: false,
                              emailSent: false,
                              noData: false,
                            };
                          });
                        }}
                      >
                        {dateConvertor(obj.user.last_login)}
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Mulish" }}
                        align="left"
                        onClick={() => {
                          setSelectedRow(obj);
                          setSelectedRowIndex(
                            activeData.findIndex((item: any) => item === obj)
                          );
                          setAboutUserDialogOpen(true);
                          setAlertState((previousState: any) => {
                            return {
                              ...previousState,
                              updateUserSuccess: false,
                              deleteUserSuccess: false,
                              addUserSuccess: false,
                              emailSent: false,
                              noData: false,
                            };
                          });
                        }}
                      >
                        {`${obj.site_count} sites`}
                      </TableCell>

                      <TableCell align="center">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(event) => {
                            setAlertState((previousState: any) => {
                              return {
                                ...previousState,
                                updateUserSuccess: false,
                                deleteUserSuccess: false,
                                addUserSuccess: false,
                                emailSent: false,
                                noData: false,
                              };
                            });
                            setUserNameString(obj.user.username);
                            handleClick(event);
                            setGroupUserId(obj.id);
                            setSelectedRow(obj);
                            setSelectedRowIndex(
                              activeData.findIndex((item: any) => item === obj)
                            );
                          }}
                        >
                          <GridMoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "250px",
                            },
                          }}
                        >
                          {/* <MenuItem
                            key="more-info"
                            onClick={() => {
                              setAboutUserDialogOpen(true);
                              handleClose();
                            }}
                          >
                            More Info
                          </MenuItem> */}
                          <MenuItem
                            key="resend-temp-password"
                            onClick={() => {
                              handleClose();
                              setUpdatingGroupUserData(true);
                              handleResendPassword(
                                obj.id,
                                setAlertState,
                                setUpdatingGroupUserData,
                                setUserNameString,
                                navigate
                              );
                            }}
                          >
                            Resend Temp Password
                          </MenuItem>
                          <MenuItem
                            key="delete"
                            onClick={() => {
                              setDeleteGroupUserDialogOpen(true);
                              setUpdatingGroupUserData(true);
                              handleClose();
                            }}
                          >
                            Delete User
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            {moreUsersToLoad && displayShowMoreButton && (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <OrangeTextButton
                    onClick={() => {
                      setUpdatingGroupUserData(true);
                      setDisplayShowMoreButton(false);
                      getGroupUsersData(
                        setGroupUserData,
                        activeData,
                        setUpdatingGroupUserData,
                        moreUsersToLoadPageNumber,
                        searchString,
                        setBackgroundLoadingData,
                        setAlertState,
                        setFilteredData,
                        setActiveData,
                        setMoreUsersToLoad,
                        setMoreUsersToLoadPageNumber,
                        navigate
                      );
                    }}
                    text="Load More"
                    disabled={false}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={
          activeData.length
          // filteredData[0] ?
          // filteredData.length
          // : groupUserData.length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
