import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { statusTypeCreator } from "./HealthMonitoringUtils/statusTypeCreator";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";
import { Link, useSearchParams } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { alertTitleCreator } from "./HealthMonitoringUtils/alertTitleCreator";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { theme } from "../AddSite/StepsStepper";
import { getPreviewURL } from "./HealthMonitoringUtils/getPreviewURL";
import { createFormatedData } from "./HealthMonitoringUtils/createFormatedData";
import { createCause } from "./HealthMonitoringUtils/createCause";
import { createNextSteps } from "./HealthMonitoringUtils/createNextSteps";
import { handleUpdateNotes } from "./HealthMonitoringUtils/handleUpdateNotes";
import { handleSubmitNoteChanges } from "./HealthMonitoringUtils/handleSubmitNoteChanges";
import { getImageURLS } from "./HealthMonitoringUtils/getImageURLS";
import { handleRemoveOpenAlertSearchParams } from "./HealthMonitoringUtils/handleRemoveOpenAlertSearchParams";
import { handleAddOpenAlertSearchParams } from "./HealthMonitoringUtils/handleAddOpenAlertSearchParams";
import { severityTypeIconCreator } from "./HealthMonitoringUtils/severityTypeCreator";

const AlertStatusDialog = ({
  alertsData,
  alertStatusDialogOpen,
  setAlertStatusDialogOpen,
  selectedRow,
  selectedRowIndex,
  setSelectedRowIndex,
  setSelectedRow,
  clickedTab,
  setFectchingAllData,
  setUnhealthyCamsData,
  unhealthyCamsData,
  setAlertsData,
  notesValue,
  setNotesValue,
  selectedRowsToSend,
  setSelectedRowsToSend,
  setSortedData,
  setActiveTable,
  sortedData,
  singleCellActivated,
  activeName,
  filters,
  alertFilter,
  setSceneChangeOutcomesDialogOpen,
  setUpdatingStatus,
  backgroundFrame,
  detectedFrame,
  setBackgroundFrame,
  setDetectedFrame,
  setSelectedRows,
  selectedRows,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  cameraDropDownValue,
  setFilteredDataLengths,
  sceneChangeValueString,
  setBulkSceneChangeOutcomesDialogOpen,
}: {
  alertsData: any;
  alertStatusDialogOpen: boolean;
  setAlertStatusDialogOpen: Dispatcher<boolean>;
  selectedRow: any;
  selectedRowIndex: number;
  setSelectedRowIndex: Dispatcher<number>;
  setSelectedRow: any;
  clickedTab: string;
  setFectchingAllData: Dispatcher<boolean>;
  setUnhealthyCamsData: any;
  unhealthyCamsData: any;
  setAlertsData: any;
  notesValue: string;
  setNotesValue: Dispatcher<string>;
  selectedRowsToSend: any;
  setSelectedRowsToSend: any;
  setSortedData: any;
  setActiveTable: any;
  sortedData: any;
  singleCellActivated: boolean;
  activeName: any;
  filters: any;
  alertFilter: any;
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  setUpdatingStatus: any;
  backgroundFrame: any;
  detectedFrame: any;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setSelectedRows: any;
  selectedRows: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  cameraDropDownValue: any;
  setFilteredDataLengths: any;
  sceneChangeValueString: string;
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
}) => {
  const activeData = sortedData;

  const [previewURL, setPreviewURL] = React.useState("");

  const [loadingImage, setLoadingImage] = React.useState(true);

  const [changesMade, setChangesMade] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    setNotesValue(selectedRow.notes);
  }, []);

  return (
    <Dialog open={alertStatusDialogOpen} fullWidth={true} maxWidth={"md"}>
      <DialogTitle sx={{ marginLeft: 5, p: 2 }} id="customized-dialog-title">
        {alertTitleCreator(selectedRow.error_type)}
        <div className="absolute top-4 right-[100px] text-gray-600 text-[14px]">
          {selectedRowIndex + 1} of {activeData.length}
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <OrangeTextButton
            onClick={() => {
              setLoadingImage(true);
              getPreviewURL(
                activeData[selectedRowIndex - 1].camera_id,
                setLoadingImage,
                setPreviewURL,
                navigate
              );
              setBackgroundFrame("");
              setDetectedFrame("");
              if (
                activeData[selectedRowIndex - 1].error_type === "scene_change"
              ) {
                getImageURLS(
                  activeData[selectedRowIndex - 1],
                  setBackgroundFrame,
                  setDetectedFrame,
                  navigate
                );
              }
              setSelectedRowIndex(selectedRowIndex - 1);
              setSelectedRow(activeData[selectedRowIndex - 1]);
              setNotesValue(activeData[selectedRowIndex - 1].notes);
              handleAddOpenAlertSearchParams(
                activeData[selectedRowIndex - 1],
                searchParams,
                setSearchParams
              );
              if (changesMade) {
                setSelectedRowsToSend((previousState: any) => {
                  return [...previousState, activeData[selectedRowIndex]];
                });
                setChangesMade(false);
              }
            }}
            text={<ArrowBackIosIcon fontSize="small" id="fowardButton" />}
            disabled={selectedRowIndex === 0}
          />
          <OrangeTextButton
            onClick={() => {
              setLoadingImage(true);
              getPreviewURL(
                activeData[selectedRowIndex + 1].camera_id,
                setLoadingImage,
                setPreviewURL,
                navigate
              );
              setBackgroundFrame("");
              setDetectedFrame("");
              if (
                activeData[selectedRowIndex + 1].error_type === "scene_change"
              ) {
                getImageURLS(
                  activeData[selectedRowIndex + 1],
                  setBackgroundFrame,
                  setDetectedFrame,
                  navigate
                );
              }

              setSelectedRowIndex(selectedRowIndex + 1);
              setSelectedRow(activeData[selectedRowIndex + 1]);
              setNotesValue(activeData[selectedRowIndex + 1].notes);
              handleAddOpenAlertSearchParams(
                activeData[selectedRowIndex + 1],
                searchParams,
                setSearchParams
              );
              if (changesMade) {
                setSelectedRowsToSend((previousState: any) => {
                  return [...previousState, activeData[selectedRowIndex]];
                });
                setChangesMade(false);
              }
            }}
            text={<ArrowForwardIosIcon fontSize="small" id="backButton" />}
            disabled={selectedRowIndex === activeData.length - 1}
          />
        </div>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          const updatedSelectedRowsToSend = selectedRowsToSend;
          if (changesMade) {
            updatedSelectedRowsToSend.push(activeData[selectedRowIndex]);
          }
          setAlertStatusDialogOpen(false);
          handleRemoveOpenAlertSearchParams(searchParams, setSearchParams);
          handleSubmitNoteChanges(
            updatedSelectedRowsToSend,
            setSelectedRowsToSend,
            navigate
          );
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className="h-[650px]">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  Site
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  Camera
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  Start Time
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  End Time
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  Severity
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", width: 95, color: "#0000EE" }}
                >
                  <Link
                    to={`/sites/aboutsite/${selectedRow.site_id}`}
                    target="_blank"
                  >
                    {selectedRow.site_name}
                  </Link>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", width: 95, color: "#0000EE" }}
                >
                  <Link
                    to={`/sites/${selectedRow.site_id}/editcamera/${selectedRow.camera_id}`}
                    target="_blank"
                  >
                    {selectedRow.camera_name}
                  </Link>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", width: 95 }}
                >
                  {createFormatedData(selectedRow.formatted_start_date)}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", width: 95 }}
                >
                  {selectedRow.formatted_end_date
                    ? createFormatedData(selectedRow.formatted_end_date)
                    : ""}{" "}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", width: 95 }}
                >
                  {severityTypeIconCreator(selectedRow.severity)}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", width: 95 }}
                >
                  {statusTypeCreator(
                    selectedRow.status,
                    selectedRow,
                    setFectchingAllData,
                    setAlertsData,
                    alertsData,
                    setUnhealthyCamsData,
                    unhealthyCamsData,
                    clickedTab,
                    setActiveTable,
                    setAlertStatusDialogOpen,
                    setSelectedRow,
                    setSelectedRowIndex,
                    setSortedData,
                    singleCellActivated,
                    activeName,
                    setUpdatingStatus,
                    filters,
                    alertFilter,
                    setSceneChangeOutcomesDialogOpen,
                    setBackgroundFrame,
                    setDetectedFrame,
                    setSelectedRows,
                    selectedRows,
                    setErrorsOverviewData,
                    setCameraStatsData,
                    setLoadingCameraStatsData,
                    setBandwidthData,
                    setLoadingBandwidthData,
                    setShowSiteStats,
                    cameraDropDownValue,
                    setFilteredDataLengths,
                    sceneChangeValueString,
                    setBulkSceneChangeOutcomesDialogOpen,
                    navigate
                  )}{" "}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <p className="font-mulish text-actuate-blue text-[20px] pt-3">
            Likely Cause
          </p>
          <p className="font-mulish pt-2">{createCause(selectedRow)}</p>
          <p className="font-mulish text-actuate-blue text-[20px] pt-3">
            Next Steps
          </p>
          <p className="font-mulish pt-2">
            {`${createNextSteps(
              selectedRow
            )} Please refer to our support wiki for common troubleshooting steps `}
            {
              <a
                className="text-[#0000EE]"
                href="https://support.actuateui.net/camera-health-troubleshooting/"
              >
                https://support.actuateui.net/camera-health-troubleshooting/
              </a>
            }
          </p>
          <br></br>
          <hr></hr>
          {selectedRow.error_type === "scene_change" && (
            <div>
              <div className="columns-2 pt-3 w-[895]">
                <p>
                  Reference Image
                  <img
                    className="w-[413px]"
                    src={backgroundFrame}
                    alt="camera_image"
                  />
                </p>
                <p>
                  Detected Image
                  <img
                    className="w-[413px]"
                    src={detectedFrame}
                    alt="camera_image"
                  />
                </p>
              </div>
              <br></br>
              <hr></hr>
            </div>
          )}
          <br></br>
          <p className="font-mulish text-actuate-blue text-[20px] pt-3">
            Notes
          </p>
          <TextField
            onChange={(event) => {
              setChangesMade(true);
              setNotesValue(event.target.value);
              handleUpdateNotes(
                selectedRow,
                event.target.value,
                alertsData,
                setAlertsData
              );
            }}
            value={notesValue}
            variant="outlined"
            multiline
            rows={3}
            sx={{ fontFamily: "Mulish", width: 600 }}
          />
          <br></br>
          <br></br>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertStatusDialog;
