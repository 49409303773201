import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";

export const WebhooksCameraTab = ({
  cameraWebhookData,
}: {
  cameraWebhookData: any;
}) => {
  const titlesArray = ["Webhook", "Custom Fields", "Event Type"];

  const handleConvertTypeToString = (string: any) => {
    if (string === "no_motion") {
      return "No Motion";
    } else if (string === "unable_to_connect") {
      return "Unable to Connect";
    } else if (string === "detection") {
      return "Detection";
    } else return "";
  };

  return (
    <TableContainer component={Paper} sx={{ width: "screen" }}>
      <Table
        sx={{
          postion: "absolute",
          width: "screen",
          fontFamily: "Mulish",
        }}
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          {cameraWebhookData.webhook && (
            <TableRow>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraWebhookData.webhook.display_name
                  ? cameraWebhookData.webhook.display_name
                  : ""}
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraWebhookData.custom_fields
                  ? `${cameraWebhookData.custom_fields}`
                  : ""}
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraWebhookData.event_types
                  ? cameraWebhookData.event_types[0]
                    ? handleConvertTypeToString(
                        cameraWebhookData.event_types[0]
                      )
                    : ""
                  : ""}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
