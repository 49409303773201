// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxisTab } from "../AxisTab";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getAxisData = async (
  inputId: string,
  navigate: NavigateFunction,
  setRecipientsConfiguredData: any,
  setTitlesArray: Dispatcher<any>,
  setActionRulesData: any,
  setActionRulesTitlesArray: Dispatcher<any>,
  setApplicationsConfiguredData: Dispatcher<any>,
  mobotixFinished: any,
  setAxisFinished: Dispatcher<any>,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  axisFinished: any,
  setActiveTab: any,
  actionRulesTitlesArray: any,
  applicationsConfiguredData: any,
  cameraGeneralInfo: any,
  actionRulesData: any
) => {
  const retryFunction = () => {
    getAxisData(
      inputId,
      navigate,
      setRecipientsConfiguredData,
      setTitlesArray,
      setActionRulesData,
      setActionRulesTitlesArray,
      setApplicationsConfiguredData,
      mobotixFinished,
      setAxisFinished,
      setBackdropLoadingScreenOn,
      axisFinished,
      setActiveTab,
      actionRulesTitlesArray,
      applicationsConfiguredData,
      cameraGeneralInfo,
      actionRulesData
    );
  };

  const AxiosUI = createApi("");

  let getRecipientFail = false;
  let getActionsRulesFail = false;

  let recipientData: any[] = [];

  let recipientTitlesArray: any[] = [];

  let newActionRulesData: any = [];

  let actionsRulesTitles: any = [];

  await AxiosUI.get(`/axis/${inputId}/recipient/`).then(
    (response: any) => {
      if (response.data) {
        if (response.data[0]) {
          if (response.data[0].level_tag) {
            if (response.data[0].message) {
              if (response.data[0].message.includes("Error retrieving")) {
                alert("Error retrieving recipients");
              }
            }
            setAxisFinished((previousState: any) => {
              return { ...previousState, recipient: true };
            });
            if (localStorage.editCamerActiveTab === "seven") {
              setBackdropLoadingScreenOn(true);
              recipientTitlesArray = [
                "Name",
                "Recipient Type",
                "Host",
                "Port",
                "Email From",
                "Email To",
              ];
              setActiveTab(
                <AxisTab
                  recipientsConfiguredData={[]}
                  titlesArray={[
                    "Name",
                    "Recipient Type",
                    "Host",
                    "Port",
                    "Email From",
                    "Email To",
                  ]}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setRecipientsConfiguredData={setRecipientsConfiguredData}
                  setTitlesArray={setTitlesArray}
                  actionRulesData={actionRulesData}
                  setActionRulesData={setActionRulesData}
                  actionRulesTitlesArray={actionRulesTitlesArray}
                  setActionRulesTitlesArray={setActionRulesTitlesArray}
                  setActiveTab={setActiveTab}
                  applicationsConfiguredData={applicationsConfiguredData}
                  cameraGeneralInfo={cameraGeneralInfo}
                  axisFinished={{
                    recipient: true,
                    actionRules: false,
                    application: false,
                  }}
                />
              );
              setBackdropLoadingScreenOn(false);
            }
          } else {
            getRecipientFail = false;
            setAxisFinished((previousState: any) => {
              return { ...previousState, recipient: true };
            });
            setRecipientsConfiguredData(response.data);
            const newTitlesArray = ["Name"];
            response.data.forEach((object: any) => {
              object.additional_fields.forEach((obj: any) => {
                if (
                  obj.id === "recipient_type" &&
                  !newTitlesArray.includes("Recipient Type")
                ) {
                  newTitlesArray.push("Recipient Type");
                }
                if (obj.id === "host" && !newTitlesArray.includes("Host")) {
                  newTitlesArray.push("Host");
                }
                if (obj.id === "port" && !newTitlesArray.includes("Port")) {
                  newTitlesArray.push("Port");
                }
                if (
                  obj.id === "email_from" &&
                  !newTitlesArray.includes("Email From")
                ) {
                  newTitlesArray.push("Email From");
                }
                if (
                  obj.id === "email_to" &&
                  !newTitlesArray.includes("Email To")
                ) {
                  newTitlesArray.push("Email To");
                }
                if (obj.id === "login" && !newTitlesArray.includes("Login")) {
                  newTitlesArray.push("Login");
                }
                if (
                  obj.id === "password" &&
                  !newTitlesArray.includes("Password")
                ) {
                  newTitlesArray.push("Password");
                }
              });
            });
            newTitlesArray.push("");
            setTitlesArray(newTitlesArray);
            recipientTitlesArray = newTitlesArray;
            recipientData = response.data;
            if (localStorage.editCamerActiveTab === "seven") {
              setBackdropLoadingScreenOn(true);
              setActiveTab(
                <AxisTab
                  recipientsConfiguredData={recipientData}
                  titlesArray={newTitlesArray}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setRecipientsConfiguredData={setRecipientsConfiguredData}
                  setTitlesArray={setTitlesArray}
                  actionRulesData={actionRulesData}
                  setActionRulesData={setActionRulesData}
                  actionRulesTitlesArray={actionRulesTitlesArray}
                  setActionRulesTitlesArray={setActionRulesTitlesArray}
                  setActiveTab={setActiveTab}
                  applicationsConfiguredData={applicationsConfiguredData}
                  cameraGeneralInfo={cameraGeneralInfo}
                  axisFinished={{
                    recipient: true,
                    actionRules: false,
                    application: false,
                  }}
                />
              );
              setBackdropLoadingScreenOn(false);
            }
          }
        } else {
          setBackdropLoadingScreenOn(true);
          recipientTitlesArray = [
            "Name",
            "Recipient Type",
            "Host",
            "Port",
            "Email From",
            "Email To",
          ];
          setActiveTab(
            <AxisTab
              recipientsConfiguredData={[]}
              titlesArray={[
                "Name",
                "Recipient Type",
                "Host",
                "Port",
                "Email From",
                "Email To",
              ]}
              navigate={navigate}
              setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
              setRecipientsConfiguredData={setRecipientsConfiguredData}
              setTitlesArray={setTitlesArray}
              actionRulesData={actionRulesData}
              setActionRulesData={setActionRulesData}
              actionRulesTitlesArray={actionRulesTitlesArray}
              setActionRulesTitlesArray={setActionRulesTitlesArray}
              setActiveTab={setActiveTab}
              applicationsConfiguredData={applicationsConfiguredData}
              cameraGeneralInfo={cameraGeneralInfo}
              axisFinished={{
                recipient: true,
                actionRules: true,
                application: true,
              }}
            />
          );
          setAxisFinished({
            recipient: true,
            actionRules: true,
            application: true,
          });
          setBackdropLoadingScreenOn(false);
        }
      } else {
        setBackdropLoadingScreenOn(true);
        recipientTitlesArray = [
          "Name",
          "Recipient Type",
          "Host",
          "Port",
          "Email From",
          "Email To",
        ];
        setActiveTab(
          <AxisTab
            recipientsConfiguredData={[]}
            titlesArray={[
              "Name",
              "Recipient Type",
              "Host",
              "Port",
              "Email From",
              "Email To",
            ]}
            navigate={navigate}
            setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
            setRecipientsConfiguredData={setRecipientsConfiguredData}
            setTitlesArray={setTitlesArray}
            actionRulesData={actionRulesData}
            setActionRulesData={setActionRulesData}
            actionRulesTitlesArray={actionRulesTitlesArray}
            setActionRulesTitlesArray={setActionRulesTitlesArray}
            setActiveTab={setActiveTab}
            applicationsConfiguredData={applicationsConfiguredData}
            cameraGeneralInfo={cameraGeneralInfo}
            axisFinished={{
              recipient: true,
              actionRules: true,
              application: true,
            }}
          />
        );
        setAxisFinished({
          recipient: true,
          actionRules: true,
          application: true,
        });
        setBackdropLoadingScreenOn(false);
      }
      AxiosUI.get(`/axis/${inputId}/action_rule/`).then(
        (response: any) => {
          if (response.data) {
            if (response.data[0]) {
              {
                if (response.data[0].level_tag) {
                  if (response.data[0].message) {
                    if (response.data[0].message.includes("Error retrieving")) {
                      alert("Error retrieving action rules");
                    }
                  }
                  setAxisFinished((previousState: any) => {
                    return { ...previousState, actionRules: true };
                  });
                  if (localStorage.editCamerActiveTab === "seven") {
                    setBackdropLoadingScreenOn(true);
                    actionsRulesTitles = [
                      "Name",
                      "Host",
                      "Port",
                      "Email From",
                      "Email To",
                      "Message",
                      "Enabled",
                      "Profile",
                    ];
                    setActiveTab(
                      <AxisTab
                        recipientsConfiguredData={recipientData}
                        titlesArray={recipientTitlesArray}
                        navigate={navigate}
                        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                        setRecipientsConfiguredData={
                          setRecipientsConfiguredData
                        }
                        setTitlesArray={setTitlesArray}
                        actionRulesData={[]}
                        setActionRulesData={setActionRulesData}
                        actionRulesTitlesArray={[
                          "Name",
                          "Host",
                          "Port",
                          "Email From",
                          "Email To",
                          "Message",
                          "Enabled",
                          "Profile",
                        ]}
                        setActionRulesTitlesArray={setActionRulesTitlesArray}
                        setActiveTab={setActiveTab}
                        applicationsConfiguredData={applicationsConfiguredData}
                        cameraGeneralInfo={cameraGeneralInfo}
                        axisFinished={{
                          recipient: true,
                          actionRules: true,
                          application: false,
                        }}
                      />
                    );
                    setBackdropLoadingScreenOn(false);
                  }
                } else {
                  getActionsRulesFail = false;
                  setActionRulesData(response.data);
                  newActionRulesData = response.data;
                  setAxisFinished((previousState: any) => {
                    return { ...previousState, actionRules: true };
                  });
                  const newActionRulesTitlesArray = ["Name"];
                  response.data.forEach((object: any) => {
                    object.additional_fields.forEach((obj: any) => {
                      if (
                        obj.id === "host" &&
                        !newActionRulesTitlesArray.includes("Host")
                      ) {
                        newActionRulesTitlesArray.push("Host");
                      }
                      if (
                        obj.id === "port" &&
                        !newActionRulesTitlesArray.includes("Port")
                      ) {
                        newActionRulesTitlesArray.push("Port");
                      }
                      if (
                        obj.id === "email_to" &&
                        !newActionRulesTitlesArray.includes("E-mail to")
                      ) {
                        newActionRulesTitlesArray.push("E-mail to");
                      }
                      if (
                        obj.id === "email_from" &&
                        !newActionRulesTitlesArray.includes("E-mail from")
                      ) {
                        newActionRulesTitlesArray.push("E-mail from");
                      }
                      if (
                        obj.id === "message" &&
                        !newActionRulesTitlesArray.includes("Message")
                      ) {
                        newActionRulesTitlesArray.push("Message");
                      }
                      if (
                        obj.id === "enabled" &&
                        !newActionRulesTitlesArray.includes("Enabled")
                      ) {
                        newActionRulesTitlesArray.push("Enabled");
                      }
                      if (
                        obj.id === "profile_selected" &&
                        !newActionRulesTitlesArray.includes("Profile")
                      ) {
                        newActionRulesTitlesArray.push("Profile");
                      }
                    });
                  });
                  const orderedFinalTitles: any = ["Name"];
                  if (!orderedFinalTitles.includes("Host")) {
                    orderedFinalTitles.push("Host");
                  }
                  if (!orderedFinalTitles.includes("Port")) {
                    orderedFinalTitles.push("Port");
                  }
                  if (!orderedFinalTitles.includes("E-mail to")) {
                    orderedFinalTitles.push("E-mail to");
                  }
                  if (!orderedFinalTitles.includes("E-mail from")) {
                    orderedFinalTitles.push("E-mail from");
                  }
                  if (!orderedFinalTitles.includes("Message")) {
                    orderedFinalTitles.push("Message");
                  }
                  if (!orderedFinalTitles.includes("Enabled")) {
                    orderedFinalTitles.push("Enabled");
                  }
                  if (!orderedFinalTitles.includes("Profile")) {
                    orderedFinalTitles.push("Profile");
                  }
                  orderedFinalTitles.push("");
                  setActionRulesTitlesArray(orderedFinalTitles);
                  actionsRulesTitles = orderedFinalTitles;
                  if (localStorage.editCamerActiveTab === "seven") {
                    setBackdropLoadingScreenOn(true);
                    setActiveTab(
                      <AxisTab
                        recipientsConfiguredData={recipientData}
                        titlesArray={recipientTitlesArray}
                        navigate={navigate}
                        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                        setRecipientsConfiguredData={
                          setRecipientsConfiguredData
                        }
                        setTitlesArray={setTitlesArray}
                        actionRulesData={response.data}
                        setActionRulesData={setActionRulesData}
                        actionRulesTitlesArray={orderedFinalTitles}
                        setActionRulesTitlesArray={setActionRulesTitlesArray}
                        setActiveTab={setActiveTab}
                        applicationsConfiguredData={applicationsConfiguredData}
                        cameraGeneralInfo={cameraGeneralInfo}
                        axisFinished={{
                          recipient: true,
                          actionRules: true,
                          application: false,
                        }}
                      />
                    );
                    setBackdropLoadingScreenOn(false);
                  }
                }
              }
            } else {
              actionsRulesTitles = [
                "Name",
                "Host",
                "Port",
                "Email From",
                "Email To",
                "Message",
                "Enabled",
                "Profile",
              ];
              setBackdropLoadingScreenOn(true);
              setActiveTab(
                <AxisTab
                  recipientsConfiguredData={recipientData}
                  titlesArray={recipientTitlesArray}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setRecipientsConfiguredData={setRecipientsConfiguredData}
                  setTitlesArray={setTitlesArray}
                  actionRulesData={[]}
                  setActionRulesData={setActionRulesData}
                  actionRulesTitlesArray={[
                    "Name",
                    "Host",
                    "Port",
                    "Email From",
                    "Email To",
                    "Message",
                    "Enabled",
                    "Profile",
                  ]}
                  setActionRulesTitlesArray={setActionRulesTitlesArray}
                  setActiveTab={setActiveTab}
                  applicationsConfiguredData={applicationsConfiguredData}
                  cameraGeneralInfo={cameraGeneralInfo}
                  axisFinished={{
                    recipient: true,
                    actionRules: true,
                    application: false,
                  }}
                />
              );
              setBackdropLoadingScreenOn(false);
            }
          } else {
            actionsRulesTitles = [
              "Name",
              "Host",
              "Port",
              "Email From",
              "Email To",
              "Message",
              "Enabled",
              "Profile",
            ];
            setBackdropLoadingScreenOn(true);
            setActiveTab(
              <AxisTab
                recipientsConfiguredData={recipientData}
                titlesArray={recipientTitlesArray}
                navigate={navigate}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setRecipientsConfiguredData={setRecipientsConfiguredData}
                setTitlesArray={setTitlesArray}
                actionRulesData={[]}
                setActionRulesData={setActionRulesData}
                actionRulesTitlesArray={[
                  "Name",
                  "Host",
                  "Port",
                  "Email From",
                  "Email To",
                  "Message",
                  "Enabled",
                  "Profile",
                ]}
                setActionRulesTitlesArray={setActionRulesTitlesArray}
                setActiveTab={setActiveTab}
                applicationsConfiguredData={applicationsConfiguredData}
                cameraGeneralInfo={cameraGeneralInfo}
                axisFinished={{
                  recipient: true,
                  actionRules: true,
                  application: false,
                }}
              />
            );
            setBackdropLoadingScreenOn(false);
          }
          AxiosUI.get(`/axis/${inputId}/application/`).then(
            (response: AxiosResponse) => {
              if (response.data) {
                if (response.data[0]) {
                  if (response.data[0].level_tag) {
                    if (response.data[0].message) {
                      if (
                        response.data[0].message.includes("Error retrieving")
                      ) {
                        alert("Error retrieving applications");
                      }
                    }
                    setAxisFinished((previousState: any) => {
                      return { ...previousState, application: true };
                    });
                    if (localStorage.editCamerActiveTab === "seven") {
                      setBackdropLoadingScreenOn(true);
                      setActiveTab(
                        <AxisTab
                          recipientsConfiguredData={recipientData}
                          titlesArray={recipientTitlesArray}
                          navigate={navigate}
                          setBackdropLoadingScreenOn={
                            setBackdropLoadingScreenOn
                          }
                          setRecipientsConfiguredData={
                            setRecipientsConfiguredData
                          }
                          setTitlesArray={setTitlesArray}
                          actionRulesData={newActionRulesData}
                          setActionRulesData={setActionRulesData}
                          actionRulesTitlesArray={actionsRulesTitles}
                          setActionRulesTitlesArray={setActionRulesTitlesArray}
                          setActiveTab={setActiveTab}
                          applicationsConfiguredData={[]}
                          cameraGeneralInfo={cameraGeneralInfo}
                          axisFinished={{
                            recipient: true,
                            actionRules: true,
                            application: true,
                          }}
                        />
                      );
                      setBackdropLoadingScreenOn(false);
                    }
                  } else {
                    setApplicationsConfiguredData(response.data);
                    setAxisFinished((previousState: any) => {
                      return { ...previousState, application: true };
                    });
                    if (localStorage.editCamerActiveTab === "seven") {
                      setBackdropLoadingScreenOn(true);
                      setActiveTab(
                        <AxisTab
                          recipientsConfiguredData={recipientData}
                          titlesArray={recipientTitlesArray}
                          navigate={navigate}
                          setBackdropLoadingScreenOn={
                            setBackdropLoadingScreenOn
                          }
                          setRecipientsConfiguredData={
                            setRecipientsConfiguredData
                          }
                          setTitlesArray={setTitlesArray}
                          actionRulesData={newActionRulesData}
                          setActionRulesData={setActionRulesData}
                          actionRulesTitlesArray={actionsRulesTitles}
                          setActionRulesTitlesArray={setActionRulesTitlesArray}
                          setActiveTab={setActiveTab}
                          applicationsConfiguredData={response.data}
                          cameraGeneralInfo={cameraGeneralInfo}
                          axisFinished={{
                            recipient: true,
                            actionRules: true,
                            application: true,
                          }}
                        />
                      );
                      setBackdropLoadingScreenOn(false);
                    }
                  }
                } else {
                  setBackdropLoadingScreenOn(true);
                  setActiveTab(
                    <AxisTab
                      recipientsConfiguredData={recipientData}
                      titlesArray={recipientTitlesArray}
                      navigate={navigate}
                      setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                      setRecipientsConfiguredData={setRecipientsConfiguredData}
                      setTitlesArray={setTitlesArray}
                      actionRulesData={newActionRulesData}
                      setActionRulesData={setActionRulesData}
                      actionRulesTitlesArray={actionsRulesTitles}
                      setActionRulesTitlesArray={setActionRulesTitlesArray}
                      setActiveTab={setActiveTab}
                      applicationsConfiguredData={[]}
                      cameraGeneralInfo={cameraGeneralInfo}
                      axisFinished={{
                        recipient: true,
                        actionRules: true,
                        application: true,
                      }}
                    />
                  );
                  setBackdropLoadingScreenOn(false);
                }
              } else {
                setBackdropLoadingScreenOn(true);
                setActiveTab(
                  <AxisTab
                    recipientsConfiguredData={recipientData}
                    titlesArray={recipientTitlesArray}
                    navigate={navigate}
                    setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                    setRecipientsConfiguredData={setRecipientsConfiguredData}
                    setTitlesArray={setTitlesArray}
                    actionRulesData={newActionRulesData}
                    setActionRulesData={setActionRulesData}
                    actionRulesTitlesArray={actionsRulesTitles}
                    setActionRulesTitlesArray={setActionRulesTitlesArray}
                    setActiveTab={setActiveTab}
                    applicationsConfiguredData={[]}
                    cameraGeneralInfo={cameraGeneralInfo}
                    axisFinished={{
                      recipient: true,
                      actionRules: true,
                      application: true,
                    }}
                  />
                );
                setBackdropLoadingScreenOn(false);
              }
            },
            (reason: AxiosError) => {
              universalAPIErrorHandler(reason, navigate, retryFunction, () => {
                // eslint-disable-next-line no-console
                console.log(`${reason}`);
                setAxisFinished((previousState: any) => {
                  return { ...previousState, application: true };
                });
                if (localStorage.editCamerActiveTab === "seven") {
                  setBackdropLoadingScreenOn(true);
                  setActiveTab(
                    <AxisTab
                      recipientsConfiguredData={recipientData}
                      titlesArray={recipientTitlesArray}
                      navigate={navigate}
                      setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                      setRecipientsConfiguredData={setRecipientsConfiguredData}
                      setTitlesArray={setTitlesArray}
                      actionRulesData={newActionRulesData}
                      setActionRulesData={setActionRulesData}
                      actionRulesTitlesArray={actionsRulesTitles}
                      setActionRulesTitlesArray={setActionRulesTitlesArray}
                      setActiveTab={setActiveTab}
                      applicationsConfiguredData={[]}
                      cameraGeneralInfo={cameraGeneralInfo}
                      axisFinished={{
                        recipient: true,
                        actionRules: true,
                        application: true,
                      }}
                    />
                  );
                  setBackdropLoadingScreenOn(false);
                }
              });
            }
          );
        },
        (reason: AxiosError) => {
          getActionsRulesFail = true;
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setAxisFinished((previousState: any) => {
              return { ...previousState, actionRules: true };
            });
            if (localStorage.editCamerActiveTab === "seven") {
              actionsRulesTitles = [
                "Name",
                "Host",
                "Port",
                "Email From",
                "Email To",
                "Message",
                "Enabled",
                "Profile",
              ];
              setBackdropLoadingScreenOn(true);
              setActiveTab(
                <AxisTab
                  recipientsConfiguredData={recipientData}
                  titlesArray={recipientTitlesArray}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setRecipientsConfiguredData={setRecipientsConfiguredData}
                  setTitlesArray={setTitlesArray}
                  actionRulesData={[]}
                  setActionRulesData={setActionRulesData}
                  actionRulesTitlesArray={[
                    "Name",
                    "Host",
                    "Port",
                    "Email From",
                    "Email To",
                    "Message",
                    "Enabled",
                    "Profile",
                  ]}
                  setActionRulesTitlesArray={setActionRulesTitlesArray}
                  setActiveTab={setActiveTab}
                  applicationsConfiguredData={applicationsConfiguredData}
                  cameraGeneralInfo={cameraGeneralInfo}
                  axisFinished={{
                    recipient: true,
                    actionRules: true,
                    application: false,
                  }}
                />
              );
              setBackdropLoadingScreenOn(false);
            }
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setAxisFinished((previousState: any) => {
          return { ...previousState, recipient: true };
        });
        if (localStorage.editCamerActiveTab === "seven") {
          setBackdropLoadingScreenOn(true);
          recipientTitlesArray = [
            "Name",
            "Recipient Type",
            "Host",
            "Port",
            "Email From",
            "Email To",
          ];
          setActiveTab(
            <AxisTab
              recipientsConfiguredData={[]}
              titlesArray={[
                "Name",
                "Recipient Type",
                "Host",
                "Port",
                "Email From",
                "Email To",
              ]}
              navigate={navigate}
              setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
              setRecipientsConfiguredData={setRecipientsConfiguredData}
              setTitlesArray={setTitlesArray}
              actionRulesData={actionRulesData}
              setActionRulesData={setActionRulesData}
              actionRulesTitlesArray={actionRulesTitlesArray}
              setActionRulesTitlesArray={setActionRulesTitlesArray}
              setActiveTab={setActiveTab}
              applicationsConfiguredData={applicationsConfiguredData}
              cameraGeneralInfo={cameraGeneralInfo}
              axisFinished={{
                recipient: true,
                actionRules: true,
                application: true,
              }}
            />
          );
          setAxisFinished({
            recipient: true,
            actionRules: true,
            application: true,
          });
          setBackdropLoadingScreenOn(false);
        }
      });
    }
  );

  //   !getRecipientFail &&
  //     (await AxiosUI.get(`/axis/${inputId}/action_rule/`).then(
  //       (response: any) => {
  //         if (response.data[0].level_tag) {
  //           if (response.data[0].message) {
  //             if (response.data[0].message.includes("Error retrieving")) {
  //               alert("Error retrieving action rules");
  //             }
  //           }
  //           setAxisFinished((previousState: any) => {
  //             return { ...previousState, actionRules: true };
  //           });
  //           if (localStorage.editCamerActiveTab === "seven") {
  //             setBackdropLoadingScreenOn(true);
  //             actionsRulesTitles = [
  //               "Name",
  //               "Host",
  //               "Port",
  //               "Email From",
  //               "Email To",
  //               "Message",
  //               "Enabled",
  //               "Profile",
  //             ];
  //             setActiveTab(
  //               <AxisTab
  //                 recipientsConfiguredData={recipientData}
  //                 titlesArray={recipientTitlesArray}
  //                 navigate={navigate}
  //                 setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
  //                 setRecipientsConfiguredData={setRecipientsConfiguredData}
  //                 setTitlesArray={setTitlesArray}
  //                 actionRulesData={[]}
  //                 setActionRulesData={setActionRulesData}
  //                 actionRulesTitlesArray={[
  //                   "Name",
  //                   "Host",
  //                   "Port",
  //                   "Email From",
  //                   "Email To",
  //                   "Message",
  //                   "Enabled",
  //                   "Profile",
  //                 ]}
  //                 setActionRulesTitlesArray={setActionRulesTitlesArray}
  //                 setActiveTab={setActiveTab}
  //                 applicationsConfiguredData={applicationsConfiguredData}
  //                 cameraGeneralInfo={cameraGeneralInfo}
  //                 axisFinished={{
  //                   recipient: true,
  //                   actionRules: true,
  //                   application: false,
  //                 }}
  //               />
  //             );
  //             setBackdropLoadingScreenOn(false);
  //           }
  //         } else {
  //           getActionsRulesFail = false;
  //           setActionRulesData(response.data);
  //           newActionRulesData = response.data;
  //           setAxisFinished((previousState: any) => {
  //             return { ...previousState, actionRules: true };
  //           });
  //           const newActionRulesTitlesArray = ["Name"];
  //           response.data.forEach((object: any) => {
  //             object.additional_fields.forEach((obj: any) => {
  //               if (
  //                 obj.id === "host" &&
  //                 !newActionRulesTitlesArray.includes("Host")
  //               ) {
  //                 newActionRulesTitlesArray.push("Host");
  //               }
  //               if (
  //                 obj.id === "port" &&
  //                 !newActionRulesTitlesArray.includes("Port")
  //               ) {
  //                 newActionRulesTitlesArray.push("Port");
  //               }
  //               if (
  //                 obj.id === "email_to" &&
  //                 !newActionRulesTitlesArray.includes("E-mail to")
  //               ) {
  //                 newActionRulesTitlesArray.push("E-mail to");
  //               }
  //               if (
  //                 obj.id === "email_from" &&
  //                 !newActionRulesTitlesArray.includes("E-mail from")
  //               ) {
  //                 newActionRulesTitlesArray.push("E-mail from");
  //               }
  //               if (
  //                 obj.id === "message" &&
  //                 !newActionRulesTitlesArray.includes("Message")
  //               ) {
  //                 newActionRulesTitlesArray.push("Message");
  //               }
  //               if (
  //                 obj.id === "enabled" &&
  //                 !newActionRulesTitlesArray.includes("Enabled")
  //               ) {
  //                 newActionRulesTitlesArray.push("Enabled");
  //               }
  //               if (
  //                 obj.id === "profile_selected" &&
  //                 !newActionRulesTitlesArray.includes("Profile")
  //               ) {
  //                 newActionRulesTitlesArray.push("Profile");
  //               }
  //             });
  //           });
  //           const orderedFinalTitles: any = ["Name"];
  //           if (!orderedFinalTitles.includes("Host")) {
  //             orderedFinalTitles.push("Host");
  //           }
  //           if (!orderedFinalTitles.includes("Port")) {
  //             orderedFinalTitles.push("Port");
  //           }
  //           if (!orderedFinalTitles.includes("E-mail to")) {
  //             orderedFinalTitles.push("E-mail to");
  //           }
  //           if (!orderedFinalTitles.includes("E-mail from")) {
  //             orderedFinalTitles.push("E-mail from");
  //           }
  //           if (!orderedFinalTitles.includes("Message")) {
  //             orderedFinalTitles.push("Message");
  //           }
  //           if (!orderedFinalTitles.includes("Enabled")) {
  //             orderedFinalTitles.push("Enabled");
  //           }
  //           if (!orderedFinalTitles.includes("Profile")) {
  //             orderedFinalTitles.push("Profile");
  //           }
  //           orderedFinalTitles.push("");
  //           setActionRulesTitlesArray(orderedFinalTitles);
  //           actionsRulesTitles = orderedFinalTitles;
  //           if (localStorage.editCamerActiveTab === "seven") {
  //             setBackdropLoadingScreenOn(true);
  //             setActiveTab(
  //               <AxisTab
  //                 recipientsConfiguredData={recipientData}
  //                 titlesArray={recipientTitlesArray}
  //                 navigate={navigate}
  //                 setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
  //                 setRecipientsConfiguredData={setRecipientsConfiguredData}
  //                 setTitlesArray={setTitlesArray}
  //                 actionRulesData={response.data}
  //                 setActionRulesData={setActionRulesData}
  //                 actionRulesTitlesArray={orderedFinalTitles}
  //                 setActionRulesTitlesArray={setActionRulesTitlesArray}
  //                 setActiveTab={setActiveTab}
  //                 applicationsConfiguredData={applicationsConfiguredData}
  //                 cameraGeneralInfo={cameraGeneralInfo}
  //                 axisFinished={{
  //                   recipient: true,
  //                   actionRules: true,
  //                   application: false,
  //                 }}
  //               />
  //             );
  //             setBackdropLoadingScreenOn(false);
  //           }
  //         }
  //       },
  //       (reason: AxiosError) => {
  //         getActionsRulesFail = true;
  //         universalAPIErrorHandler(reason, navigate, retryFunction, () => {
  //           setAxisFinished((previousState: any) => {
  //             return { ...previousState, actionRules: true };
  //           });
  //           if (localStorage.editCamerActiveTab === "seven") {
  //             actionsRulesTitles = [
  //               "Name",
  //               "Host",
  //               "Port",
  //               "Email From",
  //               "Email To",
  //               "Message",
  //               "Enabled",
  //               "Profile",
  //             ];
  //             setBackdropLoadingScreenOn(true);
  //             setActiveTab(
  //               <AxisTab
  //                 recipientsConfiguredData={recipientData}
  //                 titlesArray={recipientTitlesArray}
  //                 navigate={navigate}
  //                 setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
  //                 setRecipientsConfiguredData={setRecipientsConfiguredData}
  //                 setTitlesArray={setTitlesArray}
  //                 actionRulesData={[]}
  //                 setActionRulesData={setActionRulesData}
  //                 actionRulesTitlesArray={[
  //                   "Name",
  //                   "Host",
  //                   "Port",
  //                   "Email From",
  //                   "Email To",
  //                   "Message",
  //                   "Enabled",
  //                   "Profile",
  //                 ]}
  //                 setActionRulesTitlesArray={setActionRulesTitlesArray}
  //                 setActiveTab={setActiveTab}
  //                 applicationsConfiguredData={applicationsConfiguredData}
  //                 cameraGeneralInfo={cameraGeneralInfo}
  //                 axisFinished={{
  //                   recipient: true,
  //                   actionRules: true,
  //                   application: false,
  //                 }}
  //               />
  //             );
  //             setBackdropLoadingScreenOn(false);
  //           }
  //         });
  //       }
  //     ));

  //   !getRecipientFail &&
  //     !getActionsRulesFail &&
  //     (await AxiosUI.get(`/axis/${inputId}/application/`).then(
  //       (response: AxiosResponse) => {
  //         if (response.data[0].level_tag) {
  //           if (response.data[0].message) {
  //             if (response.data[0].message.includes("Error retrieving")) {
  //               alert("Error retrieving applications");
  //             }
  //           }
  //           setAxisFinished((previousState: any) => {
  //             return { ...previousState, application: true };
  //           });
  //           if (localStorage.editCamerActiveTab === "seven") {
  //             setBackdropLoadingScreenOn(true);
  //             setActiveTab(
  //               <AxisTab
  //                 recipientsConfiguredData={recipientData}
  //                 titlesArray={recipientTitlesArray}
  //                 navigate={navigate}
  //                 setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
  //                 setRecipientsConfiguredData={setRecipientsConfiguredData}
  //                 setTitlesArray={setTitlesArray}
  //                 actionRulesData={newActionRulesData}
  //                 setActionRulesData={setActionRulesData}
  //                 actionRulesTitlesArray={actionsRulesTitles}
  //                 setActionRulesTitlesArray={setActionRulesTitlesArray}
  //                 setActiveTab={setActiveTab}
  //                 applicationsConfiguredData={[]}
  //                 cameraGeneralInfo={cameraGeneralInfo}
  //                 axisFinished={{
  //                   recipient: true,
  //                   actionRules: true,
  //                   application: true,
  //                 }}
  //               />
  //             );
  //             setBackdropLoadingScreenOn(false);
  //           }
  //         } else {
  //           setApplicationsConfiguredData(response.data);
  //           setAxisFinished((previousState: any) => {
  //             return { ...previousState, application: true };
  //           });
  //           if (localStorage.editCamerActiveTab === "seven") {
  //             setBackdropLoadingScreenOn(true);
  //             setActiveTab(
  //               <AxisTab
  //                 recipientsConfiguredData={recipientData}
  //                 titlesArray={recipientTitlesArray}
  //                 navigate={navigate}
  //                 setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
  //                 setRecipientsConfiguredData={setRecipientsConfiguredData}
  //                 setTitlesArray={setTitlesArray}
  //                 actionRulesData={newActionRulesData}
  //                 setActionRulesData={setActionRulesData}
  //                 actionRulesTitlesArray={actionsRulesTitles}
  //                 setActionRulesTitlesArray={setActionRulesTitlesArray}
  //                 setActiveTab={setActiveTab}
  //                 applicationsConfiguredData={response.data}
  //                 cameraGeneralInfo={cameraGeneralInfo}
  //                 axisFinished={{
  //                   recipient: true,
  //                   actionRules: true,
  //                   application: true,
  //                 }}
  //               />
  //             );
  //             setBackdropLoadingScreenOn(false);
  //           }
  //         }
  //       },
  //       (reason: AxiosError) => {
  //         universalAPIErrorHandler(reason, navigate, retryFunction, () => {
  //           console.log(`${reason}`);
  //           setAxisFinished((previousState: any) => {
  //             return { ...previousState, application: true };
  //           });
  //           if (localStorage.editCamerActiveTab === "seven") {
  //             setBackdropLoadingScreenOn(true);
  //             setActiveTab(
  //               <AxisTab
  //                 recipientsConfiguredData={recipientData}
  //                 titlesArray={recipientTitlesArray}
  //                 navigate={navigate}
  //                 setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
  //                 setRecipientsConfiguredData={setRecipientsConfiguredData}
  //                 setTitlesArray={setTitlesArray}
  //                 actionRulesData={newActionRulesData}
  //                 setActionRulesData={setActionRulesData}
  //                 actionRulesTitlesArray={actionsRulesTitles}
  //                 setActionRulesTitlesArray={setActionRulesTitlesArray}
  //                 setActiveTab={setActiveTab}
  //                 applicationsConfiguredData={[]}
  //                 cameraGeneralInfo={cameraGeneralInfo}
  //                 axisFinished={{
  //                   recipient: true,
  //                   actionRules: true,
  //                   application: true,
  //                 }}
  //               />
  //             );
  //             setBackdropLoadingScreenOn(false);
  //           }
  //         });
  //       }
  //     ));
};
