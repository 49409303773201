import React from "react";
import { SiteDropDownAILink } from "./SiteDropDownAILink";
import { CameraDropDownAILink } from "./CameraDropDownAILink";
import { StartDateDropDownAILink } from "./StartDateDropDownAILink";
import { EndDateDropDownAILink } from "./EndDateDropDownAILink";
import { ClipResultDropDown } from "./ClipResultDropDown";
import { GroundTruthDropDown } from "./GroundTruthDropDown";
import { ApplyFiltersButton } from "./ApplyFiltersButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { UserDropDownAILink } from "./UserDropDownAILink";
import { StartTimeDropDownAILink } from "./StartTimeDropDownAILink";
import { EndTimeDropDownAILink } from "./EndTimeDropDownAILink";

export const AILinkFilters = ({
  siteOptions,
  loadingSites,
  siteDropDownValue,
  setSiteDropDownValue,
  setCameraDropDownValue,
  setUserFilters,
  setCameraOptions,
  setLoadingCameras,
  cameraOptions,
  cameraDropDownValue,
  loadingCameras,
  setInvestigationData,
  setPageLoading,
  setSelectedRow,
  setPrecisionRecallData,
  setVideoURL,
  setVideoClipLoading,
  setClipsIndexOrder,
  setVerifiedClicked,
  setUnverifiedClicked,
  selectedRow,
  setNoDataToDisplay,
  setSiteOptions,
  setLoadingSites,
  userFilters,
  setFetchingFilteredData,
  setPage,
  showUserDropDown,
  userDropDownValue,
  userOptions,
  setUserDropDownValue,
  groupID,
  setGroupID,
  setAiLinkClipsTotal,
  setLoadingAdditionalData,
  navBarCollapsed,
  userInfo,
  setFiltersApplied,
  timeFiltersDisabledAILink,
  setTimeFiltersDisabledAILink,
  navigate,
}: {
  siteOptions: any;
  loadingSites: boolean;
  siteDropDownValue: string;
  setSiteDropDownValue: Dispatcher<string>;
  setCameraDropDownValue: Dispatcher<string>;
  setUserFilters: any;
  setCameraOptions: any;
  setLoadingCameras: Dispatcher<boolean>;
  cameraDropDownValue: any;
  cameraOptions: any;
  loadingCameras: boolean;
  userFilters: any;
  setInvestigationData: any;
  setPageLoading: Dispatcher<boolean>;
  setSelectedRow: any;
  setPrecisionRecallData: any;
  setVideoURL: any;
  setVideoClipLoading: Dispatcher<boolean>;
  setClipsIndexOrder: any;
  setVerifiedClicked: Dispatcher<boolean>;
  setUnverifiedClicked: Dispatcher<boolean>;
  selectedRow: any;
  setNoDataToDisplay: Dispatcher<boolean>;
  setSiteOptions: any;
  setLoadingSites: any;
  setFetchingFilteredData: Dispatcher<boolean>;
  setPage: any;
  showUserDropDown: boolean;
  userDropDownValue: any;
  userOptions: any;
  setUserDropDownValue: any;
  groupID: any;
  setGroupID: any;
  setAiLinkClipsTotal: any;
  setLoadingAdditionalData: Dispatcher<boolean>;
  navBarCollapsed: boolean;
  userInfo: any;
  setFiltersApplied: Dispatcher<boolean>;
  timeFiltersDisabledAILink: boolean;
  setTimeFiltersDisabledAILink: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  let noUserDropDownCSS = showUserDropDown
    ? [
        "absolute top-0 left-[125px]",
        "absolute top-0 left-[250px]",
        "absolute top-0 left-[375px]",
        "absolute top-0 left-[530px]",
        "absolute top-0 left-[685px]",
        "absolute top-0 left-[822px]",
        "absolute top-[8px] left-[1215px]",
        "absolute top-0",
        "absolute top-0 left-[959px]",
        "absolute top-0 left-[1084px]",
      ]
    : [
        "absolute top-0",
        "absolute top-0 left-[165px]",
        "absolute top-0 left-[330px]",
        "absolute top-0 left-[485px]",
        "absolute top-0 left-[640px]",
        "absolute top-0 left-[780px]",
        "absolute top-[8px] left-[1215px]",
        "absolute top-0",
        "absolute top-0 left-[920px]",
        "absolute top-0 left-[1055px]",
      ];

  if (showUserDropDown && navBarCollapsed) {
    noUserDropDownCSS = [
      "absolute top-0 left-[145px]",
      "absolute top-0 left-[290px]",
      "absolute top-0 left-[435px]",
      "absolute top-0 left-[590px]",
      "absolute top-0 left-[745px]",
      "absolute top-0 left-[882px]",
      "absolute top-[8px] left-[1290px]",
      "absolute top-0",
      "absolute top-[0px] left-[1020px]",
      "absolute top-[0px] left-[1145px]",
    ];
  }

  if (!showUserDropDown && navBarCollapsed) {
    noUserDropDownCSS = [
      "absolute top-0",
      "absolute top-0 left-[190px]",
      "absolute top-0 left-[380px]",
      "absolute top-0 left-[540px]",
      "absolute top-0 left-[700px]",
      "absolute top-0 left-[845px]",
      "absolute top-[8px] left-[1290px]",
      "absolute top-0",
      "absolute top-[0px] left-[990px]",
      "absolute top-[0px] left-[1130px]",
    ];
  }

  return (
    <div
      className={
        userInfo.permission.sentinel && userInfo.permission.ailink
          ? "absolute top-[60px] left-0"
          : "absolute top-[0px] left-0"
      }
    >
      {showUserDropDown && (
        <div className={noUserDropDownCSS[7]}>
          <UserDropDownAILink
            userDropDownValue={userDropDownValue}
            userOptions={userOptions}
            setUserDropDownValue={setUserDropDownValue}
            setCameraDropDownValue={setCameraDropDownValue}
            setUserFilters={setUserFilters}
            setSiteDropDownValue={setSiteDropDownValue}
            setSiteOptions={setSiteOptions}
            setCameraOptions={setCameraOptions}
            setLoadingSites={setLoadingSites}
            groupID={groupID}
            setGroupID={setGroupID}
            navBarCollapsed={navBarCollapsed}
            navigate={navigate}
          />
        </div>
      )}
      <div className={noUserDropDownCSS[0]}>
        <SiteDropDownAILink
          siteOptions={siteOptions}
          loadingSites={loadingSites}
          siteDropDownValue={siteDropDownValue}
          setSiteDropDownValue={setSiteDropDownValue}
          setCameraDropDownValue={setCameraDropDownValue}
          setUserFilters={setUserFilters}
          setCameraOptions={setCameraOptions}
          setLoadingCameras={setLoadingCameras}
          showUserDropDown={showUserDropDown}
          navBarCollapsed={navBarCollapsed}
          navigate={navigate}
        />
      </div>
      <div className={noUserDropDownCSS[1]}>
        <CameraDropDownAILink
          setUserFilters={setUserFilters}
          cameraOptions={cameraOptions}
          cameraDropDownValue={cameraDropDownValue}
          setCameraDropDownValue={setCameraDropDownValue}
          siteOptions={siteOptions}
          loadingCameras={loadingCameras}
          showUserDropDown={showUserDropDown}
          navBarCollapsed={navBarCollapsed}
        />
      </div>
      <div className={noUserDropDownCSS[2]}>
        <StartDateDropDownAILink
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          setTimeFiltersDisabledAILink={setTimeFiltersDisabledAILink}
        />
      </div>
      <div className={noUserDropDownCSS[3]}>
        <EndDateDropDownAILink
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          setTimeFiltersDisabledAILink={setTimeFiltersDisabledAILink}
        />
      </div>

      <div className={noUserDropDownCSS[4]}>
        {/* <ClipResultDropDown
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          showUserDropDown={showUserDropDown}
        /> */}
        <StartTimeDropDownAILink
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          showUserDropDown={showUserDropDown}
          timeFiltersDisabledAILink={timeFiltersDisabledAILink}
        />
      </div>
      <div className={noUserDropDownCSS[5]}>
        {/* <GroundTruthDropDown
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          showUserDropDown={showUserDropDown}
        /> */}
        <EndTimeDropDownAILink
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          showUserDropDown={showUserDropDown}
          timeFiltersDisabledAILink={timeFiltersDisabledAILink}
        />
      </div>
      <div className={noUserDropDownCSS[8]}>
        <ClipResultDropDown
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          showUserDropDown={showUserDropDown}
        />
      </div>
      <div className={noUserDropDownCSS[9]}>
        <GroundTruthDropDown
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          showUserDropDown={showUserDropDown}
          navBarCollapsed={navBarCollapsed}
        />
      </div>
      <div className={noUserDropDownCSS[6]}>
        <ApplyFiltersButton
          setInvestigationData={setInvestigationData}
          setPageLoading={setPageLoading}
          setSelectedRow={setSelectedRow}
          setPrecisionRecallData={setPrecisionRecallData}
          setVideoURL={setVideoURL}
          setVideoClipLoading={setVideoClipLoading}
          setClipsIndexOrder={setClipsIndexOrder}
          setVerifiedClicked={setVerifiedClicked}
          setUnverifiedClicked={setUnverifiedClicked}
          selectedRow={selectedRow}
          setNoDataToDisplay={setNoDataToDisplay}
          setSiteOptions={setSiteOptions}
          setLoadingSites={setLoadingSites}
          userFilters={userFilters}
          setFetchingFilteredData={setFetchingFilteredData}
          setPage={setPage}
          setAiLinkClipsTotal={setAiLinkClipsTotal}
          setLoadingAdditionalData={setLoadingAdditionalData}
          setFiltersApplied={setFiltersApplied}
          navigate={navigate}
        />
      </div>
    </div>
  );
};
