import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleSubmitNewPatriotAlert } from "./EditCameraUtils/handleSubmitNewPatriotAlert";

export const AddPatriotAlertDialog = ({
  setAddPatriotAlertDialogOpen,
  addPatriotAlertDialogOpen,
  patriotAlerts,
  setBackdropLoadingScreenOn,
  setCameraGeneralInfo,
  setCameraGeneralInfoBackup,
  setGeneralInfoChangesMade,
  setPatchObject,
  setPatriotAlerts,
  setActiveTab,
  cameraGeneralInfoBackup,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  cameraGeneralInfo,
}: {
  setAddPatriotAlertDialogOpen: Dispatcher<boolean>;
  addPatriotAlertDialogOpen: boolean;
  patriotAlerts: any;
  setBackdropLoadingScreenOn: any;
  setCameraGeneralInfo: any;
  setCameraGeneralInfoBackup: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setPatriotAlerts: any;
  setActiveTab: any;
  cameraGeneralInfoBackup: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  cameraGeneralInfo: any;
}) => {
  const navigate = useNavigate();

  const [postObject, setPostObject] = React.useState({
    id: -1,
    patriot_server: "",
    patriot_client_no: "",
    patriot_username: "",
    patriot_password: "",
    patriot_zone_number: "",
  });

  React.useEffect(() => {
    if (patriotAlerts[0]) {
      setPostObject({
        id: -1,
        patriot_server: patriotAlerts[0].patriot_server,
        patriot_client_no: patriotAlerts[0].patriot_client_no,
        patriot_username: patriotAlerts[0].patriot_username,
        patriot_password: patriotAlerts[0].patriot_password,
        patriot_zone_number: patriotAlerts[0].patriot_zone_number,
      });
    }
  }, []);

  return (
    <Dialog open={addPatriotAlertDialogOpen}>
      <DialogTitle id="alert-dialog-title">Add Patriot Alert</DialogTitle>
      <DialogContent>
        <TextField
          id="patriot_server"
          variant="standard"
          label="Patriot Server"
          defaultValue={patriotAlerts[0] ? patriotAlerts[0].patriot_server : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, patriot_server: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="patriot_client_no"
          variant="standard"
          label="Patriot client no"
          defaultValue={
            patriotAlerts[0] ? patriotAlerts[0].patriot_client_no : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                patriot_client_no: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="patriot_username"
          variant="standard"
          label="Patriot username"
          defaultValue={
            patriotAlerts[0] ? patriotAlerts[0].patriot_username : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, patriot_username: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="patriot_password"
          variant="standard"
          label="Patriot password"
          defaultValue={
            patriotAlerts[0] ? patriotAlerts[0].patriot_password : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, patriot_password: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="patriot_zone_number"
          variant="standard"
          label="Patriot zone number"
          defaultValue={
            patriotAlerts[0] ? patriotAlerts[0].patriot_zone_number : ""
          }
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                patriot_zone_number: event.target.value,
              };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddPatriotAlertDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setAddPatriotAlertDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleSubmitNewPatriotAlert(
              cameraGeneralInfo,
              patriotAlerts,
              postObject,
              navigate,
              setBackdropLoadingScreenOn,
              setCameraGeneralInfo,
              setCameraGeneralInfoBackup,
              setGeneralInfoChangesMade,
              setPatchObject,
              setPatriotAlerts,
              setActiveTab,
              setAddPatriotAlertDialogOpen,
              cameraGeneralInfoBackup,
              setIgnoreZonesBreadCrumbs,
              handleSitesBreadcrumbClick,
              handleSingularSiteBreadcrumbClick
            );
          }}
          text="Submit"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
