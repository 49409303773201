import React from "react";
import { NavBar } from "../../common/NavBar";
import { InvestigationPageTabs } from "./InvestigationPageTabs";
import { AILinkFilters } from "./AILinkFilters";
import { AILinkTab } from "./AILinkTab";
import { useNavigate } from "react-router-dom";
import { handleArrowButtonClick } from "./InvestigationUtilities/handleArrowButtonClick";
import { SentinelFilters } from "./SentinelFilters";
import { getAllSitesInvestigations } from "./InvestigationUtilities/getAllSitesInvestigations";
import { SentinelTab } from "./SentinelTab";
import SentinelBackdropLoadingScreen from "../../common/SentinelBackDropLoadingScreen";
import AiLinkBackdropLoadingScreen from "../../common/AiLinkBackDropLoadingScreen";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const Investigation = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [investigationData, setInvestigationData] = React.useState<any[]>([]);

  const [sentinelData, setSentinelData] = React.useState<any[]>([]);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = React.useState<any>("");

  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const [selectedRowSentinel, setSelectedRowSentinel] = React.useState<any>(
    sentinelData[0]
  );

  const [page, setPage] = React.useState(0);

  const [pageSentinel, setPageSentinel] = React.useState(0);

  const [showMoreDialogOpen, setShowMoreDialogOpen] = React.useState(false);

  const [showMoreDialogOpenSentinel, setShowMoreDialogOpenSentinel] =
    React.useState(false);

  const [pageLoading, setPageLoading] = React.useState(false);

  const [pageLoadingSentinel, setPageLoadingSentinel] = React.useState(false);

  const [videoURL, setVideoURL] = React.useState("");

  const [videoURLSentinel, setVideoURLSentinel] = React.useState("");

  const [precisionRecallData, setPrecisionRecallData] = React.useState({
    date: "",
    date_str: "",
    true_positives: 0,
    false_positives: 0,
    false_negatives: 0,
    true_negatives: 0,
    unclassified: 0,
    total_clips: 0,
    precision: null,
    recall: null,
  });

  const [precisionRecallDataSentinel, setPrecisionRecallDataSentinel] =
    React.useState({});

  const [videoClipLoading, setVideoClipLoading] = React.useState(true);

  const [videoClipLoadingSentinel, setVideoClipLoadingSentinel] =
    React.useState(true);

  const [clipsIndexOrder, setClipsIndexOrder] = React.useState([]);

  const [clipsIndexOrderSentinel, setClipsIndexOrderSentinel] = React.useState(
    []
  );

  const [verifiedClicked, setVerifiedClicked] = React.useState(true);

  const [unverifiedClicked, setUnverifiedClicked] = React.useState(true);

  const [noDataToDisplay, setNoDataToDisplay] = React.useState(true);

  const [noDataToDisplaySentinel, setNoDataToDisplaySentinel] =
    React.useState(true);

  const [siteOptions, setSiteOptions] = React.useState<any[]>([]);

  const [loadingSites, setLoadingSites] = React.useState(false);

  const startDate = new Date();
  // startDate.setDate(startDate.getDate() - 2);

  const endDate = new Date();

  const [userFilters, setUserFilters] = React.useState({
    user: "",
    start_date: startDate,
    end_date: endDate,
    start_time: null,
    end_time: null,
    ground_truth: "",
    clip_result: "",
    site_ids: [],
    camera_ids: [],
  });

  const [siteDropDownValue, setSiteDropDownValue] = React.useState<string>("");

  const [cameraDropDownValue, setCameraDropDownValue] =
    React.useState<string>("");

  const [cameraOptions, setCameraOptions] = React.useState<any[]>([]);

  const [loadingCameras, setLoadingCameras] = React.useState(false);

  const [fetchingFilteredData, setFetchingFilteredData] = React.useState(false);

  const [fetchingFilteredDataSentinel, setFetchingFilteredDataSentinel] =
    React.useState(false);

  const userInfo = JSON.parse(localStorage.userInfo);

  const showUserDropDown = userInfo.permission.ailink_index;

  const showUserDropDownSentinel = userInfo.permission.ailink_index;

  const [userDropDownValue, setUserDropDownValue] = React.useState("");

  const [groupID, setGroupID] = React.useState(767);

  const aiLinkUsers = JSON.parse(localStorage.aiLinkUsers);

  const sentinelUsers = JSON.parse(localStorage.sentinelUsers);

  const [userDropDownValueSentinel, setUserDropDownValueSentinel] =
    React.useState("");

  const [cameraDropDownValueSentinel, setCameraDropDownValueSentinel] =
    React.useState<string>("");

  const [userFiltersSentinel, setUserFiltersSentinel] = React.useState({
    user: "sentinel",
    start_date: startDate,
    end_date: endDate,
    start_time: null,
    end_time: null,
    site_ids: [],
    camera_ids: [],
  });

  const [siteDropDownValueSentinel, setSiteDropDownValueSentinel] =
    React.useState("");

  const [siteOptionsSentinel, setSiteOptionsSentinel] = React.useState<any[]>(
    []
  );

  const [loadingCamerasSentinel, setLoadingCamerasSentinel] =
    React.useState(false);

  const [cameraOptionsSentinel, setCameraOptionsSentinel] = React.useState<
    any[]
  >([]);

  const [loadingSitesSentinel, setLoadingSitesSentinel] = React.useState(false);

  const [aiLinkClipsTotal, setAiLinkClipsTotal] = React.useState(0);

  const [loadingAdditionalData, setLoadingAdditionalData] =
    React.useState(true);

  const [loadingAdditionalDataSentinel, setLoadingAdditionalDataSentinel] =
    React.useState(true);

  const forwardClick = () => {
    handleArrowButtonClick(
      investigationData,
      selectedRow,
      clipsIndexOrder,
      "forward",
      setSelectedRow,
      setInvestigationData,
      setVideoClipLoading,
      setVerifiedClicked,
      setUnverifiedClicked,
      setVideoURL,
      userFilters,
      navigate
    );
  };

  const backClick = () => {
    handleArrowButtonClick(
      investigationData,
      selectedRow,
      clipsIndexOrder,
      "back",
      setSelectedRow,
      setInvestigationData,
      setVideoClipLoading,
      setVerifiedClicked,
      setUnverifiedClicked,
      setVideoURL,
      userFilters,
      navigate
    );
  };

  const handlers = {
    FORWARD_CLICK: forwardClick,
    BACK_CLICK: backClick,
  };

  const keyMap = {
    FORWARD_CLICK: "right",
    BACK_CLICK: "left",
  };

  const [clickedTab, setClickedTab] = React.useState("");

  const [filtersApplied, setFiltersApplied] = React.useState(false);

  const [timeFiltersDisabledAILink, setTimeFiltersDisabledAILink] =
    React.useState(false);

  const [timeFiltersDisabledSentinel, setTimeFiltersDisabledSentinel] =
    React.useState(false);

  React.useEffect(() => {
    const userInfo = JSON.parse(localStorage.userInfo);

    if (
      showUserDropDown &&
      userInfo.permission.ailink &&
      !userInfo.permission.sentinel
    ) {
      setActiveTab("ailink");
      setClickedTab("one");
      setPageLoading(false);
    }

    if (
      showUserDropDown &&
      userInfo.permission.ailink &&
      userInfo.permission.sentinel
    ) {
      setActiveTab("ailink");
      setClickedTab("one");
      setPageLoading(false);
      setPageLoadingSentinel(false);
    }

    if (
      showUserDropDown &&
      !userInfo.permission.ailink &&
      userInfo.permission.sentinel
    ) {
      setActiveTab("sentinel");
      setClickedTab("two");
      setPageLoadingSentinel(false);
    }

    const aiLinkUsers = JSON.parse(localStorage.aiLinkUsers);

    const sentinelUsers = JSON.parse(localStorage.sentinelUsers);

    if (!showUserDropDown && aiLinkUsers[0]) {
      // setFetchingFilteredData(true);
      setActiveTab("ailink");
      setClickedTab("one");
      setUserFilters((previousState: any) => {
        return { ...previousState, user: aiLinkUsers[0].username };
      });
      getAllSitesInvestigations(
        setSiteOptions,
        setLoadingSites,
        aiLinkUsers[0].group.id,
        navigate
      );
    }

    if (!showUserDropDown && sentinelUsers[0]) {
      // setFetchingFilteredDataSentinel(true);
      setActiveTab("sentinel");
      setClickedTab("two");
      setUserFiltersSentinel((previousState: any) => {
        return { ...previousState, user: sentinelUsers[0].username };
      });
      getAllSitesInvestigations(
        setSiteOptionsSentinel,
        setLoadingSitesSentinel,
        sentinelUsers[0].group.id,
        navigate
      );
    }
  }, []);

  React.useEffect(() => {
    setAiLinkClipsTotal(investigationData.length);
  }, [investigationData]);

  const ref = React.useRef<HTMLDivElement>(null);

  const setScrollTop = (event: any) => {
    localStorage.setItem("investigationsScrollTop", event.target.scrollTop);
  };

  React.useEffect(() => {
    if (ref) {
      if (ref.current !== null) {
        ref.current.scrollTop = localStorage.investigationsScrollTop;
      }
    }
  }, [investigationData]);

  const scrollToTop = () => {
    if (ref) {
      if (ref.current !== null) {
        ref.current.scrollTop = 62;
      }
    }
  };

  React.useEffect(() => {
    const userInfo = JSON.parse(localStorage.userInfo);
    if (!userInfo.permission.ailink || !userInfo.permission.sentinel) {
      scrollToTop();
    }
  }, []);

  return (
    // <HotKeys handlers={handlers} keyMap={keyMap}>
    <div>
      {/* <BackdropLoadingScreen openState={pageLoadingSentinel} /> */}
      <AiLinkBackdropLoadingScreen
        openState={fetchingFilteredData}
        aiLinkClipsTotal={aiLinkClipsTotal}
      />
      {/* <BackdropLoadingScreen openState={fetchingFilteredDataSentinel} /> */}
      <SentinelBackdropLoadingScreen openState={fetchingFilteredDataSentinel} />
      <div
        className="bg-actuate-grey absolute w-full h-full overflow-scroll"
        onScroll={setScrollTop}
        ref={ref}
      >
        <div
          className={
            !navBarCollapsed
              ? "absolute top-0 left-[170px] right-[1px] z-10"
              : "absolute top-0 left-[95px] right-[1px] z-10"
          }
        >
          <InvestigationPageTabs
            setActiveTab={setActiveTab}
            clickedTab={clickedTab}
            setClickedTab={setClickedTab}
          />
        </div>
        {!pageLoadingSentinel && activeTab === "sentinel" && (
          <div>
            <div
              className={
                userInfo.permission.sentinel && userInfo.permission.ailink
                  ? `bg-white absolute h-[152px] left-[0px] right-0 min-w-[1350px]`
                  : `bg-white absolute h-[92px] left-[0px] right-0 min-w-[1350px]`
              }
            >
              <div>
                <div
                  className={
                    !navBarCollapsed
                      ? "font-mulish absolute left-[160px] top-[15px]"
                      : "font-mulish absolute left-[85px] top-[15px]"
                  }
                >
                  <SentinelFilters
                    showUserDropDownSentinel={showUserDropDownSentinel}
                    userDropDownValueSentinel={userDropDownValueSentinel}
                    userOptions={sentinelUsers}
                    setUserDropDownValueSentinel={setUserDropDownValueSentinel}
                    setCameraDropDownValueSentinel={
                      setCameraDropDownValueSentinel
                    }
                    setUserFiltersSentinel={setUserFiltersSentinel}
                    setSiteDropDownValueSentinel={setSiteDropDownValueSentinel}
                    setSiteOptionsSentinel={setSiteOptionsSentinel}
                    setCameraOptionsSentinel={setCameraOptionsSentinel}
                    setLoadingSitesSentinel={setLoadingSitesSentinel}
                    siteOptionsSentinel={siteOptionsSentinel}
                    loadingSitesSentinel={loadingSitesSentinel}
                    siteDropDownValueSentinel={siteDropDownValueSentinel}
                    setLoadingCamerasSentinel={setLoadingCamerasSentinel}
                    cameraOptionsSentinel={cameraOptionsSentinel}
                    cameraDropDownValueSentinel={cameraDropDownValueSentinel}
                    loadingCamerasSentinel={loadingCamerasSentinel}
                    userFiltersSentinel={userFiltersSentinel}
                    setSentinelData={setSentinelData}
                    setPageLoadingSentinel={setPageLoadingSentinel}
                    setSelectedRowSentinel={setSelectedRowSentinel}
                    setPrecisionRecallDataSentinel={
                      setPrecisionRecallDataSentinel
                    }
                    setVideoURLSentinel={setVideoURLSentinel}
                    setVideoClipLoadingSentinel={setVideoClipLoadingSentinel}
                    setClipsIndexOrderSentinel={setClipsIndexOrderSentinel}
                    selectedRowSentinel={selectedRowSentinel}
                    setNoDataToDisplaySentinel={setNoDataToDisplaySentinel}
                    setFetchingFilteredDataSentinel={
                      setFetchingFilteredDataSentinel
                    }
                    setPageSentinel={setPageSentinel}
                    setLoadingAdditionalData={setLoadingAdditionalDataSentinel}
                    navBarCollapsed={navBarCollapsed}
                    setFiltersApplied={setFiltersApplied}
                    timeFiltersDisabledSentinel={timeFiltersDisabledSentinel}
                    setTimeFiltersDisabledSentinel={
                      setTimeFiltersDisabledSentinel
                    }
                    userInfo={userInfo}
                    navigate={navigate}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                {!noDataToDisplaySentinel && filtersApplied && (
                  <div>
                    <SentinelTab
                      showMoreDialogOpenSentinel={showMoreDialogOpenSentinel}
                      setShowMoreDialogOpenSentinel={
                        setShowMoreDialogOpenSentinel
                      }
                      selectedRowSentinel={selectedRowSentinel}
                      videoURLSentinel={videoURLSentinel}
                      sentinelData={sentinelData}
                      pageSentinel={pageSentinel}
                      setPageSentinel={setPageSentinel}
                      precisionRecallDataSentinel={precisionRecallDataSentinel}
                      videoClipLoadingSentinel={videoClipLoadingSentinel}
                      setVideoURLSentinel={setVideoURLSentinel}
                      setVideoClipLoadingSentinel={setVideoClipLoadingSentinel}
                      setSelectedRowSentinel={setSelectedRowSentinel}
                      setSentinelData={setSentinelData}
                      scrollToTop={scrollToTop}
                      setPageLoadingSentinel={setPageLoadingSentinel}
                      clipsIndexOrderSentinel={clipsIndexOrderSentinel}
                      setClipsIndexOrderSentinel={setClipsIndexOrderSentinel}
                      fetchingFilteredDataSentinel={
                        fetchingFilteredDataSentinel
                      }
                      userFiltersSentinel={userFiltersSentinel}
                      loadingAdditionalData={loadingAdditionalDataSentinel}
                      navBarCollapsed={navBarCollapsed}
                      userInfo={userInfo}
                      navigate={navigate}
                    />
                  </div>
                )}
                {noDataToDisplaySentinel &&
                  filtersApplied &&
                  !fetchingFilteredDataSentinel && (
                    <div className="relative top-[200px] text-center">
                      <p className="font-mulish text-[22px]">
                        No Clips to Display.
                      </p>
                    </div>
                  )}
                {!filtersApplied && (
                  <div className="relative top-[200px] text-center">
                    <p className="font-mulish text-[22px]">
                      Please Apply Filters to View Clips.
                    </p>
                    <p className="font-mulish text-[18px]">
                      To view clips for all sites, leave the Site and Camera
                      filters empty.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {!pageLoading && activeTab === "ailink" && (
          <div>
            <div
              className={
                userInfo.permission.sentinel && userInfo.permission.ailink
                  ? `bg-white absolute h-[152px] left-[0px] right-0 min-w-[1350px]`
                  : `bg-white absolute h-[92px] left-[0px] right-0 min-w-[1350px]`
              }
            >
              <div>
                <div
                  className={
                    !navBarCollapsed
                      ? "font-mulish absolute left-[160px] top-[15px]"
                      : "font-mulish absolute left-[85px] top-[15px]"
                  }
                >
                  <AILinkFilters
                    siteOptions={siteOptions}
                    loadingSites={loadingSites}
                    siteDropDownValue={siteDropDownValue}
                    setSiteDropDownValue={setSiteDropDownValue}
                    setCameraDropDownValue={setCameraDropDownValue}
                    setUserFilters={setUserFilters}
                    setCameraOptions={setCameraOptions}
                    setLoadingCameras={setLoadingCameras}
                    cameraDropDownValue={cameraDropDownValue}
                    cameraOptions={cameraOptions}
                    loadingCameras={loadingCameras}
                    userFilters={userFilters}
                    setInvestigationData={setInvestigationData}
                    setPageLoading={setPageLoading}
                    setSelectedRow={setSelectedRow}
                    setPrecisionRecallData={setPrecisionRecallData}
                    setVideoURL={setVideoURL}
                    setVideoClipLoading={setVideoClipLoading}
                    setClipsIndexOrder={setClipsIndexOrder}
                    setVerifiedClicked={setVerifiedClicked}
                    setUnverifiedClicked={setUnverifiedClicked}
                    selectedRow={selectedRow}
                    setNoDataToDisplay={setNoDataToDisplay}
                    setSiteOptions={setSiteOptions}
                    setLoadingSites={setLoadingSites}
                    setFetchingFilteredData={setFetchingFilteredData}
                    setPage={setPage}
                    showUserDropDown={showUserDropDown}
                    userDropDownValue={userDropDownValue}
                    userOptions={aiLinkUsers}
                    setUserDropDownValue={setUserDropDownValue}
                    groupID={groupID}
                    setGroupID={setGroupID}
                    setAiLinkClipsTotal={setAiLinkClipsTotal}
                    setLoadingAdditionalData={setLoadingAdditionalData}
                    navBarCollapsed={navBarCollapsed}
                    userInfo={userInfo}
                    setFiltersApplied={setFiltersApplied}
                    timeFiltersDisabledAILink={timeFiltersDisabledAILink}
                    setTimeFiltersDisabledAILink={setTimeFiltersDisabledAILink}
                    navigate={navigate}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                {!noDataToDisplay && (
                  <div>
                    <AILinkTab
                      showMoreDialogOpen={showMoreDialogOpen}
                      setShowMoreDialogOpen={setShowMoreDialogOpen}
                      selectedRow={selectedRow}
                      videoURL={videoURL}
                      investigationData={investigationData}
                      page={page}
                      setPage={setPage}
                      precisionRecallData={precisionRecallData}
                      videoClipLoading={videoClipLoading}
                      setVideoClipLoading={setVideoClipLoading}
                      setVideoURL={setVideoURL}
                      setSelectedRow={setSelectedRow}
                      setInvestigationData={setInvestigationData}
                      scrollToTop={scrollToTop}
                      verifiedClicked={verifiedClicked}
                      unverifiedClicked={unverifiedClicked}
                      setVerifiedClicked={setVerifiedClicked}
                      setUnverifiedClicked={setUnverifiedClicked}
                      setPageLoading={setPageLoading}
                      clipsIndexOrder={clipsIndexOrder}
                      setClipsIndexOrder={setClipsIndexOrder}
                      fetchingFilteredData={fetchingFilteredData}
                      userFilters={userFilters}
                      loadingAdditionalData={loadingAdditionalData}
                      navBarCollapsed={navBarCollapsed}
                      userInfo={userInfo}
                      navigate={navigate}
                    />
                  </div>
                )}
                {noDataToDisplay && filtersApplied && !fetchingFilteredData && (
                  <div className="relative top-[200px] text-center">
                    <p className="font-mulish text-[22px]">
                      No Clips to Display.
                    </p>
                  </div>
                )}
                {!filtersApplied && (
                  <div className="relative top-[200px] text-center">
                    <p className="font-mulish text-[22px]">
                      Please Apply Filters to View Clips.
                    </p>
                    <p className="font-mulish text-[18px]">
                      To view clips for all sites, leave the Site and Camera
                      filters empty.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
