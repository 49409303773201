import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const EndTimeDropDownAILink = ({
  userFilters,
  setUserFilters,
  showUserDropDown,
  timeFiltersDisabledAILink,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  showUserDropDown: boolean;
  timeFiltersDisabledAILink: boolean;
}) => {
  const sx = showUserDropDown ? { width: 132 } : { width: 135 };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        label="End Time"
        value={userFilters.end_time ? userFilters.end_time : null}
        onChange={(newValue) => {
          setUserFilters((previousState: any) => {
            return { ...previousState, end_time: newValue };
          });
        }}
        disabled={timeFiltersDisabledAILink}
        renderInput={(params) => (
          <TextField {...params} autoComplete="off" sx={sx} />
        )}
      />
    </LocalizationProvider>
  );
};
