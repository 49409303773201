import React from "react";
import { UserDropDownSentinel } from "./UserDropDownSentinel";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { SiteDropDownSentinel } from "./SiteDropDownSentinel";
import { CameraDropDownSentinel } from "./CameraDropDownSentinel";
import { StartDateDropDownSentinel } from "./StartDateDropDownSentinel";
import { EndDateDropDownSentinel } from "./EndDateDropDownSentinel";
import { ApplyFiltersButtonSentinel } from "./ApplyFiltersButtonSentinel";
import { StartTimeDropDownSentinel } from "./StartTimeDropDownSentinel";
import { EndTimeDropDownSentinel } from "./EndTimeDropDownSentinel";

export const SentinelFilters = ({
  showUserDropDownSentinel,
  userDropDownValueSentinel,
  userOptions,
  setUserDropDownValueSentinel,
  setCameraDropDownValueSentinel,
  setUserFiltersSentinel,
  setSiteDropDownValueSentinel,
  setSiteOptionsSentinel,
  setCameraOptionsSentinel,
  setLoadingSitesSentinel,
  siteOptionsSentinel,
  loadingSitesSentinel,
  siteDropDownValueSentinel,
  setLoadingCamerasSentinel,
  cameraOptionsSentinel,
  cameraDropDownValueSentinel,
  loadingCamerasSentinel,
  userFiltersSentinel,
  setSentinelData,
  setPageLoadingSentinel,
  setSelectedRowSentinel,
  setPrecisionRecallDataSentinel,
  setVideoURLSentinel,
  setVideoClipLoadingSentinel,
  setClipsIndexOrderSentinel,
  selectedRowSentinel,
  setNoDataToDisplaySentinel,
  setFetchingFilteredDataSentinel,
  setPageSentinel,
  setLoadingAdditionalData,
  navBarCollapsed,
  setFiltersApplied,
  userInfo,
  timeFiltersDisabledSentinel,
  setTimeFiltersDisabledSentinel,
  navigate,
}: {
  showUserDropDownSentinel: boolean;
  userDropDownValueSentinel: any;
  userOptions: any;
  setUserDropDownValueSentinel: any;
  setCameraDropDownValueSentinel: any;
  setUserFiltersSentinel: any;
  setSiteDropDownValueSentinel: any;
  setSiteOptionsSentinel: any;
  setCameraOptionsSentinel: any;
  setLoadingSitesSentinel: Dispatcher<boolean>;
  siteOptionsSentinel: any;
  loadingSitesSentinel: boolean;
  siteDropDownValueSentinel: string;
  setLoadingCamerasSentinel: Dispatcher<boolean>;
  cameraOptionsSentinel: any;
  cameraDropDownValueSentinel: any;
  loadingCamerasSentinel: boolean;
  userFiltersSentinel: any;
  setSentinelData: any;
  setPageLoadingSentinel: Dispatcher<boolean>;
  setSelectedRowSentinel: any;
  setPrecisionRecallDataSentinel: any;
  setVideoURLSentinel: any;
  setVideoClipLoadingSentinel: Dispatcher<boolean>;
  setClipsIndexOrderSentinel: any;
  selectedRowSentinel: any;
  setNoDataToDisplaySentinel: Dispatcher<boolean>;
  setFetchingFilteredDataSentinel: Dispatcher<boolean>;
  setPageSentinel: any;
  setLoadingAdditionalData: Dispatcher<boolean>;
  navBarCollapsed: boolean;
  setFiltersApplied: Dispatcher<boolean>;
  userInfo: any;
  timeFiltersDisabledSentinel: boolean;
  setTimeFiltersDisabledSentinel: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  let noUserDropDownCSS = showUserDropDownSentinel
    ? [
        "absolute top-0 left-[160px]",
        "absolute top-0 left-[320px]",
        "absolute top-0 left-[480px]",
        "absolute top-0 left-[640px]",
        "absolute top-0 left-[800px]",
        "absolute top-0 left-[960px]",
        "absolute top-[8px] left-[1130px]",
        "absolute top-0",
      ]
    : [
        "absolute top-0",
        "absolute top-0 left-[210px]",
        "absolute top-0 left-[420px]",
        "absolute top-0 left-[580px]",
        "absolute top-0 left-[740px]",
        "absolute top-0 left-[935px]",
        "absolute top-[8px] left-[1140px]",
      ];

  if (showUserDropDownSentinel && navBarCollapsed) {
    noUserDropDownCSS = [
      "absolute top-0 left-[185px]",
      "absolute top-0 left-[370px]",
      "absolute top-0 left-[555px]",
      "absolute top-0 left-[715px]",
      "absolute top-0 left-[875px]",
      "absolute top-0 left-[1035px]",
      "absolute top-[8px] left-[1205px]",
      "absolute top-0",
    ];
  }

  if (!showUserDropDownSentinel && navBarCollapsed) {
    noUserDropDownCSS = [
      "absolute top-0",
      "absolute top-0 left-[250px]",
      "absolute top-0 left-[495px]",
      "absolute top-0 left-[655px]",
      "absolute top-0 left-[815px]",
      "absolute top-0 left-[1010px]",
      "absolute top-[8px] left-[1215px]",
    ];
  }

  return (
    <div
      className={
        userInfo.permission.sentinel && userInfo.permission.ailink
          ? "absolute top-[60px] left-0"
          : "absolute top-[0px] left-0"
      }
    >
      {showUserDropDownSentinel && (
        <div className={noUserDropDownCSS[7]}>
          <UserDropDownSentinel
            userDropDownValueSentinel={userDropDownValueSentinel}
            userOptions={userOptions}
            setUserDropDownValueSentinel={setUserDropDownValueSentinel}
            setCameraDropDownValueSentinel={setCameraDropDownValueSentinel}
            setUserFiltersSentinel={setUserFiltersSentinel}
            setSiteDropDownValueSentinel={setSiteDropDownValueSentinel}
            setSiteOptionsSentinel={setSiteOptionsSentinel}
            setCameraOptionsSentinel={setCameraOptionsSentinel}
            setLoadingSitesSentinel={setLoadingSitesSentinel}
            navBarCollapsed={navBarCollapsed}
            navigate={navigate}
          />
        </div>
      )}
      <div className={noUserDropDownCSS[0]}>
        <SiteDropDownSentinel
          siteOptionsSentinel={siteOptionsSentinel}
          loadingSitesSentinel={loadingSitesSentinel}
          siteDropDownValueSentinel={siteDropDownValueSentinel}
          setSiteDropDownValueSentinel={setSiteDropDownValueSentinel}
          setCameraDropDownValueSentinel={setCameraDropDownValueSentinel}
          setUserFiltersSentinel={setUserFiltersSentinel}
          setCameraOptionsSentinel={setCameraOptionsSentinel}
          setLoadingCamerasSentinel={setLoadingCamerasSentinel}
          showUserDropDown={showUserDropDownSentinel}
          navBarCollapsed={navBarCollapsed}
          navigate={navigate}
        />
      </div>
      <div className={noUserDropDownCSS[1]}>
        <CameraDropDownSentinel
          setUserFiltersSentinel={setUserFiltersSentinel}
          cameraOptionsSentinel={cameraOptionsSentinel}
          cameraDropDownValueSentinel={cameraDropDownValueSentinel}
          setCameraDropDownValueSentinel={setCameraDropDownValueSentinel}
          siteOptionsSentinel={siteOptionsSentinel}
          loadingCamerasSentinel={loadingCamerasSentinel}
          navBarCollapsed={navBarCollapsed}
          showUserDropDown={showUserDropDownSentinel}
        />
      </div>
      <div className={noUserDropDownCSS[2]}>
        <StartDateDropDownSentinel
          userFiltersSentinel={userFiltersSentinel}
          setUserFiltersSentinel={setUserFiltersSentinel}
          setTimeFiltersDisabledSentinel={setTimeFiltersDisabledSentinel}
        />
      </div>
      <div className={noUserDropDownCSS[3]}>
        <EndDateDropDownSentinel
          userFiltersSentinel={userFiltersSentinel}
          setUserFiltersSentinel={setUserFiltersSentinel}
          setTimeFiltersDisabledSentinel={setTimeFiltersDisabledSentinel}
        />
      </div>
      <div className={noUserDropDownCSS[4]}>
        <StartTimeDropDownSentinel
          userFiltersSentinel={userFiltersSentinel}
          setUserFiltersSentinel={setUserFiltersSentinel}
          timeFiltersDisabledSentinel={timeFiltersDisabledSentinel}
          showUserDropDownSentinel={showUserDropDownSentinel}
        />
      </div>
      <div className={noUserDropDownCSS[5]}>
        <EndTimeDropDownSentinel
          userFiltersSentinel={userFiltersSentinel}
          setUserFiltersSentinel={setUserFiltersSentinel}
          timeFiltersDisabledSentinel={timeFiltersDisabledSentinel}
          showUserDropDownSentinel={showUserDropDownSentinel}
        />
      </div>
      <div className={noUserDropDownCSS[6]}>
        <ApplyFiltersButtonSentinel
          setSentinelData={setSentinelData}
          setPageLoadingSentinel={setPageLoadingSentinel}
          setSelectedRowSentinel={setSelectedRowSentinel}
          setPrecisionRecallDataSentinel={setPrecisionRecallDataSentinel}
          setVideoURLSentinel={setVideoURLSentinel}
          setVideoClipLoadingSentinel={setVideoClipLoadingSentinel}
          setClipsIndexOrderSentinel={setClipsIndexOrderSentinel}
          selectedRowSentinel={selectedRowSentinel}
          setNoDataToDisplaySentinel={setNoDataToDisplaySentinel}
          setSiteOptionsSentinel={setSiteOptionsSentinel}
          setLoadingSitesSentinel={setLoadingSitesSentinel}
          userFiltersSentinel={userFiltersSentinel}
          setFetchingFilteredDataSentinel={setFetchingFilteredDataSentinel}
          setPageSentinel={setPageSentinel}
          setLoadingAdditionalData={setLoadingAdditionalData}
          setFiltersApplied={setFiltersApplied}
          navigate={navigate}
        />
      </div>
    </div>
  );
};
