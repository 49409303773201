import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AlertData, Order } from "../Alerts/AlertsUtils/alertsInterfaces";
import {
  getComparator,
  stableSort,
} from "../Alerts/AlertsUtils/alertsTableFunctions";
import { EnhancedInvestigationTableHead } from "./EnhancedInvestigationTableHead";
import {
  formatDateInUTC,
  formatTimeStamp,
} from "./InvestigationUtilities/formatTimeStamp";
import { handleRowSelected } from "./InvestigationUtilities/handleRowSelected";
import { NavigateFunction } from "react-router-dom";
import { handleButtonShading } from "./InvestigationUtilities/handleButtonShading";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleReorderIndex } from "../Alerts/AlertsUtils/handleReorderIndex";
import { convertToMilitary } from "../HealthMonitoring/HealthMonitoringUtils/getAllHMData";

export const InvestigationTable = ({
  investigationData,
  setInvestigationData,
  setSelectedRow,
  page,
  setPage,
  setVideoClipLoading,
  setVideoURL,
  scrollToTop,
  setVerifiedClicked,
  setUnverifiedClicked,
  selectedRow,
  setClipsIndexOrder,
  userFilters,
  navigate,
}: {
  investigationData: any;
  setInvestigationData: any;
  setSelectedRow: any;
  page: any;
  setPage: any;
  setVideoClipLoading: any;
  setVideoURL: any;
  scrollToTop: any;
  setVerifiedClicked: Dispatcher<boolean>;
  setUnverifiedClicked: Dispatcher<boolean>;
  selectedRow: any;
  setClipsIndexOrder: any;
  userFilters: any;
  navigate: NavigateFunction;
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("formatted_date");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - investigationData.length)
      : 0;

  const sortingFunction = stableSort(
    investigationData,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const visibleRows = React.useMemo(
    () => sortingFunction,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage, investigationData]
  );

  React.useEffect(() => {
    handleReorderIndex(
      stableSort(investigationData, getComparator(order, orderBy)),
      setClipsIndexOrder,
      investigationData,
      setInvestigationData,
      "",
      "All"
    );
  }, [order, visibleRows]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 490 }}>
        <Table
          sx={{ fontFamily: "Mulish", postion: "absolute" }}
          aria-labelledby="investigations-table"
          stickyHeader
        >
          <EnhancedInvestigationTableHead
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
          />
          <TableBody>
            {visibleRows.map((row: any, index: number) => {
              let sx: any = { cursor: "pointer" };
              if (row.selected) {
                sx = {
                  cursor: "pointer",
                  backgroundColor: "rgba(2, 136, 209, 0.2)",
                };
              }
              return (
                <TableRow
                  hover
                  onClick={() => {
                    scrollToTop();
                    setVideoClipLoading(true);
                    setSelectedRow(row);
                    localStorage.setItem(
                      "investigationsSelectedRow",
                      JSON.stringify(row)
                    );
                    handleButtonShading(
                      row,
                      setVerifiedClicked,
                      setUnverifiedClicked
                    );
                    handleRowSelected(
                      row,
                      setSelectedRow,
                      setVideoURL,
                      setVideoClipLoading,
                      investigationData,
                      setInvestigationData,
                      selectedRow,
                      userFilters,
                      "ailink",
                      userFilters.user,
                      navigate
                    );
                  }}
                  tabIndex={-1}
                  key={row.id}
                  sx={sx}
                >
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.file_id}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.clip_header_received
                      ? formatDateInUTC(row.clip_header_received)
                      : ""}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.clip_received_local
                      ? formatTimeStamp(row.clip_received_local)
                      : ""}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.customer_name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.camera_name}
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >{row.clip_result}</TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >{row.ground_truth}</TableCell> */}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={investigationData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
