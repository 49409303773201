import React, { useRef, useState, useEffect } from "react";

export const PolygonDrawer = ({
  initialPoints,
  shapes,
  shapeColor,
  setShapes,
  imageHeight,
  imageWidth,
  setIZPolygonPoints,
}: {
  initialPoints: number[][];
  shapes: number[][][];
  shapeColor: string;
  setShapes: any;
  imageHeight: number;
  imageWidth: number;
  setIZPolygonPoints: any;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [polygonPoints, setPolygonPoints] = useState<number[][]>(
    initialPoints[0] ? initialPoints : []
  );
  const [drawing, setDrawing] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(
    initialPoints[0] ? true : false
  );
  const [dragging, setDragging] = useState<boolean>(false);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(
    null
  );
  const [hoveredPointIndex, setHoveredPointIndex] = useState<number | null>(
    null
  );
  ``;
  const [hoveredLineIndex, setHoveredLineIndex] = useState<number | null>(null);
  const [hoveredShapeIndex, setHoveredShapeIndex] = useState<number | null>(
    null
  );
  const [cursorType, setCursorType] = useState<string>("crosshair");

  const [shapesArray, setShapesArray] = React.useState<number[][][]>(shapes);

  const [mousePosition, setMousePosition] = useState<[number, number] | null>(
    null
  );

  const isPointInPolygon = (
    polygonPoints: any[],
    point: any,
    index: number
  ) => {
    const [x, y] = point;
    let isInside = false;
    let j = polygonPoints.length - 1;

    // const updatedPolygonPoints = polygonPoints.map(([x, y]) => [x + 172, y + 110]);

    for (let i = 0; i < polygonPoints.length; i++) {
      const [xi, yi] = polygonPoints[i];
      const [xj, yj] = polygonPoints[j];

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) isInside = !isInside;

      j = i;
    }

    if (!dragging && isInside && hoveredShapeIndex !== index) {
      setHoveredShapeIndex(index);
    }
    if (
      !isInside &&
      hoveredShapeIndex === index &&
      !dragging &&
      !hoveredPointIndex
    ) {
      setHoveredShapeIndex(null);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        if (closed) {
          //delete the the polygon that is currently displaying the polygonPoints
          setPolygonPoints([]);
          setIZPolygonPoints([]);
          setShapes([
            ...shapesArray.filter((shape) => shape !== polygonPoints),
          ]);
          setShapesArray([
            ...shapesArray.filter((shape) => shape !== polygonPoints),
          ]);
          setHoveredShapeIndex(null);
          setHoveredLineIndex(null);
          setHoveredPointIndex(null);
          setClosed(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    selectedPointIndex,
    hoveredShapeIndex,
    polygonPoints,
    shapesArray,
    setShapes,
  ]);

  React.useEffect(() => {
    if (
      hoveredPointIndex === null &&
      hoveredLineIndex === null &&
      hoveredShapeIndex === null
    ) {
      setCursorType("crosshair");
    } else {
      setCursorType("pointer");
    }
  }, [hoveredLineIndex, hoveredPointIndex, hoveredShapeIndex]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (polygonPoints) {
      // Draw the filled polygon if it's closed
      if (closed) {
        if (polygonPoints[0]) {
          if (hoveredShapeIndex !== null) {
            const shapesArrayToUpdate = shapesArray;
            shapesArrayToUpdate[hoveredShapeIndex] = polygonPoints;
            setShapesArray(shapesArrayToUpdate);
          }
          ctx.fillStyle = `${shapeColor}50`; // Yellow color with 50% opacity
          ctx.beginPath();
          ctx.moveTo(polygonPoints[0][0], polygonPoints[0][1]);
          for (let i = 1; i < polygonPoints.length; i++) {
            ctx.lineTo(polygonPoints[i][0], polygonPoints[i][1]);
          }
          ctx.closePath();
          ctx.fill();
        }
      }

      // Draw the polygon outline and points
      ctx.strokeStyle = shapeColor;
      ctx.lineWidth = 2;
      ctx.beginPath();
      if (polygonPoints.length > 1) {
        ctx.moveTo(polygonPoints[0][0], polygonPoints[0][1]);
        for (let i = 1; i < polygonPoints.length; i++) {
          ctx.lineTo(polygonPoints[i][0], polygonPoints[i][1]);
        }
        if (closed) {
          ctx.closePath();
        }
      }
      ctx.stroke();

      for (let i = 0; i < polygonPoints.length; i++) {
        const [x, y] = polygonPoints[i];
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, Math.PI * 2);
        ctx.fillStyle =
          selectedPointIndex === i
            ? "red"
            : hoveredPointIndex === i
            ? "#001943"
            : "#FF8400";
        ctx.fill();
      }

      if (
        closed &&
        hoveredLineIndex !== null &&
        !dragging &&
        polygonPoints.length > 0
      ) {
        const [startX, startY] = polygonPoints[hoveredLineIndex];
        const [endX, endY] =
          polygonPoints[(hoveredLineIndex + 1) % polygonPoints.length];
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = "red";
        ctx.stroke();
      }
      if (!closed && mousePosition && polygonPoints.length > 0 && !drawing) {
        const [lastX, lastY] = polygonPoints[polygonPoints.length - 1];
        const [mouseX, mouseY] = mousePosition;
        ctx.beginPath();
        ctx.moveTo(lastX, lastY);
        ctx.lineTo(mouseX, mouseY);
        ctx.strokeStyle = shapeColor;
        ctx.lineWidth = 1;
        ctx.stroke();
      }
    }
  }, [
    polygonPoints,
    closed,
    selectedPointIndex,
    hoveredLineIndex,
    dragging,
    hoveredPointIndex,
    mousePosition,
  ]);

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const firstPoint = polygonPoints[0];

    if (!closed) {
      if (hoveredShapeIndex !== null) {
        setPolygonPoints(shapesArray[hoveredShapeIndex]);
        setIZPolygonPoints(shapesArray[hoveredShapeIndex]);
        setShapes([
          ...shapesArray.filter((shape, index) => index !== hoveredShapeIndex),
        ]);
        // setShapesArray([...shapesArray.filter((shape, index) => index !== hoveredShapeIndex)]);
        setHoveredShapeIndex(null);
        setClosed(true);
        return;
      }
      if (
        !drawing &&
        polygonPoints.length > 2 &&
        Math.abs(firstPoint[0] - mouseX) < 5 &&
        Math.abs(firstPoint[1] - mouseY) < 5
      ) {
        setHoveredShapeIndex(shapesArray.length);
        setShapesArray([...shapesArray, polygonPoints]);
        setClosed(true);
        return;
      }
      if (!drawing) {
        setDrawing(true);
        setPolygonPoints([...polygonPoints, [mouseX, mouseY]]);
        setIZPolygonPoints([...polygonPoints, [mouseX, mouseY]]);
      }
    } else {
      if (
        hoveredShapeIndex !== null &&
        hoveredLineIndex === null &&
        hoveredPointIndex === null
      ) {
        if (polygonPoints === shapesArray[hoveredShapeIndex]) {
          return;
        }
      }

      const clickedPointIndex = polygonPoints.findIndex(
        ([x, y]) => Math.abs(x - mouseX) < 5 && Math.abs(y - mouseY) < 5
      );
      if (clickedPointIndex !== -1) {
        setDragging(true);
        setSelectedPointIndex(clickedPointIndex);
        setHoveredLineIndex(null); // Clear the hovered line index when a point is clicked
        //set the hoveredShapeIndex to the index of the shape from the shapesArray that is closest to the clicked point
        setHoveredShapeIndex(
          shapesArray.findIndex((shape) => shape === polygonPoints)
        );
      }
      if (hoveredShapeIndex !== null) {
        if (polygonPoints === shapesArray[hoveredShapeIndex]) return;
        setShapes([
          ...shapesArray.filter((shape, index) => index !== hoveredShapeIndex),
          polygonPoints,
        ]);
        setPolygonPoints(shapesArray[hoveredShapeIndex]);
        setIZPolygonPoints(shapesArray[hoveredShapeIndex]);
      }
      if (
        hoveredShapeIndex === null &&
        hoveredLineIndex === null &&
        hoveredPointIndex === null
      ) {
        setShapes(shapesArray);
        setPolygonPoints([]);
        setIZPolygonPoints([]);
        setClosed(false);
        setDrawing(true);
        setPolygonPoints([[mouseX, mouseY]]);
        setIZPolygonPoints([[mouseX, mouseY]]);
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect1 = canvas.getBoundingClientRect();
      const mouseX1 = e.clientX - rect1.left;
      const mouseY1 = e.clientY - rect1.top;
      shapesArray.forEach((shape: number[][], index: number) => {
        isPointInPolygon(shape, [mouseX1, mouseY1], index);
      });
      if (!closed && !dragging) {
        setMousePosition([mouseX1, mouseY1]);
      }
    }

    if (drawing && !closed && !dragging) {
      if (!canvas) return;
      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      setPolygonPoints([...polygonPoints.slice(0, -1), [mouseX, mouseY]]);
      setIZPolygonPoints([...polygonPoints.slice(0, -1), [mouseX, mouseY]]);
    } else if (dragging) {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      const updatedPoints = [...polygonPoints];
      updatedPoints[selectedPointIndex!] = [mouseX, mouseY];
      setPolygonPoints(updatedPoints);
      setIZPolygonPoints(updatedPoints);
      if (hoveredShapeIndex !== null) {
        const shapesArrayToUpdate = shapesArray;
        shapesArrayToUpdate[hoveredShapeIndex] = updatedPoints;
        setShapesArray(shapesArrayToUpdate);
      }
    } else if (closed && polygonPoints.length > 0) {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      const lineIndex = polygonPoints.findIndex(([x1, y1], index) => {
        const nextIndex = (index + 1) % polygonPoints.length;
        const [x2, y2] = polygonPoints[nextIndex];

        // Vector from first point of the line to the second
        const dx = x2 - x1;
        const dy = y2 - y1;
        const segmentLengthSquared = dx * dx + dy * dy;

        // If the segment is a point (both endpoints are the same)
        if (segmentLengthSquared === 0) return false;

        // Calculate the projection of the mouse onto the line segment
        const t =
          ((mouseX - x1) * dx + (mouseY - y1) * dy) / segmentLengthSquared;

        // Clamp the projection to stay within the segment bounds (0 <= t <= 1)
        const clampedT = Math.max(0, Math.min(1, t));

        // Compute the closest point on the line segment
        const closestX = x1 + clampedT * dx;
        const closestY = y1 + clampedT * dy;

        // Calculate the distance between the mouse and the closest point on the line
        const distanceToLine = Math.hypot(mouseX - closestX, mouseY - closestY);

        // Return true if the mouse is within 5px of the line segment
        return distanceToLine < 5;
      });

      const pointIndex = polygonPoints.findIndex(
        ([x, y]) => Math.abs(x - mouseX) < 5 && Math.abs(y - mouseY) < 5
      );

      if (lineIndex !== -1) {
        if (pointIndex !== -1) {
          setHoveredLineIndex(null);
          setHoveredPointIndex(pointIndex);
        } else {
          setHoveredLineIndex(lineIndex);
          setHoveredPointIndex(null);
        }
      } else {
        setHoveredLineIndex(null); // Clear the hovered line index when no line is hovered
        setHoveredPointIndex(null);
      }
    }
  };

  const handleMouseUp = () => {
    if (drawing && !closed) {
      setDrawing(false);
    } else if (dragging) {
      if (hoveredShapeIndex !== null) {
        const shapesArrayToUpdate = shapesArray;
        shapesArrayToUpdate[hoveredShapeIndex] = polygonPoints;
        setShapesArray(shapesArrayToUpdate);
      }
      setDragging(false);
      setSelectedPointIndex(null);
    }
  };

  const insertPointBetween = (lineIndex: number) => {
    const [x1, y1] = polygonPoints[lineIndex];
    const [x2, y2] = polygonPoints[(lineIndex + 1) % polygonPoints.length];
    const newPointX = (x1 + x2) / 2;
    const newPointY = (y1 + y2) / 2;
    const updatedPoints = [
      ...polygonPoints.slice(0, lineIndex + 1),
      [newPointX, newPointY],
      ...polygonPoints.slice(lineIndex + 1),
    ];
    setPolygonPoints(updatedPoints);
    setIZPolygonPoints(updatedPoints);
  };

  return (
    <canvas
      ref={canvasRef}
      width={imageWidth}
      height={imageHeight}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={() =>
        closed &&
        hoveredLineIndex !== null &&
        !dragging &&
        insertPointBetween(hoveredLineIndex)
      }
      style={{ cursor: cursorType }}
    />
  );
};
