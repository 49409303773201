import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getGroupUsersData = (
  setGroupUserData: any,
  groupUserData: any,
  setFetchingGroupUserData: Dispatcher<boolean>,
  pageNumber: number,
  searchString: string,
  setBackgroundLoadingData: Dispatcher<boolean>,
  setAlertState: any,
  setFilteredData: any,
  setActiveData: any,
  setMoreUsersToLoad: Dispatcher<boolean>,
  setMoreUsersToLoadPageNumber: Dispatcher<number>,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    getGroupUsersData(
      setGroupUserData,
      groupUserData,
      setFetchingGroupUserData,
      pageNumber,
      searchString,
      setBackgroundLoadingData,
      setAlertState,
      setFilteredData,
      setActiveData,
      setMoreUsersToLoad,
      setMoreUsersToLoadPageNumber,
      navigate
    );
  };

  let searchParam = `?`;

  if (searchString) {
    searchParam += `search=${searchString}&`;
  }

  searchParam += `page=${pageNumber}`;

  const AxiosUI = createApi(``);

  const allGroupUserData: any[] = groupUserData;

  AxiosUI.get(`group_user/${searchParam}`).then(
    (response: AxiosResponse) => {
      response.data.results.forEach((object: any) => {
        allGroupUserData.push(object);
      });
      if (!allGroupUserData[0]) {
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            noData: true,
            updateUserSuccess: false,
            deleteUserSuccess: false,
            addUserSuccess: false,
            emailSent: false,
          };
        });
      }
      setActiveData(allGroupUserData);
      setGroupUserData(allGroupUserData);
      if (searchString) {
        setFilteredData(allGroupUserData);
        setActiveData(allGroupUserData);
      }
      setFetchingGroupUserData(false);
      setBackgroundLoadingData(false);
      if (response.data.next) {
        setMoreUsersToLoad(true);
        setMoreUsersToLoadPageNumber((prev) => prev + 1);
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setFetchingGroupUserData(false);
      });
    }
  );
};
