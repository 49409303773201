import React, { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.log("Error caught by ErrorBoundary:", error, errorInfo);
    // Update state to indicate an error has occurred
    this.setState({ hasError: true });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      alert(
        "Part of this page was unable to load.  Try refreshing page. If problem persists please contact suport@actuate.ai"
      );
      // Render fallback UI when an error occurs
      return <div />;
    }

    // Render children if no error has occurred
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
