import React from "react";
import { AlertsTableHealthMonitoring } from "../AlertsTableHealthMonitoring";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const handleFilterByAlerts = (
  alertFilter: string,
  alertsData: any,
  setFilteredDataLengths: any,
  singleCellActivated: boolean,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setFectchingAllData: any,
  setAlertsData: any,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: any,
  setSortedData: any,
  setUpdatingStatus: any,
  filters: any,
  activeName: string,
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  sceneChangeValueString: string,
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  navigate: any
) => {
  const updatedAlertsAllData: any = [];
  const updatedAlertsErrorsData: any = [];
  const updatedAlertsWarningsData: any = [];
  const updatedAlertsPendingData: any = [];
  const updatedAlertsResolvedData: any = [];

  if (singleCellActivated && alertFilter !== "all") {
    alertsData.all.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else if (singleCellActivated && alertFilter === "all") {
    alertsData.all.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else if (!singleCellActivated && alertFilter !== "all") {
    alertsData.all.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else {
    alertsData.all.forEach((object: any) => {
      updatedAlertsAllData.push(object);
    });
    alertsData.errors.forEach((object: any) => {
      updatedAlertsErrorsData.push(object);
    });
    alertsData.warnings.forEach((object: any) => {
      updatedAlertsWarningsData.push(object);
    });
    alertsData.pending.forEach((object: any) => {
      updatedAlertsPendingData.push(object);
    });
    alertsData.resolved.forEach((object: any) => {
      updatedAlertsResolvedData.push(object);
    });
  }

  setFilteredDataLengths({
    all: updatedAlertsAllData.length,
    errors: updatedAlertsErrorsData.length,
    warnings: updatedAlertsWarningsData.length,
    pending: updatedAlertsPendingData.length,
    resolved: updatedAlertsResolvedData.length,
  });

  setTimeout(() => {
    setActiveTable(
      <AlertsTableHealthMonitoring
        alertsData={alertsData}
        setAlertStatusDialogOpen={setAlertStatusDialogOpen}
        setSelectedRow={setSelectedRow}
        setSelectedRowIndex={setSelectedRowIndex}
        setFectchingAllData={setFectchingAllData}
        setAlertsData={setAlertsData}
        setUnhealthyCamsData={setUnhealthyCamsData}
        unhealthyCamsData={unhealthyCamsData}
        clickedTab={clickedTab}
        setActiveTable={setActiveTable}
        setSortedData={setSortedData}
        singleCellActivated={singleCellActivated}
        activeName={activeName}
        setUpdatingStatus={setUpdatingStatus}
        filters={filters}
        alertFilter={alertFilter}
        setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
        setBackgroundFrame={setBackgroundFrame}
        setDetectedFrame={setDetectedFrame}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        setErrorsOverviewData={setErrorsOverviewData}
        setCameraStatsData={setCameraStatsData}
        setLoadingCameraStatsData={setLoadingCameraStatsData}
        setBandwidthData={setBandwidthData}
        setLoadingBandwidthData={setLoadingBandwidthData}
        setShowSiteStats={setShowSiteStats}
        cameraDropDownValue={cameraDropDownValue}
        setFilteredDataLengths={setFilteredDataLengths}
        sceneChangeValueString={sceneChangeValueString}
        setBulkSceneChangeOutcomesDialogOpen={
          setBulkSceneChangeOutcomesDialogOpen
        }
        navigate={navigate}
      />
    );
  }, 1);
};
