import React from "react";
import { useNavigate } from "react-router-dom";
import { Sites } from "../pages/Sites/SitesPage/Sites";
import { SiteAbout } from "../pages/Sites/SiteAboutPage/SiteAbout";
import { Dispatcher } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
//import ip_camera_02 from the assets folder with the correct path

export const ModuleScreen = ({
  text,
  visibleScreen,
  styling,
  groupTrees,
  setGroupTrees,
}: {
  text: string;
  visibleScreen: any;
  styling: string;
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-black fixed w-full h-full opacity-90">
        <div className="bg-black fixed w-full h-full opacity-10 pointer-events-none">
          {/* {localStorage.navigatedFromSitesAboutPage === "true" ? (
            <SiteAbout />
          ) : (
            <Sites groupTrees={groupTrees} setGroupTrees={setGroupTrees} />
          )} */}
        </div>
        <div className="flex justify-end text-left">
          <div className="absolute top-[50px] h-full bg-white w-[600px]"></div>
        </div>
      </div>
      <div className="flex justify-end text-left">
        <div className="absolute h-full bg-white w-[600px]">
          <div className="bg-actuate-blue absolute w-[600px] h-[50px]">
            <button
              className="absolute right-6 top-[10px] text-white text-[18px]"
              onClick={() => {
                localStorage.setItem("cameraNumber", "1");
                if (localStorage.navigatedFromSitesAboutPage === "true") {
                  navigate(-1);
                } else {
                  navigate("/sites");
                }
              }}
            >
              X
            </button>
            <div className={styling}>{text}</div>
          </div>
          <div>{visibleScreen}</div>
        </div>
      </div>
    </div>
  );
};
