import React from "react";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { createApi } from "../../utils/createApi";
import { createSubmittedCameraObject } from "./createSubmittedCameraObject";
import { AxiosError, AxiosResponse } from "axios";
import { WizardCameraScreen } from "./WizardCameraScreen";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../Onboarding/OnboardingProgressDiv";
import { ProgressFinishedScreen } from "../AddSchedule/ProgressFinishedScreen";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import { handleCheckCameras } from "../CameraCheck/handleCheckCameras";

export const handleSubmitCamera = async (
  values: any,
  setVisibleScreen: any,
  responseObject: any,
  setCameraCheckFinsihedAlertOpen: any,
  setGoodCameras: any,
  setConnectivityCameras: any,
  setLowResolutionCameras: any,
  setCameraCheckResultDownloadURL: any,
  navigate: any
) => {
  let onboardingFail = false;
  let deployFail = false;

  const navigateToAboutPage = () => {
    localStorage.removeItem("addAdditionalCamera");
    localStorage.setItem("syncNeeded", "true");
    navigate(`/sites/aboutsite/${localStorage.siteId}`);
  };
  const retryFunction = () => {
    handleSubmitCamera(
      values,
      setVisibleScreen,
      responseObject,
      setCameraCheckFinsihedAlertOpen,
      setGoodCameras,
      setConnectivityCameras,
      setLowResolutionCameras,
      setCameraCheckResultDownloadURL,
      navigate
    );
  };
  setVisibleScreen(
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          Camera Add in process.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );
  const AxiosUI = createApi(`camera/onboarding/`);
  const ActuateUI = createApi(``);
  await AxiosUI.post("", createSubmittedCameraObject(values)).then(
    (response: AxiosResponse) => {
      onboardingFail = false;
      localStorage.setItem("cameraId", response.data.id);
      localStorage.removeItem("addCameraError");
      localStorage.removeItem("cameraNamingError");
      localStorage.removeItem("copyValues");
      localStorage.setItem("immixAlertsSite", "false");
      localStorage.setItem("sentinelAlertsSite", "false");
      localStorage.setItem("sureviewAlertsSite", "false");
      localStorage.setItem(
        "immixAlertPostObject",
        JSON.stringify({
          id: -1,
          immix_server: "",
          immix_port: "",
        })
      );

      setVisibleScreen(
        <StatusNotificationScreen
          styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
          text="Camera Add successful!"
        />
      );
      setTimeout(() => {
        if (localStorage.addAdditionalCamera) {
          if (
            validIntegrationTypes.includes(localStorage.selectedIntegrationType)
          ) {
            localStorage.setItem("cameraCheckAlertOpen", "true");
            localStorage.setItem("clickedId", localStorage.siteId);
            localStorage.setItem("navigatedFromSitesAboutPage", "true");
            localStorage.setItem("level0Click", "true");
            localStorage.setItem("cameraChecksiteId", localStorage.siteId);
            handleCheckCameras(
              setCameraCheckFinsihedAlertOpen,
              setGoodCameras,
              setConnectivityCameras,
              setLowResolutionCameras,
              localStorage.siteId,
              setCameraCheckResultDownloadURL,
              navigate
            );
          }
          navigateToAboutPage();
        } else if (localStorage.healthcheckOnly === "true") {
          setVisibleScreen(<ProgressFinishedScreen />);
          localStorage.setItem("healthcheckOnly", "false");
        } else {
          setVisibleScreen(
            <OnboardingProgressDiv
              percentage={
                validIntegrationTypes.includes(
                  localStorage.selectedIntegrationType
                )
                  ? 50
                  : 66
              }
              step={2}
              setVisibleScreen={setVisibleScreen}
            />
          );
        }
      }, 2000);
    },
    (reason: AxiosError) => {
      onboardingFail = true;
      if (
        reason.request.response.includes(
          "Please select a different camera name"
        )
      ) {
        localStorage.setItem("cameraNamingError", "true");
        setVisibleScreen(
          <WizardCameraScreen
            setVisibleScreen={setVisibleScreen}
            responseObject={responseObject}
            setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
            setGoodCameras={setGoodCameras}
            setConnectivityCameras={setConnectivityCameras}
            setLowResolutionCameras={setLowResolutionCameras}
            setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
          />
        );
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          {
            setVisibleScreen(
              <WizardCameraScreen
                setVisibleScreen={setVisibleScreen}
                responseObject={responseObject}
                setCameraCheckFinsihedAlertOpen={
                  setCameraCheckFinsihedAlertOpen
                }
                setGoodCameras={setGoodCameras}
                setConnectivityCameras={setConnectivityCameras}
                setLowResolutionCameras={setLowResolutionCameras}
                setCameraCheckResultDownloadURL={
                  setCameraCheckResultDownloadURL
                }
              />
            );
          }
        });
      }
    }
  );
  !onboardingFail &&
    (await ActuateUI.post(
      `customer/${localStorage.siteId}/action/deploy_next/`
    ).then(
      () => {
        deployFail = false;
        localStorage.setItem("syncNeeded", "false");
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !onboardingFail &&
    !deployFail &&
    (await ActuateUI.get(
      `camera/site/?customer__id=${localStorage.siteId}`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
