export const createCamerasObject = (cameras: any) => {
  const returnObject: any = {};
  cameras.map((object: any) => {
    returnObject[object.id] = {
      camera: object.camera ? true : false,
      id: object.id,
      products: [],
      immix: {},
      sureview: {},
      sentinel: {},
      device_id: object.device_id ? object.device_id : "",
    };
  });
  return returnObject;
};
