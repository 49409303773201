import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Transition } from "../Calendars/Calendars";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TableHeadComponent } from "../../common/TableHead";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../AddSite/StepsStepper";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { TextField, Autocomplete } from "@mui/material";
import { handleSaveChangesUserInfo } from "./GroupUsersUtils/handleSaveChangesUserInfo";
import { NavigateFunction } from "react-router-dom";

export const AboutUserDialog = ({
  aboutUserDialogOpen,
  setAboutUserDialogOpen,
  row,
  selectedRowIndex,
  setGroupUserData,
  setUpdatingGroupUserData,
  setAlertState,
  setActiveData,
  navigate,
}: {
  aboutUserDialogOpen: boolean;
  setAboutUserDialogOpen: Dispatcher<boolean>;
  row: any;
  setGroupUserData: any;
  setUpdatingGroupUserData: Dispatcher<boolean>;
  setAlertState: any;
  setActiveData: any;
  navigate: NavigateFunction;
  selectedRowIndex: number;
}) => {
  const [selectedRow, setSelectedRow] = React.useState(row);

  const [editMode, setEditMode] = React.useState(false);

  const [changesMade, setChangesMade] = React.useState(false);

  const [nameChangesMade, setNameChangesMade] = React.useState(false);

  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const userID = selectedRow.user.id;

  const parentIds = new Map();
  const parentNames = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  parentAccounts.forEach((object: any) => {
    parentNames.set(object.id, object.name);
  });

  const backupSelectedRow = row;

  const [patchObject, setPatchObject] = React.useState<any>({});

  const [namePatchObject, setNamePatchObject] = React.useState<any>({});

  return (
    <div className="absolute">
      <Dialog
        open={aboutUserDialogOpen}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ backgroundColor: "#001943" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAboutUserDialogOpen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              User Info
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {!editMode && (
            <div className="absolute right-[25px] top-[80px]">
              <OrangeTextButton
                onClick={() => {
                  setEditMode(true);
                }}
                text={
                  <>
                    <ModeEditIcon />
                    &nbsp;Edit
                  </>
                }
                disabled={false}
              />
            </div>
          )}
          {editMode && (
            <div className="absolute right-[180px] top-[80px]">
              <OrangeTextButton
                onClick={() => {
                  setSelectedRow(backupSelectedRow);
                  setEditMode(false);
                }}
                text="Cancel"
                disabled={false}
              />
            </div>
          )}
          {editMode && (
            <div className="absolute right-[25px] top-[80px]">
              <OrangeTextButton
                onClick={() => {
                  setUpdatingGroupUserData(true);
                  setAboutUserDialogOpen(false);
                  handleSaveChangesUserInfo(
                    selectedRow.id,
                    selectedRowIndex,
                    changesMade,
                    patchObject,
                    setGroupUserData,
                    setUpdatingGroupUserData,
                    setAlertState,
                    nameChangesMade,
                    namePatchObject,
                    userID,
                    setActiveData,
                    navigate
                  );
                }}
                text="Save Changes"
                disabled={!changesMade && !nameChangesMade}
              />
            </div>
          )}
          <p className="font-mulish text-[20px] font-bold">General Info</p>
          <TableContainer>
            <Table sx={{ fontFamily: "Mulish", width: "100%" }}>
              <TableHeadComponent
                titlesArray={[
                  "Group",
                  "Username",
                  "First Name",
                  "Last Name",
                  "Email",
                ]}
              />
              <TableBody>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 250 }}
                  >
                    {!editMode ? (
                      selectedRow.group.name
                    ) : (
                      <Autocomplete
                        disablePortal
                        value={parentNames.get(selectedRow.group.id)}
                        options={parentAccounts.map((object: any) => {
                          return object.name;
                        })}
                        onChange={(event: any, newInputValue: any) => {
                          setChangesMade(true);
                          setSelectedRow((previousState: any) => {
                            return {
                              ...previousState,
                              group: {
                                id: parentIds.get(newInputValue),
                                name: newInputValue,
                              },
                            };
                          });
                          setPatchObject((previousState: any) => {
                            return {
                              ...previousState,
                              group: parentIds.get(newInputValue),
                            };
                          });
                        }}
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Group"
                            autoComplete="off"
                            id="group_name"
                            name="group_name"
                          />
                        )}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 250 }}
                  >
                    {
                      // !editMode ? (
                      selectedRow.user.username
                      // ) : (
                      //   <TextField
                      //     variant="standard"
                      //     value={selectedRow.user.username}
                      //     onChange={(event) => {
                      //       setChangesMade(true);
                      //       setSelectedRow((previousState: any) => {
                      //         const updatedUser = previousState.user;
                      //         updatedUser.username = event.target.value;
                      //         return { ...previousState, user: updatedUser };
                      //       });
                      //       setPatchObject((previousState: any) => {
                      //         return {
                      //           ...previousState,
                      //           username: event.target.value,
                      //         };
                      //       });
                      //     }}
                      //     sx={{ width: 250 }}
                      //     helperText=""
                      //     type="string"
                      //   />
                      // )
                    }
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 250 }}
                  >
                    {!editMode ? (
                      selectedRow.user.first_name
                    ) : (
                      <TextField
                        variant="standard"
                        value={selectedRow.user.first_name}
                        onChange={(event) => {
                          setNameChangesMade(true);
                          setSelectedRow((previousState: any) => {
                            const updatedUser = previousState.user;
                            updatedUser.first_name = event.target.value;
                            return { ...previousState, user: updatedUser };
                          });
                          setNamePatchObject((previousState: any) => {
                            return {
                              ...previousState,
                              first_name: event.target.value,
                            };
                          });
                        }}
                        sx={{ width: 250 }}
                        helperText=""
                        type="string"
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 250 }}
                  >
                    {!editMode ? (
                      selectedRow.user.last_name
                    ) : (
                      <TextField
                        variant="standard"
                        value={selectedRow.user.last_name}
                        onChange={(event) => {
                          setNameChangesMade(true);
                          setSelectedRow((previousState: any) => {
                            const updatedUser = previousState.user;
                            updatedUser.last_name = event.target.value;
                            return { ...previousState, user: updatedUser };
                          });
                          setNamePatchObject((previousState: any) => {
                            return {
                              ...previousState,
                              last_name: event.target.value,
                            };
                          });
                        }}
                        sx={{ width: 250 }}
                        helperText=""
                        type="string"
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 250 }}
                  >
                    {
                      // !editMode ? (
                      selectedRow.user.email
                      // ) : (
                      //   <TextField
                      //     variant="standard"
                      //     value={selectedRow.user.email}
                      //     onChange={(event) => {
                      //       setChangesMade(true);
                      //       setSelectedRow((previousState: any) => {
                      //         const updatedUser = previousState.user;
                      //         updatedUser.email = event.target.value;
                      //         return { ...previousState, user: updatedUser };
                      //       });
                      //       setPatchObject((previousState: any) => {
                      //         return {
                      //           ...previousState,
                      //           email: event.target.value,
                      //         };
                      //       });
                      //     }}
                      //     sx={{ width: 250 }}
                      //     helperText=""
                      //     type="string"
                      //   />
                      // )
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br></br>
          <br></br>
          <ThemeProvider theme={theme}>
            <div className="font-mulish columns-2">
              <div>
                <p className="font-mulish text-[20px] font-bold">
                  Access Level
                </p>
                <div className="pl-[10px] py-[10px]">
                  <FormControl>
                    <RadioGroup defaultValue={selectedRow.access_level}>
                      <FormControlLabel
                        onClick={() => {
                          setChangesMade(true);
                          setPatchObject((previousState: any) => {
                            return { ...previousState, access_level: 5 };
                          });
                        }}
                        value={5}
                        control={<Radio />}
                        label="View Only"
                        disabled={!editMode}
                      />
                      <FormControlLabel
                        onClick={() => {
                          setChangesMade(true);
                          setPatchObject((previousState: any) => {
                            return { ...previousState, access_level: 1 };
                          });
                        }}
                        value={1}
                        control={<Radio />}
                        label="Arm and Disarm Only"
                        disabled={!editMode}
                      />
                      <FormControlLabel
                        onClick={() => {
                          setChangesMade(true);
                          setPatchObject((previousState: any) => {
                            return { ...previousState, access_level: 2 };
                          });
                        }}
                        value={2}
                        control={<Radio />}
                        label="Manage Existing Cameras"
                        disabled={!editMode}
                      />
                      <FormControlLabel
                        onClick={() => {
                          setChangesMade(true);
                          setPatchObject((previousState: any) => {
                            return { ...previousState, access_level: 3 };
                          });
                        }}
                        value={3}
                        control={<Radio />}
                        label="Self-Service (can add new cameras)"
                        disabled={!editMode}
                      />
                      <FormControlLabel
                        onClick={() => {
                          setChangesMade(true);
                          setPatchObject((previousState: any) => {
                            return { ...previousState, access_level: 4 };
                          });
                        }}
                        value={4}
                        control={<Radio />}
                        label="Self-Service with Delete Permissions"
                        disabled={!editMode}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div>
                <p className="font-mulish text-[20px] font-bold">Permissions</p>
                <div className="pl-[10px] py-[10px]">
                  <div>
                    <FormControl variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={selectedRow.create_user}
                              onChange={(event, checked) => {
                                setChangesMade(true);
                                setSelectedRow((previousState: any) => {
                                  return {
                                    ...previousState,
                                    create_user: checked,
                                  };
                                });
                                setPatchObject((previousState: any) => {
                                  return {
                                    ...previousState,
                                    create_user: checked,
                                  };
                                });
                              }}
                              disabled={!editMode}
                            />
                          }
                          label="Create User"
                        />
                      </FormGroup>
                      <FormHelperText>
                        User can create other users under their group
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div className="pt-[10px]">
                    <FormControl variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={selectedRow.clips}
                              onChange={(event, checked) => {
                                setChangesMade(true);
                                setSelectedRow((previousState: any) => {
                                  return { ...previousState, clips: checked };
                                });
                                setPatchObject((previousState: any) => {
                                  return { ...previousState, clips: checked };
                                });
                              }}
                              disabled={!editMode}
                            />
                          }
                          label="Clips / AI Link"
                        />
                      </FormGroup>
                      <FormHelperText>
                        User can view Clips / AI Link
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};
