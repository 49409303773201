import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { updateUnhealthyCamsData } from "./updateUnhealthyCamsData";
import { updateAlertData } from "./updateAlertData";
import { AlertsTableHealthMonitoring } from "../AlertsTableHealthMonitoring";
import { AxiosError, AxiosResponse } from "axios";
import { yyyymmddGenerator } from "../../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { handleConvertTimestamp } from "./handleConvertTimestamp";
import { createErrorsOverviewData } from "./createErrorsOverviewData";

export const handleChangeStatus = (
  setAlertsData: any,
  statusString: string,
  previousStatus: string,
  selectedAlert: any,
  alertsData: any,
  setFectchingAllData: Dispatcher<boolean>,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: string,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setSortedData: any,
  singleCellActivated: boolean,
  activeName: any,
  setUpdatingStatus: Dispatcher<boolean>,
  filters: any,
  alertFilter: string,
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  setFilteredDataLengths: any,
  sceneChangeValueString: string,
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const cleanDateTimeString = (input: string): string => {
    // Split the string into date and time parts
    const [datePart, timePart, aMPM] = input.replace(",", "").split(" ");

    // Combine the date and cleaned time part
    return `${datePart} ${timePart}${aMPM}`;
  };

  const rearrangeDateString = (inputString: any) => {
    const [day, month, year] = inputString.split("/");
    const rearrangedString = `${month}/${day}/${year}`;
    return rearrangedString;
  };

  const postedAlert = selectedAlert;
  selectedAlert.status = statusString;

  const retryFunction = () => {
    handleChangeStatus(
      setAlertsData,
      statusString,
      previousStatus,
      selectedAlert,
      alertsData,
      setFectchingAllData,
      setUnhealthyCamsData,
      unhealthyCamsData,
      clickedTab,
      setActiveTable,
      setAlertStatusDialogOpen,
      setSelectedRow,
      setSelectedRowIndex,
      setSortedData,
      singleCellActivated,
      activeName,
      setUpdatingStatus,
      filters,
      alertFilter,
      setSceneChangeOutcomesDialogOpen,
      setBackgroundFrame,
      setDetectedFrame,
      setSelectedRows,
      selectedRows,
      setErrorsOverviewData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      setBandwidthData,
      setLoadingBandwidthData,
      setShowSiteStats,
      cameraDropDownValue,
      setFilteredDataLengths,
      sceneChangeValueString,
      setBulkSceneChangeOutcomesDialogOpen,
      navigate
    );
  };

  const updatedAlertData = alertsData[clickedTab];

  const selectedIndex = alertsData[clickedTab].findIndex(
    (object: any) => object.start_timestamp === selectedAlert.start_timestamp
  );

  updatedAlertData[selectedIndex] = postedAlert;

  const AxiosUI = createApi(``);

  let searchQueryString = `?start_date=${yyyymmddGenerator(
    filters.start_date
  )}&end_date=${yyyymmddGenerator(filters.end_date)}`;

  if (filters.parent_group) {
    searchQueryString += `&group=${filters.parent_group}`;
  }

  if (filters.site) {
    searchQueryString += `&site=${filters.site}`;
  }

  if (filters.camera) {
    searchQueryString += `&camera=${filters.camera}`;
  }

  AxiosUI.post(
    `/healthcheck_result/update_healthcheck/?camera_id=${postedAlert.camera_id}&created_timestamp=${postedAlert.created_timestamp}&healthcheck_type=${postedAlert.error_type}&status=${postedAlert.status}`,
    postedAlert
  ).then(
    (response: AxiosResponse) => {
      if (response.data.end_timestamp) {
        postedAlert.formatted_end_date = cleanDateTimeString(
          rearrangeDateString(
            handleConvertTimestamp(response.data.end_timestamp)
          )
        );
      }
      const submittedAlertData = updateAlertData(
        postedAlert,
        previousStatus,
        alertsData
      );
      AxiosUI.get(
        `healthcheck_result/unhealthy_cameras/${searchQueryString}`
      ).then((response: AxiosResponse) => {
        setUnhealthyCamsData(response.data);
        setActiveTable(<></>);
        setAlertsData(submittedAlertData);
        updateUnhealthyCamsData(
          setUnhealthyCamsData,
          unhealthyCamsData,
          statusString,
          previousStatus
        );
        setUpdatingStatus(false);
        setTimeout(() => {
          setActiveTable(
            <AlertsTableHealthMonitoring
              alertsData={submittedAlertData}
              setAlertStatusDialogOpen={setAlertStatusDialogOpen}
              setSelectedRow={setSelectedRow}
              setSelectedRowIndex={setSelectedRowIndex}
              setFectchingAllData={setFectchingAllData}
              setAlertsData={setAlertsData}
              setUnhealthyCamsData={setUnhealthyCamsData}
              unhealthyCamsData={unhealthyCamsData}
              clickedTab={clickedTab}
              setActiveTable={setActiveTable}
              setSortedData={setSortedData}
              singleCellActivated={singleCellActivated}
              activeName={activeName}
              setUpdatingStatus={setUpdatingStatus}
              filters={filters}
              alertFilter={alertFilter}
              setSceneChangeOutcomesDialogOpen={
                setSceneChangeOutcomesDialogOpen
              }
              setBackgroundFrame={setBackgroundFrame}
              setDetectedFrame={setDetectedFrame}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              setErrorsOverviewData={setErrorsOverviewData}
              setCameraStatsData={setCameraStatsData}
              setLoadingCameraStatsData={setLoadingCameraStatsData}
              setBandwidthData={setBandwidthData}
              setLoadingBandwidthData={setLoadingBandwidthData}
              setShowSiteStats={setShowSiteStats}
              cameraDropDownValue={cameraDropDownValue}
              setFilteredDataLengths={setFilteredDataLengths}
              sceneChangeValueString={sceneChangeValueString}
              setBulkSceneChangeOutcomesDialogOpen={
                setBulkSceneChangeOutcomesDialogOpen
              }
              navigate={navigate}
            />
          );
          createErrorsOverviewData(submittedAlertData, setErrorsOverviewData);
        }, 1);
      });
    },
    (reason: AxiosError) => {
      // eslint-disable-next-line no-console
      console.log("reason", reason);
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Unable to update status, please try again");
        if (localStorage.backupCHMAlertsData) {
          setAlertsData(JSON.parse(localStorage.backupCHMAlertsData));
        }
        setUpdatingStatus(false);
      });
    }
  );
};
