import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const StartTimeDropDownSentinel = ({
  userFiltersSentinel,
  setUserFiltersSentinel,
  timeFiltersDisabledSentinel,
  showUserDropDownSentinel,
}: {
  userFiltersSentinel: any;
  setUserFiltersSentinel: Dispatcher<any>;
  timeFiltersDisabledSentinel: boolean;
  showUserDropDownSentinel: boolean;
}) => {
  const sx = showUserDropDownSentinel ? { width: 150 } : { width: 185 };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        label="Start Time"
        value={
          userFiltersSentinel.start_time ? userFiltersSentinel.start_time : null
        }
        onChange={(newValue) => {
          setUserFiltersSentinel((previousState: any) => {
            return { ...previousState, start_time: newValue };
          });
        }}
        disabled={timeFiltersDisabledSentinel}
        renderInput={(params) => (
          <TextField {...params} autoComplete="off" sx={sx} />
        )}
      />
    </LocalizationProvider>
  );
};
