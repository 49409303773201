import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  createTheme,
} from "@mui/material";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleEmailCameraCheckReport } from "./handleEmailCameraCheckReport";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getBaseURL } from "../../utils/getBaseURL";
import { getApiUrl } from "../../utils/getApiUrl";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CameraCheckResultScreen = ({
  goodCameras,
  connectivityCameras,
  lowResolutionCameras,
  cameraCheckResultDownloadURL,
  navigate,
}: {
  goodCameras: any;
  connectivityCameras: any;
  lowResolutionCameras: any;
  cameraCheckResultDownloadURL: string;
  navigate: any;
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF8400",
      },
    },
  });

  const [emailReportDialgOpen, setEmailReportDialogOpen] =
    React.useState(false);

  const [inputEmailAddress, setInputEmailAddress] = React.useState("");

  return (
    <div>
      <Dialog open={emailReportDialgOpen}>
        <DialogTitle id="alert-dialog-title">{"Email Report"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Please enter a valid email address.
          </DialogContentText> */}
          <TextField
            onChange={(event) => {
              setInputEmailAddress(event.target.value);
            }}
            value={inputEmailAddress}
            label="Email Address"
            helperText=""
            variant="standard"
            sx={{ fontFamily: "Mulish", width: 350 }}
          />
        </DialogContent>
        <DialogActions>
          <OrangeTextButton
            onClick={() => {
              setEmailReportDialogOpen(false);
            }}
            text="Cancel"
            disabled={false}
          />
          <OrangeTextButton
            onClick={() => {
              setEmailReportDialogOpen(false);
              handleEmailCameraCheckReport(inputEmailAddress, navigate);
            }}
            text="Submit"
            disabled={false}
          />
        </DialogActions>
      </Dialog>
      <div className="first-line:font-black font-mulish text-[18px] pl-[20px] text-[#283E6D]">
        <div></div>
        {goodCameras[0] && (
          <div>
            <p className="first-line:font-black">
              The following camera(s) have passed our check:
            </p>
            {goodCameras.map((camera: any) => {
              return (
                <li className="pl-[15px] text-[#2E7D32]" key={camera}>
                  {camera}
                </li>
              );
            })}
            <br />
          </div>
        )}
        {connectivityCameras[0] && (
          <div>
            <p className="first-line:font-black">
              Unable to connect to the following camera(s):
            </p>
            {connectivityCameras.map((camera: any) => {
              return (
                <li className="pl-[15px] text-[#D32F2F]" key={camera}>
                  {camera}
                </li>
              );
            })}
            <p>Please ensure that that all site and camera information is</p>
            <p>correct. To see likely cause download and check the report.</p>
            <br />
          </div>
        )}
        {lowResolutionCameras[0] && (
          <div>
            {" "}
            <p className="first-line:font-black">
              The following camera(s) have suboptimal settings:
            </p>
            <p className="text-[#D32F2F] first-line:font-black">
              {`Low Resolution Cameras (<480p):`}
            </p>
            {lowResolutionCameras.map((object: any) => {
              return (
                <li
                  className="pl-[15px] text-[#D32F2F]"
                  key={object.cameraName}
                >
                  {object.cameraName}
                  &nbsp;
                  <Tooltip
                    title={object.detail}
                    placement="right"
                    sx={{ fontSize: "15px", fontFamily: "mulish" }}
                  >
                    <InfoOutlinedIcon sx={{ color: "#001943", fontSize: 14 }} />
                  </Tooltip>
                </li>
              );
            })}
            <br />
            <p>If unchangeable, please update the Sensitivity of the</p>
            <p>{`products on your camera to "High" (Navigate to the`}</p>
            <p>{`camera: EDIT CAMERA > PRODUCTS > EDIT`}</p>
            <p>{`INFORMATION > SHOW ALL > Sensitivity to "High")`}</p>
            <br />
          </div>
        )}
        <p className="first-line:font-black">
          Download the report for a summary of results. To email
        </p>
        <p className="first-line:font-black">
          {`the report hit the "Email Report" button below and`}
        </p>
        <p className="first-line:font-black">
          {`insert the desired email address.`}
        </p>
        <br />
        <div>
          <OrangeTextButton
            onClick={() => {
              localStorage.setItem("cameraCheckAlertOpen", "false");
              window.open(
                `${getBaseURL()}/sites/aboutsite/${
                  localStorage.cameraChecksiteId
                }`
              );
            }}
            text="See Site"
            disabled={false}
          />
        </div>
        <div className="flex">
          <div>
            <OrangeTextButton
              onClick=""
              text={
                <>
                  <a href={cameraCheckResultDownloadURL} download>
                    Download Report
                  </a>
                </>
              }
              disabled={!cameraCheckResultDownloadURL}
            />
          </div>
        </div>
        <div>
          <div>
            <OrangeTextButton
              onClick={() => setEmailReportDialogOpen(true)}
              text="Email Report"
              disabled={!cameraCheckResultDownloadURL}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
