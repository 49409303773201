import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const MuiCheckBoxFieldType = ({ 
  id,
  label,
  checked,
  disabled,
  onChange
}: { 
    id: string,
    label: string
    checked: boolean
    disabled: boolean,
    onChange: any
 }) => {
  return (
    <div key={id}>
      <FormControlLabel
        sx={{
          fontFamily: "Mulish",
          color: "#001943",
        }}
        label={label}
        control={
          <Checkbox
            color="primary"
            defaultChecked={checked}
            disabled={disabled}
            onChange={onChange}
          />
        }
      />
    </div>
  );
};
