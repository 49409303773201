import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const GroundTruthDropDown = ({
  userFilters,
  setUserFilters,
  showUserDropDown,
  navBarCollapsed,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  showUserDropDown: boolean;
  navBarCollapsed: boolean;
}) => {
  // const sx = showUserDropDown ? { width: 120 } : { width: 190 };
  let sx: any;

  if (showUserDropDown && navBarCollapsed) {
    sx = { width: 135 };
  }

  if (showUserDropDown && !navBarCollapsed) {
    sx = { width: 120 };
  }

  if (!showUserDropDown && navBarCollapsed) {
    sx = { width: 150 };
  }

  if (!showUserDropDown && !navBarCollapsed) {
    sx = { width: 145 };
  }

  return (
    <div key="ground_truth_dropdown">
      <Autocomplete
        value={userFilters.ground_truth}
        disablePortal
        options={["All", "Verified", "Unverified"]}
        onChange={(event, value: any) => {
          if (value !== "All") {
            setUserFilters((previousState: any) => {
              return { ...previousState, ground_truth: value };
            });
          } else {
            setUserFilters((previousState: any) => {
              return { ...previousState, ground_truth: "" };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ground Truth"
            autoComplete="off"
            sx={sx}
          />
        )}
      />
    </div>
  );
};
