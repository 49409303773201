import React from "react";
import { ActuateButton } from "../../../common/ActuateButton";

export const AddSiteButton = ({ navigate }: { navigate: any }) => {
  return (
    <div className="absolute right-[7vw] top-[18px]">
      <ActuateButton
        onClick={() => {
          localStorage.setItem("siteSchedule", "false");
          localStorage.setItem("clickedFromSiteAboutPage", "false");
          navigate("/sites/addsite");
          localStorage.setItem("addAnotherScheduleScreen", "false");
          localStorage.removeItem("addSiteError");
          localStorage.removeItem("noGroupError");
        }}
        text="Add Site"
        disabled={false}
      />
    </div>
  );
};
