import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { TextField } from "@mui/material";

export const BoldAlertsTabEditMode = ({
  boldAlerts,
  cameraGeneralInfo,
  setPatchObject,
  setGeneralInfoChangesMade,
}: {
  boldAlerts: any;
  cameraGeneralInfo: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
}) => {
  const createNewPatchStreams = (
    currentIndex: any,
    inputValue: any,
    titleString: string
  ) => {
    const updatedStreams = cameraGeneralInfo.streams;
    updatedStreams.forEach((object: any) => {
      if (titleString === "bold_server") {
        object.bold_alerts[currentIndex].bold_server = inputValue;
      }
      if (titleString === "bold_port") {
        object.bold_alerts[currentIndex].bold_port = inputValue;
      }
      if (titleString === "bold_id") {
        object.bold_alerts[currentIndex].bold_id = inputValue;
      }
      if (titleString === "bold_sdnis") {
        object.bold_alerts[currentIndex].bold_sdnis = inputValue;
      }
      if (titleString === "bold_zone") {
        object.bold_alerts[currentIndex].bold_zone = inputValue;
      }
      if (titleString === "bold_alarmtype") {
        object.bold_alerts[currentIndex].bold_alarmtype = inputValue;
      }
    });
    return updatedStreams;
  };

  const updatePatchObject = (patchStreams: any) => {
    setPatchObject((previousState: any) => {
      return { ...previousState, streams: patchStreams };
    });
  };

  React.useEffect(() => {
    setPatchObject((previousState: any) => {
      return { ...previousState, streams: cameraGeneralInfo.streams };
    });
  }, []);

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      {boldAlerts.map((object: any, index: any) => {
        const currentIndex = index;
        return (
          <div key={object.id} className="h-[165px]">
            <div className="min-w-[1150px] h-[5vh] bg-white"></div>
            <div className="min-w-[1150px] h-[40px] pt-[10px] bg-white">
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: "Bold",
                  color: "black",
                  textIndent: "1vw",
                }}
              >
                Bold Alert {index + 1}
              </Typography>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1000, fontFamily: "Mulish" }}>
                  <TableHeadComponent
                    titlesArray={[
                      "Bold Server",
                      "Bold port",
                      "Bold id",
                      "Bold sdnis",
                      "Bold Zone",
                      "Bold Alarm Type",
                    ]}
                  />
                  <TableBody>
                    <TableRow key={object.id}>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.bold_server}>
                          <TextField
                            id={object.bold_server}
                            defaultValue={object.bold_server}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "bold_server"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.bold_port}>
                          <TextField
                            id={object.bold_port}
                            defaultValue={object.bold_port}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "bold_port"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.bold_id}>
                          <TextField
                            id={object.bold_id}
                            defaultValue={object.bold_id}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "bold_id"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.bold_sdnis}>
                          <TextField
                            id={object.bold_sdnis}
                            defaultValue={object.bold_sdnis}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "bold_sdnis"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.bold_zone}>
                          <TextField
                            id={object.bold_zone}
                            defaultValue={object.bold_zone}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "bold_zone"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.bold_alarmtype}>
                          <TextField
                            id={object.bold_alarmtype}
                            defaultValue={object.bold_alarmtype}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "bold_alarmtype"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        );
      })}
    </div>
  );
};
