import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import {
  getNumbersAfterEditCamera,
  getNumbersAfterSites,
} from "../EditCamera/EditCamera";

export const IgnoreZonesBreadCrumbs = ({
  linksArray,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  styling,
}: {
  linksArray: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  styling: any;
}) => {
  const navigate = useNavigate();

  const handleCameraBreadcrumbClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    navigate(
      `/sites/${getNumbersAfterSites(
        location.href
      )}/editcamera/${getNumbersAfterEditCamera(location.href)}`
    );
  };

  return (
    <Stack spacing={2}>
      <div className={styling}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="medium" />}
          aria-label="breadcrumb"
        >
          {linksArray.map((object: any, index: any) => {
            if (index === 0) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href="/sites"
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  variant="h5"
                  onClick={handleSitesBreadcrumbClick}
                >
                  {object.name}
                </Link>
              );
            } else if (index === linksArray.length - 1) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  // href="/sites/aboutsite"
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  variant="h5"
                  onClick={handleSingularSiteBreadcrumbClick}
                >
                  {object.name}
                </Link>
              );
            } else if (
              index === linksArray.length &&
              !window.location.href.includes("editignorezones")
            ) {
              return (
                <Typography
                  key={index}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                  fontWeight="bold"
                >
                  {object.name}
                </Typography>
              );
            } else if (
              index === linksArray.length &&
              window.location.href.includes("editignorezones")
            ) {
              return linksArray[1] ? (
                <Link
                  underline="hover"
                  key={localStorage.currentCamera}
                  color="inherit"
                  href="/sites"
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  variant="h5"
                  onClick={handleCameraBreadcrumbClick}
                >
                  {localStorage.currentCamera}
                </Link>
              ) : (
                <></>
              );
            }
          })}
          {!window.location.href.includes("editignorezones") ? (
            <Typography
              key="current_camera"
              color="text.primary"
              fontSize="18px"
              fontFamily="Mulish"
              fontWeight="bold"
            >
              {localStorage.currentCamera}
            </Typography>
          ) : linksArray[1] ? (
            <Link
              underline="hover"
              key={localStorage.currentCamera}
              color="inherit"
              // href="/sites/aboutsite"
              sx={{
                fontSize: "18px",
                fontFamily: "Mulish",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              variant="h5"
              onClick={handleCameraBreadcrumbClick}
            >
              {localStorage.currentCamera}
            </Link>
          ) : (
            <></>
          )}
        </Breadcrumbs>
      </div>
    </Stack>
  );
};
