import React from "react";

export const createLabels = (
  newData: Record<string, any>[],
  setAlertLabels: any
) => {
  const alertLabels: string[] = ["All"];
  newData.forEach((alert: Record<string, any>) => {
    if (alert.alert_labels) {
      if (alert.alert_labels !== "UNIDENTIFIABLE") {
        if (
          !alertLabels.includes(
            alert.alert_labels.charAt(0).toUpperCase() +
              alert.alert_labels.slice(1).replace(/_/g, " ")
          )
        ) {
          alertLabels.push(
            alert.alert_labels.charAt(0).toUpperCase() +
              alert.alert_labels.slice(1).replace(/_/g, " ")
          );
        }
      }
    }
  });

  if (setAlertLabels) {
    setAlertLabels(alertLabels);
  }
};
