import React from "react";
import { ParentGroupsDropDownAlertsPage } from "./ParentGroupsDropDownAlertsPage";
import { SiteDropDownAlertsPage } from "./SiteDropDownAlertsPage";
import { CameraDropDownAlertsPage } from "./CameraDropDownAlertsPage";
import { StartDateDropDownAlerts } from "./StartDateDropDownAlerts";
import { EndDateDropDownAlerts } from "./EndDateDropDownAlerts";
import { StartTimeDropDownAlerts } from "./StartTimeDropDownAlerts";
import { EndTimeDropDownAlerts } from "./EndTimeDropDownAlerts";
import { updateSearchParamValues } from "../../utils/updateSearchParamValues";
import { getAlertsWithQuerry } from "./AlertsUtils/getAlertsWithQuerry";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { getAlertsDownloadURL } from "./AlertsUtils/getAlertsDownloadURL";

export const FiltersAlerts = ({
  navBarCollapsed,
  userFilters,
  setUserFilters,
  navigate,
  setSiteOptions,
  setSiteDropDownValue,
  setCameraOptions,
  setCameraDropDownValue,
  setLoadingSites,
  setRefreshInterval,
  parentAccounts,
  parentIds,
  parentAccountsLoading,
  siteOptions,
  siteDropDownValue,
  loadingSites,
  setLoadingCameras,
  cameraOptions,
  cameraDropDownValue,
  loadingCameras,
  setFetchingFilteredData,
  setAlertData,
  setSelectedRow,
  setSeeMoreURL,
  setMP4URL,
  setSelectedButtonsFilled,
  setThumbnailVideos,
  setDateOutsideRange,
  setPage,
  setVideoClipLoading,
  setAlertsToDisplay,
  setPageLoading,
  setTimeFiltersDisabled,
  warningIssued,
  setWarningIssued,
  timeFiltersDisabled,
  setFiltersApplied,
  pageLoadedNumber,
  setPageLoadedNumber,
  searchParams,
  setSearchParams,
  detectionType,
  setSelectedAlertCameraInfo,
  setIgnoreZonesSelectedAlert,
  setAlertsIndexOrder,
  setCurrentName,
  setRefreshingData,
  setRefreshingToken,
  refreshingToken,
  setAlertLabels,
  setPeriodHours,
  setQueryLimitHit,
  setAlertsDownloadURL,
  refreshInterval,
  setOutcomeFilterType,
  setForwardArrowDisabled,
  setBackArrowDisabled,
}: {
  navBarCollapsed: any;
  userFilters: any;
  setUserFilters: any;
  navigate: any;
  setSiteOptions: any;
  setSiteDropDownValue: any;
  setCameraOptions: any;
  setCameraDropDownValue: any;
  setLoadingSites: any;
  setRefreshInterval: any;
  parentAccounts: any;
  parentIds: any;
  parentAccountsLoading: any;
  siteOptions: any;
  siteDropDownValue: any;
  loadingSites: any;
  setLoadingCameras: any;
  cameraOptions: any;
  cameraDropDownValue: any;
  loadingCameras: any;
  setFetchingFilteredData: any;
  setAlertData: any;
  setSelectedRow: any;
  setSeeMoreURL: any;
  setMP4URL: any;
  setSelectedButtonsFilled: any;
  setThumbnailVideos: any;
  setDateOutsideRange: any;
  setPage: any;
  setVideoClipLoading: any;
  setAlertsToDisplay: any;
  setPageLoading: any;
  setTimeFiltersDisabled: any;
  warningIssued: any;
  setWarningIssued: any;
  timeFiltersDisabled: any;
  setFiltersApplied: any;
  pageLoadedNumber: any;
  setPageLoadedNumber: any;
  searchParams: any;
  setSearchParams: any;
  detectionType: any;
  setSelectedAlertCameraInfo: any;
  setIgnoreZonesSelectedAlert: any;
  setAlertsIndexOrder: any;
  setCurrentName: any;
  setRefreshingData: any;
  setRefreshingToken: any;
  refreshingToken: any;
  setAlertLabels: any;
  setPeriodHours: any;
  setQueryLimitHit: any;
  setAlertsDownloadURL: any;
  refreshInterval: any;
  setOutcomeFilterType: any;
  setForwardArrowDisabled: any;
  setBackArrowDisabled: any;
}) => {
  return (
    <div
      className={
        !navBarCollapsed
          ? "absolute top-0 left-0"
          : "absolute top-0 left-[-80px]"
      }
    >
      <div>
        <ParentGroupsDropDownAlertsPage
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          navigate={navigate}
          setSiteOptions={setSiteOptions}
          setSiteDropDownValue={setSiteDropDownValue}
          setCameraOptions={setCameraOptions}
          setCameraDropDownValue={setCameraDropDownValue}
          setLoadingSites={setLoadingSites}
          setRefreshInterval={setRefreshInterval}
          parentAccounts={parentAccounts}
          parentIds={parentIds}
          parentAccountsLoading={parentAccountsLoading}
          navBarCollapsed={navBarCollapsed}
        />
      </div>
      <div
        className={
          !navBarCollapsed
            ? "absolute top-0 left-[160px]"
            : "absolute top-0 left-[190px]"
        }
      >
        {" "}
        <SiteDropDownAlertsPage
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          siteOptions={siteOptions}
          setCameraOptions={setCameraOptions}
          setCameraDropDownValue={setCameraDropDownValue}
          navigate={navigate}
          siteDropDownValue={siteDropDownValue}
          setSiteDropDownValue={setSiteDropDownValue}
          loadingSites={loadingSites}
          setLoadingCameras={setLoadingCameras}
          navBarCollapsed={navBarCollapsed}
        />
      </div>
      <div
        className={
          !navBarCollapsed
            ? "absolute top-0 left-[320px]"
            : "absolute top-0 left-[375px]"
        }
      >
        <CameraDropDownAlertsPage
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          cameraOptions={cameraOptions}
          cameraDropDownValue={cameraDropDownValue}
          setCameraDropDownValue={setCameraDropDownValue}
          siteOptions={siteOptions}
          loadingCameras={loadingCameras}
          navBarCollapsed={navBarCollapsed}
        />
      </div>
      <div
        className={
          !navBarCollapsed
            ? "absolute top-0 left-[480px]"
            : "absolute top-0 left-[560px]"
        }
      >
        <StartDateDropDownAlerts
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          navigate={navigate}
          setFetchingFilteredData={setFetchingFilteredData}
          setAlertData={setAlertData}
          setSelectedRow={setSelectedRow}
          setSeeMoreURL={setSeeMoreURL}
          setMP4URL={setMP4URL}
          siteOptions={siteOptions}
          setSelectedButtonsFilled={setSelectedButtonsFilled}
          setThumbnailVideos={setThumbnailVideos}
          setDateOutsideRange={setDateOutsideRange}
          setPage={setPage}
          setVideoClipLoading={setVideoClipLoading}
          setAlertsToDisplay={setAlertsToDisplay}
          setPageLoading={setPageLoading}
          setTimeFiltersDisabled={setTimeFiltersDisabled}
          warningIssued={warningIssued}
          setWarningIssued={setWarningIssued}
        />
      </div>
      <div
        className={
          !navBarCollapsed
            ? "absolute top-0 left-[640px]"
            : "absolute top-0 left-[720px]"
        }
      >
        <EndDateDropDownAlerts
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          navigate={navigate}
          setFetchingFilteredData={setFetchingFilteredData}
          setAlertData={setAlertData}
          setSelectedRow={setSelectedRow}
          setSeeMoreURL={setSeeMoreURL}
          setMP4URL={setMP4URL}
          siteOptions={siteOptions}
          setSelectedButtonsFilled={setSelectedButtonsFilled}
          setThumbnailVideos={setThumbnailVideos}
          setDateOutsideRange={setDateOutsideRange}
          setPage={setPage}
          setVideoClipLoading={setVideoClipLoading}
          setAlertsToDisplay={setAlertsToDisplay}
          setPageLoading={setPageLoading}
          setTimeFiltersDisabled={setTimeFiltersDisabled}
          warningIssued={warningIssued}
          setWarningIssued={setWarningIssued}
        />
      </div>
      <div
        className={
          !navBarCollapsed
            ? "absolute top-0 left-[800px]"
            : "absolute top-0 left-[880px]"
        }
      >
        <StartTimeDropDownAlerts
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          navigate={navigate}
          setFetchingFilteredData={setFetchingFilteredData}
          setAlertData={setAlertData}
          setSelectedRow={setSelectedRow}
          setSeeMoreURL={setSeeMoreURL}
          setMP4URL={setMP4URL}
          siteOptions={siteOptions}
          setSelectedButtonsFilled={setSelectedButtonsFilled}
          setThumbnailVideos={setThumbnailVideos}
          setDateOutsideRange={setDateOutsideRange}
          setPage={setPage}
          setVideoClipLoading={setVideoClipLoading}
          setAlertsToDisplay={setAlertsToDisplay}
          setPageLoading={setPageLoading}
          timeFiltersDisabled={timeFiltersDisabled}
        />
      </div>
      <div
        className={
          !navBarCollapsed
            ? "absolute top-0 left-[960px]"
            : "absolute top-0 left-[1040px]"
        }
      >
        <EndTimeDropDownAlerts
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          navigate={navigate}
          setFetchingFilteredData={setFetchingFilteredData}
          setAlertData={setAlertData}
          setSelectedRow={setSelectedRow}
          setSeeMoreURL={setSeeMoreURL}
          setMP4URL={setMP4URL}
          siteOptions={siteOptions}
          setSelectedButtonsFilled={setSelectedButtonsFilled}
          setThumbnailVideos={setThumbnailVideos}
          setDateOutsideRange={setDateOutsideRange}
          setPage={setPage}
          setVideoClipLoading={setVideoClipLoading}
          setAlertsToDisplay={setAlertsToDisplay}
          setPageLoading={setPageLoading}
          timeFiltersDisabled={timeFiltersDisabled}
        />
      </div>
      <div
        className={
          !navBarCollapsed
            ? "absolute top-[8px] left-[1120px]"
            : "absolute top-[8px] left-[1200px]"
        }
      >
        <button
          className="shadow-button bg-actuate-orange border-2	border-actuate-orange rounded font-mulish text-white text-base p-1.5 w-[120px]"
          onClick={() => {
            localStorage.setItem("pageLoadedNumber", JSON.stringify(0));
            localStorage.setItem(
              "latestAlertFilters",
              JSON.stringify(userFilters)
            );
            let newNumber = Number(pageLoadedNumber);
            newNumber += 1;
            setPageLoadedNumber(newNumber);
            localStorage.setItem("pageLoadedNumber", `${newNumber}`);
            setRefreshingData(false);
            setFiltersApplied(true);
            // let newNumber = pageLoadedNumber;
            // newNumber += 1;
            // setPageLoadedNumber(newNumber);
            // localStorage.setItem("pageLoadedNumber", JSON.stringify(newNumber));
            setFetchingFilteredData(true);
            setDateOutsideRange(false);
            localStorage.setItem("newQueryRunning", "true");
            updateSearchParamValues(
              userFilters,
              searchParams,
              setSearchParams,
              detectionType
            );
            getAlertsWithQuerry(
              setAlertData,
              navigate,
              setSelectedRow,
              setFetchingFilteredData,
              setSeeMoreURL,
              setMP4URL,
              userFilters.parent_group,
              userFilters.site,
              userFilters.camera,
              yyyymmddGenerator(userFilters.start_date),
              yyyymmddGenerator(userFilters.end_date),
              userFilters.start_time,
              userFilters.end_time,
              userFilters.filter_type,
              setSelectedButtonsFilled,
              setThumbnailVideos,
              setDateOutsideRange,
              setPage,
              setVideoClipLoading,
              setAlertsToDisplay,
              setPageLoading,
              setSelectedAlertCameraInfo,
              setIgnoreZonesSelectedAlert,
              setAlertsIndexOrder,
              setRefreshInterval,
              setCurrentName,
              setUserFilters,
              setRefreshingData,
              newNumber,
              refreshInterval,
              userFilters,
              cameraOptions,
              siteOptions,
              setRefreshingToken,
              refreshingToken,
              setAlertLabels,
              setPeriodHours,
              setQueryLimitHit,
              setForwardArrowDisabled,
              setBackArrowDisabled
            );
            setAlertsDownloadURL("");
            setOutcomeFilterType("All");
            getAlertsDownloadURL(
              setAlertsDownloadURL,
              userFilters.parent_group,
              userFilters.site,
              userFilters.camera,
              yyyymmddGenerator(userFilters.start_date),
              yyyymmddGenerator(userFilters.end_date),
              userFilters.start_time,
              userFilters.end_time
            );
          }}
          disabled={false}
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
};
