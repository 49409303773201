import { GeneralInfoEditCameraTabEditMode } from "../GeneralInfoEditCameraTabEditMode";
import { ConnectionsTabEditMode } from "../ConnectionsTabEditMode";
import { NotificationsTabEditMode } from "../NotificationsTabEditMode";
import { ProductsTabEditMode } from "../ProductsTabEditMode";
import { ImmixAlertsTabEditMode } from "../ImmixAlertsTabEditMode";
import { SureviewAlertsTabEditMode } from "../SureviewAlertsTabEditMode";
import { WebhooksCameraTabEditMode } from "../WebhooksCameraTabEditMode";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { SentinelAlertsTabEditMode } from "../SentinelAlertsTabEditMode";
import { BoldAlertsTabEditMode } from "../BoldAlertsTabEditMode";
import { PatriotAlertsTabEditMode } from "../PatriotAlertsTabEditMode";
import { USMonitoringAlertsTabEditMode } from "../USMonitoringAlertsTabEditMode";
import { SoftguardAlertsTabEditMode } from "../SoftguardAlertsTabEditMode";
import ErrorBoundary from "../../../../ErrorBoundary";

export const handleEditInfoButtonClick = (
  setEditMode: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  cameraGeneralInfo: any,
  setCameraGeneralInfo: any,
  setPatchObject: any,
  setGeneralInfoChangesMade: any,
  setActiveChangeMade: any,
  immixAlerts: any,
  sureviewAlerts: any,
  activeProducts: any,
  cameraWebhookData: any,
  setCameraWebhooksPatchObject: any,
  setCameraWebhooksChangesMade: any,
  productsData: any,
  setProductsData: Dispatcher<any>,
  allTabsOpen: boolean,
  setAllTabsOpen: Dispatcher<boolean>,
  setBackupProductsData: any,
  sentinelAlerts: any,
  boldAlerts: any,
  patriotAlerts: any,
  uSMonitoringAlerts: any,
  softguardAlerts: any
) => {
  setEditMode(true);
  if (clickedTab === "one") {
    setActiveTab(
      <ErrorBoundary>
        <GeneralInfoEditCameraTabEditMode
          siteInfo={siteInfo}
          cameraGeneralInfo={cameraGeneralInfo}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          setActiveChangeMade={setActiveChangeMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "two") {
    setActiveTab(
      <ErrorBoundary>
        <ConnectionsTabEditMode
          cameraGeneralInfo={cameraGeneralInfo}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "three") {
    setActiveTab(
      <ErrorBoundary>
        <NotificationsTabEditMode
          cameraGeneralInfo={cameraGeneralInfo}
          setCameraGeneralInfo={setCameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "four") {
    setActiveTab(
      <ErrorBoundary>
        <ProductsTabEditMode
          activeProducts={activeProducts}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
          cameraGeneralInfo={cameraGeneralInfo}
          productsData={productsData}
          setProductsData={setProductsData}
          allTabsOpen={allTabsOpen}
          setAllTabsOpen={setAllTabsOpen}
          setBackupProductsData={setBackupProductsData}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "five") {
    setActiveTab(
      <ErrorBoundary>
        <ImmixAlertsTabEditMode
          immixAlerts={immixAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "six") {
    setActiveTab(
      <ErrorBoundary>
        <SureviewAlertsTabEditMode
          sureviewAlerts={sureviewAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "nine") {
    setActiveTab(
      <ErrorBoundary>
        <WebhooksCameraTabEditMode
          cameraWebhookData={cameraWebhookData}
          setCameraWebhooksPatchObject={setCameraWebhooksPatchObject}
          setCameraWebhooksChangesMade={setCameraWebhooksChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "ten") {
    setActiveTab(
      <ErrorBoundary>
        <SentinelAlertsTabEditMode
          sentinelAlerts={sentinelAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "eleven") {
    setActiveTab(
      <ErrorBoundary>
        <BoldAlertsTabEditMode
          boldAlerts={boldAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "twelve") {
    setActiveTab(
      <ErrorBoundary>
        <PatriotAlertsTabEditMode
          patriotAlerts={patriotAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "thirteen") {
    setActiveTab(
      <ErrorBoundary>
        <USMonitoringAlertsTabEditMode
          uSMonitoringAlerts={uSMonitoringAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  } else if (clickedTab === "fourteen") {
    localStorage.setItem(
      "softguardAlertsBackup",
      JSON.stringify(softguardAlerts)
    );
    setActiveTab(
      <ErrorBoundary>
        <SoftguardAlertsTabEditMode
          softguardAlerts={softguardAlerts}
          cameraGeneralInfo={cameraGeneralInfo}
          setPatchObject={setPatchObject}
          setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        />
      </ErrorBoundary>
    );
  }
};
