import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { OrangeTextButton } from "../../common/OrangeTextButton";

export const SoftguardAlertsTab = ({
  softguardAlerts,
  setAddSoftguardAlertDialogOpen,
}: {
  softguardAlerts: any;
  setAddSoftguardAlertDialogOpen: any;
}) => {
  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      <div className="min-w-[1150px] h-[50px] bg-white">
        <div className="absolute right-[70px] top-[10px]">
          {permissions.edit_cameras && (
            <OrangeTextButton
              onClick={() => {
                setAddSoftguardAlertDialogOpen(true);
              }}
              text="+ Add Softguard Alert"
              disabled={false}
            />
          )}
        </div>
      </div>
      {softguardAlerts[0] &&
        softguardAlerts.map((object: any, index: any) => {
          return (
            <div key={object.id} className="h-[160px]">
              <div className="min-w-[1150px] h-[50px] pt-[10px] bg-white">
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "black",
                    textIndent: "1vw",
                  }}
                >
                  Softguard Alert {index + 1}
                </Typography>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 1000, fontFamily: "Mulish" }}>
                    <TableHeadComponent
                      titlesArray={[
                        "Server",
                        "Port",
                        "Zone",
                        "Account",
                        "User",
                        "User Ext",
                      ]}
                    />
                    <TableBody>
                      <TableRow key={object.id}>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.server ? object.server : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.port ? object.port : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.zone ? object.zone : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.account ? object.account : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.user ? object.user : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.user_ext ? object.user_ext : "--"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          );
        })}
    </div>
  );
};
