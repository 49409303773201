// import { assignMetricsToProducts } from "./assignMetricsToProducts";

// export const generateActiveProducts = (
//   streamsObject: any,
//   setActiveProducts: any,
//   setProductsData: any,
//   setBackupProductsData: any
// ) => {
//   const activeProductsToSet: any[] = [];
//   let count = 0;
//   for (const key in streamsObject) {
//     const streamsMetcis: any[] = streamsObject[key].stream_metrics;
//     streamsMetcis.forEach((object: any) => {
//       if (count === 0) {
//         activeProductsToSet.push(object);
//       } else {
//         activeProductsToSet.forEach((obj: any, index: any) => {
//           if (obj.label_name === object.label_name) {
//             if (object.active) {
//               activeProductsToSet[index] = object;
//             }
//           }
//         });
//       }
//     });
//     count += 1;
//   }
//   setActiveProducts(activeProductsToSet);
//   assignMetricsToProducts(
//     activeProductsToSet,
//     setProductsData,
//     setBackupProductsData
//   );
//   return activeProductsToSet;
// };

import { assignMetricsToProducts } from "./assignMetricsToProducts";

export const generateActiveProducts = (
  streamsObject: any,
  setActiveProducts: any,
  setProductsData: any,
  setBackupProductsData: any
) => {
  const productsMap = new Map();
  // Iterate over each stream
  for (const key in streamsObject) {
    const streamMetrics: any[] = streamsObject[key].stream_metrics;
    streamMetrics.forEach((metric: any) => {
      // If it's the first encounter or metric is active, set or update the product
      if (!productsMap.has(metric.label_name) || metric.active) {
        productsMap.set(metric.label_name, metric);
      }
    });
  }

  const activeProductsToSet = Array.from(productsMap.values());
  setActiveProducts(activeProductsToSet);
  assignMetricsToProducts(
    activeProductsToSet,
    setProductsData,
    setBackupProductsData
  );

  return activeProductsToSet;
};
