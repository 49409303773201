import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const ClipResultDropDown = ({
  userFilters,
  setUserFilters,
  showUserDropDown,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  showUserDropDown: boolean;
}) => {
  const sx = showUserDropDown ? { width: 120 } : { width: 130 };

  return (
    <div key="clip_result_dropdown">
      <Autocomplete
        value={userFilters.clip_result}
        disablePortal
        options={["All", "Verified", "Unverified", "Faulted", "Unprocessed"]}
        onChange={(event, value: any) => {
          if (value !== "All") {
            setUserFilters((previousState: any) => {
              return { ...previousState, clip_result: value };
            });
          } else {
            setUserFilters((previousState: any) => {
              return { ...previousState, clip_result: "" };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Clip Result"
            autoComplete="off"
            sx={sx}
          />
        )}
      />
    </div>
  );
};
