import React from "react";
import { createApi } from "../../utils/createApi";
import { removeCookies } from "../../utils/removeCookies";
import { NavBar } from "../../common/NavBar";
import { AxiosError } from "axios";
import { AxiosResponse } from "axios";
import { handleRedirect } from "../Login";
import "../../../index.css";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import GroupIcon from "@mui/icons-material/Group";
import { useNavigate } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const LogoutAPI = createApi(`auth/`);

export const handleLogout = () => {
  localStorage.clear();
  LogoutAPI.post("logout/")
    .then((response: AxiosResponse) => {
      removeCookies();
      document.location = response.data.logout_url;
      handleRedirect();
    })
    .catch((err: AxiosError) => {
      // eslint-disable-next-line no-console
      console.log(`Error: ${err}`);
      removeCookies();
      handleRedirect();
    });
};

export const Home = ({
  navBarCollapsed,
  setNavBarCollapsed,
  permissions,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
  permissions: any;
}) => {
  const userName = localStorage.username;
  const firstName = localStorage.first_name;

  const navigate = useNavigate();

  const pageDiv = (
    <div>
      <div className="bg-actuate-grey absolute w-full h-full">
        <div
          className={
            !navBarCollapsed
              ? "absolute left-[160px] top-5 font-black font-mulish text-3xl p-3"
              : "absolute left-[85px] top-5 font-black font-mulish text-3xl p-3"
          }
        >
          {!firstName ? (
            <p className="font-black font-mulish text-3xl">Hello, {userName}</p>
          ) : (
            <p className="font-black font-mulish text-3xl">
              Hello, {firstName}
            </p>
          )}
          {permissions.manage_users && (
            <div className="relative top-5 left-[-8px]">
              <OrangeTextButton
                onClick={() => {
                  navigate("/groupuser");
                }}
                text={
                  <>
                    <GroupIcon />
                    &nbsp; Manage Group User Access
                  </>
                }
                disabled={false}
              />
            </div>
          )}
          <br />
          <button
            className="shadow-button bg-actuate-orange rounded font-mulish text-white text-base	p-1.5"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );

  return <div>{pageDiv}</div>;
};
