import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleSubmitNewBoldAlert } from "./EditCameraUtils/handleSubmitNewBoldAlert";

export const AddBoldAlertDialog = ({
  setAddBoldAlertDialogOpen,
  addBoldAlertDialogOpen,
  boldAlerts,
  setBackdropLoadingScreenOn,
  setCameraGeneralInfo,
  setCameraGeneralInfoBackup,
  setGeneralInfoChangesMade,
  setPatchObject,
  setBoldAlerts,
  setActiveTab,
  cameraGeneralInfoBackup,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  cameraGeneralInfo,
}: {
  setAddBoldAlertDialogOpen: Dispatcher<boolean>;
  addBoldAlertDialogOpen: boolean;
  boldAlerts: any;
  setBackdropLoadingScreenOn: any;
  setCameraGeneralInfo: any;
  setCameraGeneralInfoBackup: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setBoldAlerts: any;
  setActiveTab: any;
  cameraGeneralInfoBackup: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  cameraGeneralInfo: any;
}) => {
  const navigate = useNavigate();

  const [postObject, setPostObject] = React.useState({
    id: -1,
    bold_server: "",
    bold_port: "",
    bold_id: "",
    bold_sdnis: "",
    bold_zone: "",
    bold_alarmtype: "",
  });

  React.useEffect(() => {
    if (boldAlerts[0]) {
      setPostObject({
        id: -1,
        bold_server: boldAlerts[0].bold_server,
        bold_port: boldAlerts[0].bold_port,
        bold_id: boldAlerts[0].bold_id,
        bold_sdnis: boldAlerts[0].bold_sdnis,
        bold_zone: boldAlerts[0].bold_zone,
        bold_alarmtype: boldAlerts[0].bold_alarmtype,
      });
    }
  }, []);

  return (
    <Dialog open={addBoldAlertDialogOpen}>
      <DialogTitle id="alert-dialog-title">Add Bold Alert</DialogTitle>
      <DialogContent>
        <TextField
          id="bold_server"
          variant="standard"
          label="Bold Server"
          defaultValue={boldAlerts[0] ? boldAlerts[0].bold_server : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, bold_server: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="bold_port"
          variant="standard"
          label="Bold Port"
          defaultValue={boldAlerts[0] ? boldAlerts[0].bold_port : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, bold_port: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="bold_id"
          variant="standard"
          label="Bold Id"
          defaultValue={boldAlerts[0] ? boldAlerts[0].bold_id : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, bold_id: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="bold_sdnis"
          variant="standard"
          label="Bold sdnis"
          defaultValue={boldAlerts[0] ? boldAlerts[0].bold_sdnis : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, bold_sdnis: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="bold_zone"
          variant="standard"
          label="Bold Zone"
          defaultValue={boldAlerts[0] ? boldAlerts[0].bold_zone : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, bold_zone: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="bold_alarmtype"
          variant="standard"
          label="Bold Alarm Type"
          defaultValue={boldAlerts[0] ? boldAlerts[0].bold_alarmtype : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, bold_alarmtype: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
          helperText="Alarm type for alert, default is BA (Burglary alarm)."
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddBoldAlertDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setAddBoldAlertDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleSubmitNewBoldAlert(
              cameraGeneralInfo,
              boldAlerts,
              postObject,
              navigate,
              setBackdropLoadingScreenOn,
              setCameraGeneralInfo,
              setCameraGeneralInfoBackup,
              setGeneralInfoChangesMade,
              setPatchObject,
              setBoldAlerts,
              setActiveTab,
              setAddBoldAlertDialogOpen,
              cameraGeneralInfoBackup,
              setIgnoreZonesBreadCrumbs,
              handleSitesBreadcrumbClick,
              handleSingularSiteBreadcrumbClick
            );
          }}
          text="Submit"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
