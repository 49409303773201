import React from 'react'

export const handleSentinelInput = (
    setCamerasObject: any,
    camerasObject: any,
    inputString: string,
    inputBoolean: boolean,
    objectId: number | null,
    inputNameString: string,
) => {
    let newObject = camerasObject

    if (objectId) {
        newObject = camerasObject[objectId];
    }

    if (inputNameString === "drawIgnoreZones") {
        newObject.sentinel.draw_ignore_zones = inputBoolean;
    }

    if (inputNameString === "sentinelServer") {
        for (const key in newObject) {
            newObject[key].sentinel.sentinel_server = inputString;
        }
    }
    if (inputNameString === "sentinelSiteId") {
        for (const key in newObject) {
            newObject[key].sentinel.sentinel_site_id = inputString;
        }
    }
    if (inputNameString === "sentinelDeviceAddress") {
        for (const key in newObject) {
            newObject[key].sentinel.sentinel_device_address = inputString;
        }
    }

    if (objectId) {
        setCamerasObject((previousState: any) => {
            return { ...previousState, objectId: newObject };
        });
    } else {
        setCamerasObject(newObject);
    }
}