import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const addSeletedAndDetectionType = (
  data: any,
  setAlertsIndexOrder: any
) => {
  const newData: object[] = [];
  const indexOrder: number[] = [];
  data.forEach((object: Record<string, any>, index: number) => {
    indexOrder.push(index);
    const newObject = object;
    newObject.index = index;
    if (index === 0) {
      newObject.selected = true;
      newObject.checked = false;
      if (object.engineering_outcome) {
        newObject.seen = true;
        newObject.notes = object.engineering_outcome.notes;
        newObject.user = object.engineering_outcome.user;
        newObject.updated = object.engineering_outcome.updated;
        if (object.engineering_outcome.outcome_v2) {
          newObject.detection_type = object.engineering_outcome.outcome_v2;
          if (object.engineering_outcome.outcome_v2 === "true_non_threat") {
            newObject.outcome_number = 2;
          }
          if (object.engineering_outcome.outcome_v2 === "true_threat") {
            newObject.outcome_number = 1;
          }
          if (object.engineering_outcome.outcome_v2 === "false_positive") {
            newObject.outcome_number = 3;
          }
        }
        if (!object.engineering_outcome.outcome_v2) {
          if (object.engineering_outcome.outcome === "intruder_detected") {
            newObject.detection_type = "true_threat";
            newObject.outcome_number = 1;
          }
          if (object.engineering_outcome.outcome === "false_positive") {
            newObject.detection_type = "false_positive";
            newObject.outcome_number = 3;
          }
        }
      } else if (object.customer_outcome) {
        newObject.seen = true;
        newObject.notes = object.customer_outcome.notes;
        newObject.user = object.customer_outcome.user;
        newObject.updated = object.customer_outcome.updated;
        if (object.customer_outcome.outcome_v2) {
          newObject.detection_type = object.customer_outcome.outcome_v2;
          if (object.customer_outcome.outcome_v2 === "true_non_threat") {
            newObject.outcome_number = 2;
          }
          if (object.customer_outcome.outcome_v2 === "true_threat") {
            newObject.outcome_number = 1;
          }
          if (object.customer_outcome.outcome_v2 === "false_positive") {
            newObject.outcome_number = 3;
          }
        }
        if (!object.customer_outcome.outcome_v2) {
          if (object.customer_outcome.outcome === "intruder_detected") {
            newObject.detection_type = "true_threat";
            newObject.outcome_number = 1;
          }
          if (object.customer_outcome.outcome === "false_positive") {
            newObject.detection_type = "false_positive";
            newObject.outcome_number = 3;
          }
        }
      } else {
        newObject.checked = false;
        newObject.seen = false;
        newObject.detection_type = "";
      }
    } else {
      newObject.selected = false;
      if (object.engineering_outcome) {
        newObject.seen = true;
        newObject.notes = object.engineering_outcome.notes;
        newObject.user = object.engineering_outcome.user;
        newObject.updated = object.engineering_outcome.updated;
        if (object.engineering_outcome.outcome_v2) {
          newObject.detection_type = object.engineering_outcome.outcome_v2;
          if (object.engineering_outcome.outcome_v2 === "true_non_threat") {
            newObject.outcome_number = 2;
          }
          if (object.engineering_outcome.outcome_v2 === "true_threat") {
            newObject.outcome_number = 1;
          }
          if (object.engineering_outcome.outcome_v2 === "false_positive") {
            newObject.outcome_number = 3;
          }
        }
        if (!object.engineering_outcome.outcome_v2) {
          if (object.engineering_outcome.outcome === "intruder_detected") {
            newObject.detection_type = "true_threat";
            newObject.outcome_number = 1;
          }
          if (object.engineering_outcome.outcome === "false_positive") {
            newObject.detection_type = "false_positive";
            newObject.outcome_number = 3;
          }
        }
      } else if (object.customer_outcome) {
        newObject.seen = true;
        newObject.notes = object.customer_outcome.notes;
        newObject.user = object.customer_outcome.user;
        newObject.updated = object.customer_outcome.updated;
        if (object.customer_outcome.outcome_v2) {
          newObject.detection_type = object.customer_outcome.outcome_v2;
          if (object.customer_outcome.outcome_v2 === "true_non_threat") {
            newObject.outcome_number = 2;
          }
          if (object.customer_outcome.outcome_v2 === "true_threat") {
            newObject.outcome_number = 1;
          }
          if (object.customer_outcome.outcome_v2 === "false_positive") {
            newObject.outcome_number = 3;
          }
        }
        if (!object.customer_outcome.outcome_v2) {
          if (object.customer_outcome.outcome === "intruder_detected") {
            newObject.detection_type = "true_threat";
            newObject.outcome_number = 1;
          }
          if (object.customer_outcome.outcome === "false_positive") {
            newObject.detection_type = "false_positive";
            newObject.outcome_number = 3;
          }
        }
      } else {
        newObject.checked = false;
        newObject.seen = false;
        newObject.detection_type = "";
      }
    }
    newData.push(newObject);
  });

  setAlertsIndexOrder(indexOrder);

  return newData;
};
