import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { getSitesByParentGroupAlerts } from "./AlertsUtils/getSitesByParentGroupAlerts";
import { getRefreshTimeout } from "../../utils/getRefreshTimeout";

export const ParentGroupsDropDownAlertsPage = ({
  userFilters,
  setUserFilters,
  navigate,
  setSiteOptions,
  setSiteDropDownValue,
  setCameraOptions,
  setCameraDropDownValue,
  setLoadingSites,
  setRefreshInterval,
  parentAccounts,
  parentIds,
  parentAccountsLoading,
  navBarCollapsed,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  navigate: NavigateFunction;
  setSiteOptions: Dispatcher<any>;
  setCameraOptions: Dispatcher<any>;
  setSiteDropDownValue: Dispatcher<string>;
  setCameraDropDownValue: Dispatcher<string>;
  setLoadingSites: Dispatcher<boolean>;
  setRefreshInterval: any;
  parentAccounts: any;
  parentIds: any;
  parentAccountsLoading: boolean;
  navBarCollapsed: boolean;
}) => {
  const masterParentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentNames = new Map();
  masterParentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  masterParentAccounts.forEach((object: any) => {
    parentNames.set(object.id, object.name);
  });

  return (
    <div key="parent_groups_dropdown">
      <Autocomplete
        disablePortal
        options={
          masterParentAccounts[0]
            ? parentAccounts.map((object: any) => {
                return object.name;
              })
            : []
        }
        defaultValue={parentNames.get(Number(userFilters.parent_group))}
        // loading={parentAccountsLoading}
        onChange={(event, value: string | null) => {
          // setRefreshInterval(getRefreshTimeout(parentIds.get(value)));
          setSiteOptions([]);
          setCameraOptions([]);
          setSiteDropDownValue("");
          setCameraDropDownValue("");
          // setFetchingFilteredData(true);
          if (value !== null) {
            getSitesByParentGroupAlerts(
              parentIds.get(value),
              navigate,
              setSiteOptions,
              setLoadingSites
            );
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                parent_group: parentIds.get(value),
                site: "",
                camera: "",
              };
            });
          } else {
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                parent_group: "",
                site: "",
                camera: "",
              };
            });
          }
        }}
        sx={!navBarCollapsed ? { width: 150 } : { width: 180 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Parent Group"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {parentAccountsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
};

// import React from "react";
// import { Autocomplete, CircularProgress, TextField } from "@mui/material";
// import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
// import { NavigateFunction } from "react-router-dom";
// import { getSitesByParentGroupAlerts } from "./AlertsUtils/getSitesByParentGroupAlerts";
// import { getRefreshTimeout } from "../../utils/getRefreshTimeout";

// const ParentGroupsDropDownAlertsPage = React.memo(
//   function ParentGroupsDropDownAlertsPage({
//     userFilters,
//     setUserFilters,
//     navigate,
//     setSiteOptions,
//     setSiteDropDownValue,
//     setCameraOptions,
//     setCameraDropDownValue,
//     setLoadingSites,
//     setRefreshInterval,
//     parentAccounts,
//     parentIds,
//     parentAccountsLoading,
//   }: {
//     userFilters: any;
//     setUserFilters: Dispatcher<any>;
//     navigate: NavigateFunction;
//     setSiteOptions: Dispatcher<any>;
//     setCameraOptions: Dispatcher<any>;
//     setSiteDropDownValue: Dispatcher<string>;
//     setCameraDropDownValue: Dispatcher<string>;
//     setLoadingSites: Dispatcher<boolean>;
//     setRefreshInterval: any;
//     parentAccounts: any;
//     parentIds: any;
//     parentAccountsLoading: boolean;
//   }) {
//     return (
//       <div key="parent_groups_dropdown">
//         <div key="parent_groups_dropdown">
//           <Autocomplete
//             disablePortal
//             options={
//               parentAccounts[0]
//                 ? parentAccounts.map((object: any) => {
//                   return object.name;
//                 })
//                 : []
//             }
//             defaultValue={parentAccounts[0] ? userFilters.parent_group : ""}
//             loading={parentAccountsLoading}
//             onChange={(event, value: string | null) => {
//               setRefreshInterval(getRefreshTimeout(parentIds.get(value)));
//               setSiteOptions([]);
//               setCameraOptions([]);
//               setSiteDropDownValue("");
//               setCameraDropDownValue("");
//               if (value !== null) {
//                 getSitesByParentGroupAlerts(
//                   parentIds.get(value),
//                   navigate,
//                   setSiteOptions,
//                   setLoadingSites
//                 );
//                 setUserFilters((previousState: any) => {
//                   return {
//                     ...previousState,
//                     parent_group: parentIds.get(value),
//                     site: "",
//                     camera: "",
//                   };
//                 });
//               } else {
//                 setUserFilters((previousState: any) => {
//                   return {
//                     ...previousState,
//                     parent_group: "",
//                     site: "",
//                     camera: "",
//                   };
//                 });
//               }
//             }}
//             sx={{ width: 150 }}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Parent Group"
//                 InputProps={{
//                   ...params.InputProps,
//                   endAdornment: (
//                     <React.Fragment>
//                       {parentAccountsLoading ? (
//                         <CircularProgress color="inherit" size={20} />
//                       ) : null}
//                       {params.InputProps.endAdornment}
//                     </React.Fragment>
//                   ),
//                 }}
//                 autoComplete="off"
//               />
//             )}
//           />
//         </div>
//       </div>
//     );
//   },
//   (prevProps, nextProps) => {
//     // Compare previous and next props
//     console.log("prevProps", prevProps.parentAccountsLoading)
//     console.log("nextProps", nextProps.parentAccountsLoading)
//     return (prevProps.parentAccountsLoading !== nextProps.parentAccountsLoading) || (prevProps.userFilters !== nextProps.userFilters);
//   }
// );

// ParentGroupsDropDownAlertsPage.displayName = "ParentGroupsDropDownAlertsPage";

// export default ParentGroupsDropDownAlertsPage;
