import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleSaveChangesUserInfo = (
  groupUserid: any,
  selectedRowIndex: number,
  changesMade: boolean,
  patchObject: any,
  setGroupUserData: any,
  setUpdatingGroupUserData: Dispatcher<boolean>,
  setAlertState: any,
  nameChangesMade: boolean,
  namePatchObject: any,
  userID: any,
  setActiveData: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleSaveChangesUserInfo(
      groupUserid,
      selectedRowIndex,
      changesMade,
      patchObject,
      setGroupUserData,
      setUpdatingGroupUserData,
      setAlertState,
      nameChangesMade,
      namePatchObject,
      userID,
      setActiveData,
      navigate
    );
  };

  if (changesMade && nameChangesMade) {
    AxiosUI.patch(`group_user/${groupUserid}/`, patchObject).then(
      (response: AxiosResponse) => {
        setGroupUserData((prevState: any) => {
          const newState = [...prevState];
          newState[selectedRowIndex] = response.data;
          return newState;
        });
        setActiveData((prevState: any) => {
          const newState = [...prevState];
          newState[selectedRowIndex] = response.data;
          return newState;
        });
        AxiosUI.patch(`user/${userID}/`, namePatchObject).then(
          (response: AxiosResponse) => {
            setGroupUserData((prevState: any) => {
              const newState = [...prevState];
              newState[selectedRowIndex] = response.data;
              return newState;
            });
            setActiveData((prevState: any) => {
              const newState = [...prevState];
              newState[selectedRowIndex] = response.data;
              return newState;
            });
            setUpdatingGroupUserData(false);
            setAlertState((previousState: any) => {
              return {
                ...previousState,
                updateUserSuccess: true,
                deleteUserSuccess: false,
                addUserSuccess: false,
                emailSent: false,
                noData: false,
              };
            });
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              alert("Error updating user, please try again");
              setUpdatingGroupUserData(false);
            });
          }
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Error updating user, please try again");
          setUpdatingGroupUserData(false);
        });
      }
    );
  }

  if (nameChangesMade && !changesMade) {
    AxiosUI.patch(`user/${userID}/`, namePatchObject).then(
      (response: AxiosResponse) => {
        setGroupUserData((prevState: any) => {
          const newState = [...prevState];
          newState[selectedRowIndex] = response.data;
          return newState;
        });
        setActiveData((prevState: any) => {
          const newState = [...prevState];
          newState[selectedRowIndex] = response.data;
          return newState;
        });
        setUpdatingGroupUserData(false);
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            updateUserSuccess: true,
            deleteUserSuccess: false,
            addUserSuccess: false,
            emailSent: false,
            noData: false,
          };
        });
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Error updating user, please try again");
          setUpdatingGroupUserData(false);
        });
      }
    );
  }

  if (changesMade && !nameChangesMade) {
    AxiosUI.patch(`group_user/${groupUserid}/`, patchObject).then(
      (response: AxiosResponse) => {
        setGroupUserData((prevState: any) => {
          const newState = [...prevState];
          newState[selectedRowIndex] = response.data;
          return newState;
        });
        setActiveData((prevState: any) => {
          const newState = [...prevState];
          newState[selectedRowIndex] = response.data;
          return newState;
        });
        setUpdatingGroupUserData(false);
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            updateUserSuccess: true,
            deleteUserSuccess: false,
            addUserSuccess: false,
            emailSent: false,
            noData: false,
          };
        });
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Error updating user, please try again");
          setUpdatingGroupUserData(false);
        });
      }
    );
  }
};
