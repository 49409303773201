import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";

export const EndDateDropDownSentinel = ({
  userFiltersSentinel,
  setUserFiltersSentinel,
  setTimeFiltersDisabledSentinel,
}: {
  userFiltersSentinel: any;
  setUserFiltersSentinel: Dispatcher<any>;
  setTimeFiltersDisabledSentinel: Dispatcher<boolean>;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={userFiltersSentinel.end_date}
        label="End Date"
        onChange={(newValue) => {
          if (newValue !== null) {
            setUserFiltersSentinel((previousState: any) => {
              return { ...previousState, end_date: newValue };
            });
          }
          if (newValue === null) {
            setUserFiltersSentinel((previousState: any) => {
              return { ...previousState, end_date: null };
            });
          }
          if (
            yyyymmddGenerator(newValue) !==
            yyyymmddGenerator(userFiltersSentinel.start_date)
          ) {
            setTimeFiltersDisabledSentinel(true);
            setUserFiltersSentinel((previousState: any) => {
              return { ...previousState, start_time: null, end_time: null };
            });
          }
          if (
            yyyymmddGenerator(newValue) ===
            yyyymmddGenerator(userFiltersSentinel.start_date)
          ) {
            setTimeFiltersDisabledSentinel(false);
          }
        }}
        renderInput={(params) => <TextField sx={{ width: 150 }} {...params} />}
      />
    </LocalizationProvider>
  );
};
