/* eslint-disable no-console */
export const saveUserTimezone = (): void => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Check if localStorage is available
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem("userTimezone", timezone);
    } else {
      console.warn("localStorage is not available.");
    }
  } catch (error) {
    console.error("Error retrieving or saving timezone:", error);
  }
};
