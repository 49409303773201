import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { updateSearchParamValues } from "../../utils/updateSearchParamValues";

export const DetectionTypeFilter = ({
  alertLabels,
  detectionType,
  setDetectionType,
  userFilters,
  searchParams,
  setSearchParams,
  setPage,
}: {
  alertLabels: any;
  detectionType: string;
  setDetectionType: Dispatcher<string>;
  userFilters: any;
  searchParams: any;
  setSearchParams: Dispatcher<any>;
  setPage: Dispatcher<number>;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div>
        <div className="text-[12px]">
          DETECTION TYPE
          <Button
            id="filter-button"
            aria-controls={open ? "filter-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ paddingBottom: "8px", fontSize: "12px" }}
          >
            {detectionType}
          </Button>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            //   anchorOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            //   }}
            //   transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            //   }}
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
          >
            {alertLabels.map((name: string) => {
              return (
                <MenuItem
                  key={name}
                  onClick={() => {
                    setDetectionType(name);
                    updateSearchParamValues(
                      userFilters,
                      searchParams,
                      setSearchParams,
                      name
                    );
                    setPage(0);
                    handleClose();
                  }}
                >
                  {name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </div>
  );
};
