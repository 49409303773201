import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const CameraDropDownAILink = ({
  setUserFilters,
  cameraOptions,
  cameraDropDownValue,
  setCameraDropDownValue,
  siteOptions,
  loadingCameras,
  showUserDropDown,
  navBarCollapsed,
}: {
  setUserFilters: Dispatcher<any>;
  cameraOptions: any;
  cameraDropDownValue: any;
  setCameraDropDownValue: Dispatcher<string>;
  siteOptions: any;
  loadingCameras: boolean;
  showUserDropDown: boolean;
  navBarCollapsed: boolean;
}) => {
  const cameraIds = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  let sx: any;

  if (showUserDropDown && navBarCollapsed) {
    sx = { width: 140 };
  }

  if (showUserDropDown && !navBarCollapsed) {
    sx = { width: 120 };
  }

  if (!showUserDropDown && navBarCollapsed) {
    sx = { width: 180 };
  }

  if (!showUserDropDown && !navBarCollapsed) {
    sx = { width: 160 };
  }
  return (
    <div key="camera_dropdown">
      <Autocomplete
        value={cameraDropDownValue}
        disablePortal
        options={
          cameraOptions[0]
            ? cameraOptions.map((object: any) => {
                return object.camera_name;
              })
            : []
        }
        loading={loadingCameras}
        onChange={(event, value: any) => {
          if (value !== "") {
            setCameraDropDownValue(value);
            setUserFilters((previousState: any) => {
              return { ...previousState, camera_ids: [cameraIds.get(value)] };
            });
          } else {
            setCameraDropDownValue(value);
            setUserFilters((previousState: any) => {
              return { ...previousState, camera_ids: [] };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Camera"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCameras ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={sx}
          />
        )}
      />
    </div>
  );
};
