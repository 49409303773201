import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ErrorWarningTabs } from "./ErrorWarningTabs";
import { ErrorTab } from "./ErrorTab";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const TotalCamerasTable = ({
  dashboardTotal,
  setActiveTab,
  setPage,
  activeTab,
  camerasErrorInfo,
  camerasWarningInfo,
  page,
  setPageLoading,
  navBarCollapsed,
  errorPaginationNumber,
  setErrorPaginationNumber,
  setCameraErrorInfo,
  warningPaginationNumber,
  setWarningPaginationNumber,
  setCameraWarningInfo,
  navigate,
}: {
  dashboardTotal: any;
  setActiveTab: any;
  setPage: any;
  activeTab: any;
  camerasErrorInfo: any;
  camerasWarningInfo: any;
  page: any;
  setPageLoading: any;
  navBarCollapsed: boolean;
  errorPaginationNumber: number;
  setErrorPaginationNumber: any;
  setCameraErrorInfo: any;
  warningPaginationNumber: number;
  setWarningPaginationNumber: any;
  setCameraWarningInfo: any;
  navigate: any;
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#001943",
      },
      secondary: {
        main: "#F57C00",
      },
      success: {
        main: "#2E7D32",
      },
      error: {
        main: "#F44336",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        className={
          navBarCollapsed
            ? "absolute top-[65px] left-[95px] right-[1px] h-[250px] w-[625px] bg-white"
            : "absolute top-[65px] left-[170px] right-[1px] h-[250px] w-[550px] bg-white"
        }
      >
        {Number(dashboardTotal.total) >= 0 ? (
          <p className="text-actuate-blue font-mulish text-[26px] font-bold absolute top-[25px] left-[40px]">
            {dashboardTotal.total}
          </p>
        ) : (
          <div className="absolute top-[25px] left-[40px]">
            <CircularProgress color="primary" size={35} />
          </div>
        )}
        <p className="text-actuate-blue font-mulish text-[16px] font-bold absolute top-[65px] left-[40px]">
          Total Cameras
        </p>
        <svg className="absolute top-[15px] left-[175px]" height="88px">
          <line
            x1="0"
            y1="0"
            x2="0"
            y2="135"
            style={{ stroke: `#BDBDBD`, strokeWidth: 4 }}
          />
        </svg>
        {Number(dashboardTotal.error) >= 0 ? (
          <p className="text-[#F44336] font-mulish text-[16px] font-bold absolute top-[12%] left-[43.2%] -translate-x-1/2 -translate-y-1/2">
            {dashboardTotal.error}
          </p>
        ) : (
          <div className="absolute top-[12%] left-[43.2%] -translate-x-1/2 -translate-y-1/2">
            <CircularProgress color="error" size={25} />
          </div>
        )}
        <p
          className={
            navBarCollapsed
              ? "text-[#F44336] font-mulish text-[16px] absolute top-[44px] left-[250px]"
              : "text-[#F44336] font-mulish text-[16px] absolute top-[44px] left-[220px]"
          }
        >
          Error
        </p>
        <div
          className={
            navBarCollapsed
              ? "absolute top-[68px] left-[255px]"
              : "absolute top-[68px] left-[225px]"
          }
        >
          <CancelIcon sx={{ color: "#F44336" }} />
        </div>
        {Number(dashboardTotal.warning) >= 0 ? (
          <p className="text-[#F57C00] font-mulish text-[16px] font-bold absolute top-[12%] left-[65%] -translate-x-1/2 -translate-y-1/2">
            {dashboardTotal.warning}
          </p>
        ) : (
          <div className="absolute top-[12%] left-[65%] -translate-x-1/2 -translate-y-1/2">
            <CircularProgress color="secondary" size={25} />
          </div>
        )}
        <p
          className={
            navBarCollapsed
              ? "text-[#F57C00] font-mulish text-[16px] absolute top-[44px] left-[373px]"
              : "text-[#F57C00] font-mulish text-[16px] absolute top-[44px] left-[325px]"
          }
        >
          Warning
        </p>
        <div
          className={
            navBarCollapsed
              ? "absolute top-[68px] left-[393px]"
              : "absolute top-[68px] left-[345px]"
          }
        >
          <ErrorIcon sx={{ color: "#F57C00" }} />
        </div>
        {Number(dashboardTotal.healthy) >= 0 ? (
          <p className="text-[#2E7D32] font-mulish text-[16px] font-bold absolute top-[12%] left-[88.5%] -translate-x-1/2 -translate-y-1/2">
            {dashboardTotal.healthy}
          </p>
        ) : (
          <div className="absolute top-[12%] left-[88.5%] -translate-x-1/2 -translate-y-1/2">
            <CircularProgress color="success" size={25} />
          </div>
        )}
        <p
          className={
            navBarCollapsed
              ? "text-[#2E7D32] font-mulish text-[16px] absolute top-[44px] left-[525px]"
              : "text-[#2E7D32] font-mulish text-[16px] absolute top-[44px] left-[460px]"
          }
        >
          Healthy
        </p>
        <div
          className={
            navBarCollapsed
              ? "absolute top-[68px] left-[540px]"
              : "absolute top-[68px] left-[475px]"
          }
        >
          <CheckCircleIcon sx={{ color: "#2E7D32" }} />
        </div>
        <div className="absolute top-[120px] left-[25px]">
          <ErrorWarningTabs
            dashboardTotal={dashboardTotal}
            setActiveTab={setActiveTab}
            setPage={setPage}
            navBarCollapsed={navBarCollapsed}
          />
        </div>
        <div className="absolute top-[170px] left-[0px]">
          <ErrorTab
            activeTab={activeTab}
            camerasErrorInfo={camerasErrorInfo}
            camerasWarningInfo={camerasWarningInfo}
            page={page}
            setPage={setPage}
            setPageLoading={setPageLoading}
            navBarCollapsed={navBarCollapsed}
            errorPaginationNumber={errorPaginationNumber}
            setErrorPaginationNumber={setErrorPaginationNumber}
            setCameraErrorInfo={setCameraErrorInfo}
            warningPaginationNumber={warningPaginationNumber}
            setWarningPaginationNumber={setWarningPaginationNumber}
            setCameraWarningInfo={setCameraWarningInfo}
            navigate={navigate}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};
